import { Box } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Label } from 'recharts'
import { Header, Icon, Segment } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'
import BrowseData from './BrowseData'
import BrowseDataBaby from './BrowseDataBaby'
import QuickLook from './QuickLook'
import QuickLookBaby from './QuickLookBaby'
function Ind40Visualizer({ network, machines }) {
    const [startTime, setStartTime] = useState(moment().startOf('month').toDate())
    const [endTime, setEndTime] = useState(moment().endOf('month').toDate())

    useEffect(() => {
        network.getMachineList()
    }, [])

    const babySegment = (machine) => (
        <>
            <Segment>
                <Header>{machine.machineName}</Header>
                {!machine.machineHadesRef && (
                    <Segment placeholder>
                        <Header icon>
                            <Icon name="delete" />
                            Macchina {machine.machineName} ({machine.machineId}) non mappata
                        </Header>
                    </Segment>
                )}
                <Segment.Group>
                    <Segment.Group horizontal="true">
                        <Segment>
                            <Label>Data inizio</Label>
                            <ReactDatePicker
                                name="startTime"
                                placeholder="Data inizio"
                                label="Data inizio"
                                useLocalValue={true}
                                onChange={(e) => {
                                    setStartTime(e)
                                }}
                                selected={startTime}
                                dateFormat="dd/MM/yyyy"
                            />
                        </Segment>
                        <Segment>
                            <Label>Data fine</Label>
                            <ReactDatePicker
                                name="endTime"
                                placeholder="Data fine"
                                label="Data fine"
                                useLocalValue={true}
                                onChange={(e) => {
                                    setEndTime(e)
                                }}
                                selected={endTime}
                                dateFormat="dd/MM/yyyy"
                            />
                        </Segment>
                    </Segment.Group>
                    <QuickLookBaby
                        table={machine.machineId}
                        fields={{
                            // cmd_bordo: 84148224,
                            // cmd_move: 0,
                            // state_terra_cmd: 122619906,
                            // state_terra_move: 9568259,
                            state_alarms: 'Allarme',
                            program_change_cnt: 'Programmi cambiati',
                            alarms_serious: 'Bloccante',
                            alarms_general: 'Generale',
                            alarms_thermo: 'Termico',
                            alarms_ciclo_open: 'Ciclo Aperto',
                            alarms_ciclo_off: 'Ciclo Spento',
                            client_name: 'Cliente',
                            cycle_total: 'Cicli',
                            // cicli_cartuccia_vuota: null,
                            // n_cicli_spurgo: 6,
                            // n_cicli_iniziali_quota: 5,
                            // conta_cicli_att: 'Conta cicli',
                            // num_ciclo_graph: 3190,
                            actvalues_tempociclototale: 'Tempo ciclo totale',
                            actvalues_tempocicloparziale: 'Tempo ciclo parziale',
                            // qualitycmpvalues_tempociclototale: 0,
                            // qualitycmpvalues_tempocicloparziale: 0,
                            qualitycmpvalues_temperaturaolio: 'Temperatura olio',
                            qualitycmpvalues_totalecicli: 'Totale cicli',
                            qualitycmpvalues_totaleore: 'Totale ore',
                            qualitycmpvalues_nomeprogramma: 'Programma',
                            qualitycmpvalues_ciclo: 'Ciclo',
                            qualitycmpvalues_ugello: 'Ugello',
                        }}
                        // machinestatusfield="machinemode"
                        // machinestatusmap={{
                        //     0: { label: 'Altro', color: 'green.50' },
                        //     1: { label: 'Automatico', color: 'green.600' },
                        //     2: { label: 'Semiautomatico', color: 'yellow.600' },
                        //     3: { label: 'Manuale', color: 'red.600' },
                        //     4: { label: 'Tooling', color: 'teal.600' },
                        //     5: { label: 'Sleep', color: 'gray.600' },
                        // }}
                        // fields={{
                        //     conta_cicli: 'Conta cicli',
                        //     // conta_commessa: 'Conta commessa',
                        //     codicearticolo: 'Articolo',
                        //     codicecliente: 'Cliente',
                        //     codiceproduzione: 'Produzione',
                        //     codicestampo: 'Stampo',
                        //     all_code: 'Allarmi',
                        //     conta_scarti: 'Scarti',
                        //     // manuale: 'Manuale',
                        // }}
                    />
                    <BrowseDataBaby table={machine.machineHadesRef} startTime={startTime} endTime={endTime} />
                </Segment.Group>
            </Segment>
        </>
    )

    const machineSegment = (machine) => {
        let fields = {
            cyclecounter: 'Conta cicli',
            // conta_commessa: 'Conta commessa',
            currentlotname: 'Lotto',
            cycletime: 'Tempo ciclo',
            jobgoodpartscounter: 'Pezzi prodotti',
            jobbadpartscounter: 'Scarti',
            jobname: 'Commessa',
            jobnamefile: 'File',
            machinecyclecounter: 'Totale cicli',
            material: 'Materiale',
            // manuale: 'Manuale',
        }

        let machineStatusMap = {
            0: { label: 'Altro', color: 'green.50' },
            1: { label: 'Automatico', color: 'green.600' },
            2: { label: 'Semiautomatico', color: 'yellow.600' },
            3: { label: 'Manuale', color: 'red.600' },
            4: { label: 'Tooling', color: 'teal.600' },
            5: { label: 'Sleep', color: 'gray.600' },
        }

        switch (machine.machineHadesRef) {
            case 'distillatore':
                fields = {
                    temperaturabagnomariaattuale: 'temperaturabagnomariaattuale',
                    valoreuscitaanalogicaposvalvolamod: 'valoreuscitaanalogicaposvalvolamod',
                    statoimpiantook: 'statoimpiantook',
                    impiantoinautomatico: 'impiantoinautomatico',
                    comandostartautomatico: 'comandostartautomatico',
                    mancanzaausiliari: 'mancanzaausiliari',
                    mancanza24vingressiuscitedig: 'mancanza24vingressiuscitedig',
                    mancanza24vanalogiche: 'mancanza24vanalogiche',
                    allarmepressostato: 'allarmepressostato',
                    agitatoreinmarcia: 'agitatoreinmarcia',
                    agitatoreinmanuale: 'agitatoreinmanuale',
                    allarmetermicoagitatore: 'allarmetermicoagitatore',
                    ricettaattualmentecaricata: 'ricettaattualmentecaricata',
                }
                break
            case 'pallettizzatore':
            case 'pallettizzatore1':
                fields = {
                    // azzerarecontatori: 'AzzerareContatori',
                    // richiestacambioformato: 'RichiestaCambioFormato',
                    // feedbackfineproduzione: 'FeedbackFineProduzione',
                    // whatchdog: 'WhatchDog',
                    // infopalout: 'InfoPalOut',
                    macchinainmarcia: 'MacchinaInMarcia',
                    macchinainarresto: 'MacchinaInArresto',
                    plcattivo: 'PlcAttivo',
                    // richiamoformatoeseguito: 'RichiamoFormatoEseguito',
                    // fineproduzione: 'FineProduzione',
                    // gestionaleabilitato: 'GestionaleAbilitato',
                    // statomacchina: 'StatoMacchina',
                    numerocartonirimastidalavorare: 'NumeroCartoniRimastiDaLavorare',
                    numerocartonidalavorare: 'NumeroCartoniDaLavorare',
                    numerobancalidalavorare: 'NumeroBancaliDaLavorare',
                    numerostratidalavorare: 'NumeroStratiDaLavorare',
                    numeroformatodalavorare: 'NumeroFormatoDaLavorare',
                    stringaformatodalavorare: 'StringaFormatoDaLavorare',
                    tipobancaledausare: 'TipoBancaleDaUsare',
                    numerocartoniparziali: 'NumeroCartoniParziali',
                    numeropaletteparziali: 'NumeroPaletteParziali',
                    // numerocartonitotali: 'NumeroCartoniTotali',
                    // numeropalettetotali: 'NumeroPaletteTotali',
                    // numerostratirealtime: 'NumeroStratiRealTime',
                    // numerocartonirealtime: 'NumeroCartoniRealTime',
                    // numerocartoniperstrato: 'NumeroCartoniPerStrato',
                    // numerostratiperbancale: 'NumeroStratiPerBancale',
                    // numerocartonisubancaleinlavorazione: 'NumeroCartoniSuBancaleInLavorazione',
                    // numerocartonisubancalelavorato: 'NumeroCartoniSuBancaleLavorato',
                    numeroformatoincorso: 'NumeroFormatoInCorso',
                    nomeformatoincorso: 'NomeFormatoInCorso',
                    // tipobancaleutilizzato: 'TipoBancaleUtilizzato',
                    // emergenzamacchina: 'EmergenzaMacchina',
                    // protezioniaperte: 'ProtezioniAperte',
                    // nastroingressofermo: 'NastroIngressoFermo',
                    // macchinainmanuale: 'MacchinaInManuale',
                    // impiantofermo: 'ImpiantoFermo',
                    // pulsanteemergenzapremuto: 'PulsanteemergenzaPremuto',
                    // mancanzaaria: 'MancanzaAria',
                    // batteriaplcscarica: 'BatteriaPlcScarica',
                    // termichemotori: 'TermicheMotori',
                    // incalcamentotesta: 'IncalcamentoTesta',
                    // incalcamentofila: 'IncalcamentoFila',
                    // allarmeinverter: 'AllarmeInverter',
                    // mancanzacartonispintorepacchi: 'MancanzaCartoniSpintorePacchi',
                    // troppicartonisulnastroingresso: 'TroppiCartoniSulNastroIngresso',
                    // incalcamentointerfalda: 'IncalcamentoInterfalda',
                    // traslazionebracciointerfbloccato: 'TraslazioneBraccioInterfBloccato',
                    // allarmeinvertera1: 'AllarmeInverterA1',
                    // allarmeinvertera2: 'AllarmeInverterA2',
                    // allarmeinvertera3: 'AllarmeInverterA3',
                    // allarmeinvertera4: 'AllarmeInverterA4',
                    // allarmeinvertera5: 'AllarmeInverterA5',
                    // allarmeinvertera6: 'AllarmeInverterA6',
                    // allarmeinvertera7: 'AllarmeInverterA7',
                    // allarmeinvertera8: 'AllarmeInverterA8',
                    // allarmeinvertera9: 'AllarmeInverterA9',
                    // allarmeinvertera10: 'AllarmeInverterA10',
                    // allarmeinvertera11: 'AllarmeInverterA11',
                    // allarmeinvertera12: 'AllarmeInverterA12',
                    // allarmecomunicazione: 'AllarmeComunicazione',
                }
                break
            case 'depallettizzatore':
            case 'depallettizzatore1':
                fields = {
                    numerobottiglietotali: 'Numero Cartoni Parziale',
                    numeropalettetotali: 'Numero Pallet Parziale',
                    statomacchina: 'Stato macchina'
                }
                break
            default:
                break
        }

        return (
            <>
                <Segment>
                    <Header>{machine.machineName}</Header>
                    {!machine.machineHadesRef && (
                        <Segment placeholder>
                            <Header icon>
                                <Icon name="delete" />
                                Macchina {machine.machineName} ({machine.machineId}) non mappata
                            </Header>
                        </Segment>
                    )}
                    {machine.machineHadesRef && (
                        <Segment.Group>
                            <Segment.Group horizontal="true">
                                <Segment>
                                    <Label>Data inizio</Label>
                                    <ReactDatePicker
                                        name="startTime"
                                        placeholder="Data inizio"
                                        label="Data inizio"
                                        useLocalValue={true}
                                        onChange={(e) => {
                                            setStartTime(e)
                                        }}
                                        selected={startTime}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </Segment>
                                <Segment>
                                    <Label>Data fine</Label>
                                    <ReactDatePicker
                                        name="endTime"
                                        placeholder="Data fine"
                                        label="Data fine"
                                        useLocalValue={true}
                                        onChange={(e) => {
                                            setEndTime(e)
                                        }}
                                        selected={endTime}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </Segment>
                            </Segment.Group>
                            <QuickLook
                                table={machine.machineHadesRef}
                                fields={fields}
                                // machinestatusfield={machine.machineHadesRef === 'distillatore' ? `statoimpiantook` : `statomacchina`}
                                // machinestatusmap={machineStatusMap}
                            />
                            <BrowseData table={machine.machineHadesRef} startTime={startTime} endTime={endTime} />
                        </Segment.Group>
                    )}
                </Segment>
            </>
        )
    }

    if (!machines) {
        return null
    } else if (machines.length === 0) {
        return (
            <>
                <Segment placeholder>
                    <Header icon>
                        <Icon name="search" />
                        Nessuna macchina trovata
                    </Header>
                </Segment>
            </>
        )
    }

    const segsForRow = 1
    const segs = []

    // const segsForRow = 1
    // const segs = []
    // let row = []
    // for (const [i, machine] of machines.entries()) {
    //     if (machine.machineType !== 8) {
    //         continue
    //     }

    //     row.push(<Grid.Column>{machineSegment(machine)}</Grid.Column>)

    //     if (row.length === segsForRow || i === machines.length - 1) {
    //         segs.push(<Grid.Row>{row}</Grid.Row>)
    //         row = []
    //     }
    // }

    for (const [i, machine] of machines.entries()) {
        if (!(machine.machineType === 8 || machine.machineType === 9 || machine.machineType === 10 || machine.machineType === 11)) {
            continue
        }

        if (machine.machineHadesRef && machine.machineHadesRef.indexOf('babyplast') >= 0) {
            segs.push(babySegment(machine))
        } else {
            segs.push(
                <Box w="100%" key={`machine_${i}_40`}>
                    {machineSegment(machine)}
                </Box>
            )
        }
    }

    return (
        <>
            {segs}
            {/* <Grid columns={2}>{segs}</Grid> */}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList: {
            fetching: fetchMachines,
            data: { data: machines },
        },
    } = state

    return {
        fetchMachines,
        machines,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Ind40Visualizer)))
