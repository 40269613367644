import { Box, Stack } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Select, Step, TextArea } from 'semantic-ui-react'
import { Article, Job, PickFile, PickImage } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from '../lib/View'
import ArticleDropdown from '../utility/ArticleDropdown'
import CustomerDropdown from '../utility/CustomerDropdown'
import { routes, route_from } from 'lib/routes'
import { createAlert } from 'lib/util'
import { Switch, Wrap } from '@chakra-ui/react'
const { app, printjobs } = routes

const defaultJob = {
    jobCode: '#AUTO#',
    jobName: moment().format('DDMMYY'),
    jobClientCode: '',
    description: '',
    jobType: 3,
    client: 'Cliente',
    image: '',
    urgencyLevel: 0,
    createdExternal: 0,
    billed: 0,
    active: 1,
    budgeted: 0,
    jobProgram: '',
    deliveryDate: new Date(),
    deliveryWeek: moment().get('week'),
    orderDate: new Date(),
    customerId: 30,
    jobLocation: '',
    jobMachineType: 0,
}

function JobWizard(props) {
    const { network, toolbarResult, filtersResult, sortsResult, isEmployee, success, error, history } = props
    const [step, setStep] = useState(0)
    const [typeOptions, setTypeOptions] = useState([])
    const [job, setJob] = useState(defaultJob)
    const [articles, setArticles] = useState([{}])
    const [articleCount, setArticleCount] = useState(1)
    const [autoId, setAutoId] = useState(true)

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
        network.getJobTypeList().then((result) => {
            const types = result.data
            let options = []
            for (let i in types) {
                let type = types[i]
                if (type.jobType !== 1) {
                    options.push({
                        key: type.jobType,
                        text: type.typeName,
                        value: type.jobType,
                    })
                }
            }

            setTypeOptions(options)
        })
    }, [])

    const handleInput = (event) => {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (name === 'deliveryWeek') {
            if (value < 1) value = 1
            if (value > 53) value = 53
        }

        setJob({ ...job, [name]: value })

        if (name === 'deliveryWeek') {
            setJob({
                ...job,
                deliveryWeek: value,
                deliveryDate: moment().set('week', value).toDate(),
            })
        }
    }

    const articleDropdowns = []
    for (let i = 0; i < articleCount; i++) {
        articleDropdowns.push(
            <Stack direction={['column', 'row']} spacing="24px" horizontal="true" key={`ArticleDropdownWizardSegmentGroup_${i}`}>
                <Box w={600} p={6} key={`ArticleDropdownWizardSegment_${i}`}>
                    <ArticleDropdown
                        key={`ArticleDropdownWizard_${i}`}
                        selected={articles}
                        onArticleSelected={(ev, data, selected, selectedArticle) => {
                            articles[i] = selectedArticle
                            setArticles(articles)
                        }}
                        defaultSelectedArticles={articles[i]}
                        single
                    />
                </Box>
                <Box p={6}>
                    <Button
                        key={`ArticleDropdownWizardBtnRmv_${i}`}
                        onClick={() => {
                            articles.splice(i, 1)
                            setArticles(articles)
                            setArticleCount(articleCount - 1)
                        }}
                    >
                        Rimuovi articolo
                    </Button>
                </Box>
            </Stack>
        )
    }

    return (
        <>
            <Step.Group style={{ width: '100%' }}>
                <Step active={step === 0}>
                    <Icon name="tablet alternate" />
                    <Step.Content>
                        <Step.Title>Dati generali ordine cliente</Step.Title>
                        <Step.Description>Data di chiusura prevista, cliente, immagine</Step.Description>
                    </Step.Content>
                </Step>

                {job.jobType === 3 && (
                    <Step active={step === 1}>
                        <Icon name="cube" />
                        <Step.Content>
                            <Step.Title>Articoli ordinati</Step.Title>
                            <Step.Description>Quantità e data di consegna</Step.Description>
                        </Step.Content>
                    </Step>
                )}

                <Step active={step === 2}>
                    <Icon name="check" />
                    <Step.Content>
                        <Step.Title>Fine</Step.Title>
                        <Step.Description>Procedura completa</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            {step === 0 && (
                <>
                    <Box width={'100%'} m="0" p="12">
                        <Form
                            success={job.status === 'success'}
                            error={job.status === 'error'}
                            warning={job.status === 'warning'}
                            style={{ margin: 8, marginBottom: 42 }}
                        >
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="form-input-control-job-id"
                                    control={Input}
                                    label={<span className="label">Codice ordine cliente interno</span>}
                                    name="jobName"
                                    value={job.jobName}
                                    onChange={(e) => handleInput(e)}
                                    placeholder="Ordine cliente interno"
                                />
                                <Form.Field
                                    id="form-input-control-job-client-code"
                                    control={Input}
                                    label={<span className="label">Numero documento cliente</span>}
                                    name="jobClientCode"
                                    value={job.jobClientCode}
                                    // disabled={!!id || updating}
                                    onChange={(e) => handleInput(e)}
                                    placeholder="Numero documento cliente"
                                />
                                {/* <Form.Field
                                    id="form-input-control-job-type"
                                    control={Select}
                                    options={typeOptions}
                                    label={<span className="label">Tipo commessa</span>}
                                    placeholder="Tipo commessa"
                                    name="jobType"
                                    value={job.jobType}
                                    onChange={(e, data) => {
                                        let id = data.value
                                        setJob({ ...job, jobType: id })
                                    }}
                                />
                                <Form.Field
                                    id="form-input-control-job-type"
                                    control={Select}
                                    options={[
                                        {
                                            key: 0,
                                            value: 0,
                                            text: 'Normale',
                                        },
                                    ]}
                                    label={<span className="label">Tipo macchina</span>}
                                    placeholder="Tipo macchina"
                                    name="jobMachineType"
                                    value={job.jobMachineType}
                                    onChange={(e, data) => {
                                        let id = data.value
                                        setJob({ ...job, jobMachineType: id })
                                    }}
                                /> */}
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="form-input-control-job-description"
                                    control={TextArea}
                                    label={<span className="label">Descrizione</span>}
                                    placeholder="Fresature"
                                    name="description"
                                    value={job.description}
                                    onChange={(e) => handleInput(e)}
                                />
                            </Form.Group>
                            <Box p={0} pb={2}>
                                <Box textStyle="header" as="h1">
                                    Cliente
                                </Box>
                                <CustomerDropdown
                                    single={true}
                                    onChange={(ev, data, selected, customers, displayName) => {
                                        setJob({
                                            ...job,
                                            customerId: selected,
                                            client: displayName,
                                        })
                                    }}
                                    defaultSelectedCustomers={job.customerId}
                                />
                                {/* </Form.Group> */}
                            </Box>
                            <Form.Group>
                                {/* <Form.Field
                                    id="form-input-control-job-location"
                                    control={Input}
                                    label={<span className="label">Ubicazione (se applicabile)</span>}
                                    placeholder="Codice locazione"
                                    name="jobLocation"
                                    value={job.jobLocation}
                                    onChange={(e) => this.handleInput(e)}
                                /> */}
                                {job.jobType !== 3 && (
                                    <>
                                        <Form.Field
                                            id="form-input-control-job-delivery-week"
                                            control={Input}
                                            label={<span className="label">Settimana di consegna</span>}
                                            placeholder="32"
                                            name="deliveryWeek"
                                            value={job.deliveryWeek}
                                            onChange={(e) => handleInput(e)}
                                            disabled={job.jobType === 3}
                                        />
                                        <Form.Field
                                            id="form-input-control-job-delivery-date"
                                            control={ReactDatePicker}
                                            label={<span className="label">Data di consegna</span>}
                                            name="deliveryDate"
                                            value={job.deliveryDate}
                                            dateFormat="dd/MM/yyyy"
                                            selected={job.deliveryDate}
                                            disabled={job.jobType === 3}
                                            onChange={(data) => {
                                                let deliveryWeek = moment(data).get('week')

                                                setJob({
                                                    ...job,
                                                    deliveryDate: data,
                                                    deliveryWeek,
                                                })
                                            }}
                                        />
                                    </>
                                )}
                                <Form.Field
                                    id="form-input-control-job-order-date"
                                    control={ReactDatePicker}
                                    label={<span className="label">Data ordine</span>}
                                    name="orderDate"
                                    value={job.orderDate}
                                    dateFormat="dd/MM/yyyy"
                                    selected={job.orderDate}
                                    onChange={(data) => {
                                        setJob({
                                            ...job,
                                            orderDate: data,
                                        })
                                    }}
                                    autoComplete="off"
                                />
                            </Form.Group>
                            {/* <Form.Group>
                                <Form.Field
                                    id="form-input-control-job-id"
                                    control={Input}
                                    label={<span className="label">Identificativo ordine</span>}
                                    name="jobCode"
                                    value={job.jobCode}
                                    // disabled={!!id || updating}
                                    onChange={(e) => handleInput(e)}
                                    placeholder="Ordine cliente"
                                    disabled={autoId}
                                />
                                <Form.Field
                                    control={Switch}
                                    label={<span className="label">Genera automaticamente codice ordine cliente</span>}
                                    defaultChecked={autoId}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setJob({ ...job, jobCode: '#AUTO#' })
                                            setAutoId(true)
                                        } else {
                                            setJob({ ...job, jobCode: '' })
                                            setAutoId(false)
                                        }
                                    }}
                                />
                            </Form.Group> */}
                            {job.jobType !== 3 && (
                                <Form.Group widths="equal">
                                    <Form.Field
                                        id="form-textarea-control-job-program"
                                        control={Input}
                                        label={<span className="label">Programma</span>}
                                        placeholder="Programma"
                                        name="jobProgram"
                                        value={job.jobProgram}
                                        disabled
                                        onChange={(e) => handleInput(e)}
                                    />
                                    <PickFile
                                        onChange={(jobProgram) => {
                                            setJob({ ...job, jobProgram: jobProgram })
                                        }}
                                        value={job.jobProgram}
                                    />
                                </Form.Group>
                            )}
                            {/* <Form.Group widths="equal" style={{ margin: 0 }}> */}
                        </Form>
                    </Box>
                    {/* <Box width={[0.99, 0.99, 0.99, 1.7 / 10]} m="0" p="0">
                        <PickImage
                            onChange={(image) => {
                                setJob({ ...job, image: image })
                            }}
                            value={job.image}
                        />
                    </Box> */}
                </>
            )}
            {step === 1 && (
                <>
                    {articleDropdowns}
                    <Button
                        onClick={() => {
                            setArticleCount(articleCount + 1)
                            articles.push({})
                            setArticles(articles)
                        }}
                    >
                        Aggiungi articolo
                    </Button>
                </>
            )}
            {step === 2 && (
                <>
                    <Stack direction={['column', 'row']} spacing="24px">
                        <Box p={6}>
                            <Box textStyle="header">Dati anagrafici</Box>
                            <Job noRequests noActions type="full" job={job} />
                        </Box>

                        {job.jobType === 3 && (
                            <Box p={6}>
                                <Box textStyle="header">Dati articoli</Box>
                                {/* <Table> */}
                                {/* <Article type="header" /> */}
                                {articles.map((value, index) => {
                                    return <Article key={`article-${value.articleId}`} noActions type="full" article={value} />
                                })}
                                {/* </Table> */}
                            </Box>
                        )}
                    </Stack>
                </>
            )}
            <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                {step > 0 && (
                    <Button
                        onClick={() => {
                            if (step === 2 && job.jobType !== 3) {
                                setStep(step - 2)
                            } else {
                                setStep(step - 1)
                            }
                        }}
                    >
                        Indietro
                    </Button>
                )}
                {step < 2 && (
                    <Button
                        onClick={() => {
                            if (step === 0) {
                                if (!job.jobName) {
                                    error(createAlert('Dati mancanti', "Inserire l'ordine cliente interno"))
                                    return
                                }

                                if (!job.jobClientCode) {
                                    error(createAlert('Dati mancanti', 'Inserire il numero documento cliente'))
                                    return
                                }

                                if (!job.description) {
                                    error(createAlert('Dati mancanti', 'Inserire una descrizione'))
                                    return
                                }

                                if (!job.customerId) {
                                    error(createAlert('Dati mancanti', 'Inserire un cliente'))
                                    return
                                }

                                if (!job.orderDate) {
                                    error(createAlert('Dati mancanti', 'Inserire una data ordine'))
                                    return
                                }
                            }

                            if (step === 1) {
                                if (articles.length === 0 || !articles[0].articleId) {
                                    error(createAlert('Dati mancanti', 'Inserire almeno un articolo'))
                                    return
                                }

                                for (const key in articles) {
                                    if (articles[key].quantity !== 0 && !articles[key].quantity) {
                                        error(createAlert('Dati mancanti', 'Inserire le quantità da produrre'))
                                        return
                                    }

                                    if (!articles[key].deliveryDate) {
                                        error(createAlert('Dati mancanti', 'Inserire le date di consegna'))
                                        return
                                    }

                                    if (!articles[key].productionDate) {
                                        error(createAlert('Dati mancanti', 'Inserire le date di produzione'))
                                        return
                                    }
                                }
                            }

                            if (step === 0 && job.jobType !== 3) {
                                setStep(step + 2)
                            } else {
                                setStep(step + 1)
                            }
                        }}
                    >
                        Avanti
                    </Button>
                )}
                {step === 2 && (
                    <Button
                        color="green"
                        onClick={() => {
                            if (autoId) {
                                job.jobCode = '#AUTO#'
                            }

                            const toSave = {
                                ...job,
                                deliveryDate: job.deliveryDate
                                    ? moment(job.deliveryDate).add('hour', 2).format('YYYY-MM-DD')
                                    : moment().format('YYYY-MM-DD'),
                            }
                            delete toSave.deliveryWeek
                            network.createJob(job.jobCode, toSave).then((result) => {
                                for (const article of articles) {
                                    if (article.articleId) {
                                        network.createWorkOrder({
                                            workCode: article.articleCode,
                                            jobCode: result.jobCode,
                                            clientOrderCode: result.jobCode,
                                            product: article.articleCode,
                                            productQuantity: article.quantity,
                                            remainingQuantity: article.quantity,
                                            producedQuantity: 0,
                                            deliveredQuantity: 0,
                                            articleId: article.articleId,
                                            completed: 0,
                                            deliveryDate: moment(article.deliveryDate).format('YYYY-MM-DD'),
                                            productionDate: moment(article.productionDate).format('YYYY-MM-DD'),
                                        })
                                    }
                                }
                                success(
                                    createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Visualizza', () => {
                                        history.push(route_from(app, printjobs))
                                    })
                                )
                            })
                            setJob(defaultJob)
                            setArticles([{}])
                            setStep(0)
                        }}
                    >
                        Fine
                    </Button>
                )}
            </View>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMold, mergeMold } = state

    return {
        getMachineList,
        deleteMold,
        mergeMold,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(JobWizard)))
