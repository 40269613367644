import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { Confirmation } from '../components'

const { app, jobs, costUpdate } = routes
moment.locale('it')

class Cost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleting: false,
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            ...props.cost,
        }

        let { onCostChange, cost } = props

        if (onCostChange && cost) {
            onCostChange(cost.costPrice, cost.costId)
        }
    }

    totalCost() {
        let { costPrice } = this.state
        return costPrice
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange } = this.props
        let { costId, costPrice } = this.state
        if (costPrice !== prevState.costPrice) {
            if (onCostChange) {
                onCostChange(costPrice, costId)
            }
        }
    }

    renderBalanceFooter() {
        let { total } = this.props

        return (
            <Table.Row>
                <Table.Cell width="3" />
                <Table.Cell width="3" />
                <Table.Cell width="3" />
                <Table.Cell width="4" />
                <Table.Cell width="1">Totale</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="3">Data</Table.HeaderCell>
                    <Table.HeaderCell width="3">Descrizione</Table.HeaderCell>
                    <Table.HeaderCell width="3">Fornitore</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { costDescription, costPrice, costDate, producer } = this.state
        return (
            <Table.Row>
                <Table.Cell>{moment(costDate).format('ll')}</Table.Cell>
                <Table.Cell>{costDescription}</Table.Cell>
                <Table.Cell>{producer}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={costPrice}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    costPrice: value,
                                })
                            }}
                        />
                        €
                    </span>
                    <span className="only-print">{costPrice}€</span>
                </Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                        <Table.HeaderCell>Descrizione</Table.HeaderCell>
                        <Table.HeaderCell>Fornitore</Table.HeaderCell>
                        <Table.HeaderCell>Prezzo</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { deleting, costId, costDescription, costPrice, costDate, producer } = this.state
        let { isBalance } = this.props

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    <Confirmation
                        message="Vuoi davvero eliminare questo costo?"
                        showModal={deleting}
                        onConfirm={() => {
                            this.remove()
                        }}
                        onCancel={() => {
                            this.setState({ deleting: false })
                        }}
                    />
                    <Table.Cell>{moment(costDate).format('ll')}</Table.Cell>
                    <Table.Cell>{costDescription}</Table.Cell>
                    <Table.Cell>{producer}</Table.Cell>
                    <Table.Cell>{costPrice}</Table.Cell>
                    <Table.Cell width="4">
                        <Button
                            color="blue"
                            onClick={() => {
                                this.props.history.push(route_from(app, jobs, costUpdate, `${costId}`))
                            }}
                        >
                            <Icon name="edit" />
                            Modifica
                        </Button>
                        <Button
                            color="red"
                            onClick={() => {
                                this.openRemoveDialog()
                            }}
                        >
                            <Icon name="delete" />
                            Elimina
                        </Button>
                    </Table.Cell>
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { costId } = this.state
        let { network } = this.props

        network.deleteCost({
            costId,
        })

        this.setState({ deleting: false })
    }

    render() {
        let { deleting, costId, costDescription, costPrice, costDate, producer } = this.state
        let { type } = this.props
        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Box style={styles.costCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questo costo?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.costContent}>
                                <View fullw row>
                                    <View fullw column>
                                        <span style={styles.header}>{costPrice}</span>
                                        <Box textStyle="description">
                                            {costDescription}, {producer}
                                        </Box>
                                        <Box textStyle="meta" style={{ textTransform: 'capitalize' }}>{moment(costDate).format('LLLL')}</Box>
                                    </View>
                                </View>
                            </Box>
                            <Box textStyle="content" extra>
                                <a href='/'>
                                    <Icon name="cogs" />
                                    Dettagli
                                </a>
                            </Box>
                            <Box textStyle="content" extra>
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.props.history.push(route_from(app, jobs, costUpdate, `${costId}`))
                                    }}
                                >
                                    <Icon name="edit" />
                                    Modifica
                                </Button>
                                <Button
                                    color="red"
                                    onClick={() => {
                                        this.openRemoveDialog()
                                    }}
                                >
                                    <Icon name="delete" />
                                    Elimina
                                </Button>
                            </Box>
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        height: 128,
        width: 128,
        marginRight: 28,
    },
    costImage: {
        height: 128,
        width: 128,
        objectFit: 'contain',
    },
    costContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    costCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = () => {
    return {}
}
export default withRouter(connect(mapStateToProps)(connectNetwork(Cost)))
