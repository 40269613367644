import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Checkbox, Input, Placeholder, Table } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { CLIENTORDERLISTSTATE_ACTIONS } from '../actions/clientOrderListState'
import { Empty } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'
import DropFile from '../utility/DropFile'

const moment = require('moment-timezone')

const { app, clientOrders, clientOrderEdit, newEl } = routes

class ClientOrders extends Component {
    constructor(props) {
        super(props)
        let type = this.props.type ? this.props.type : 'full'
        let direction = this.props.direction ? this.props.direction : 'column'
        let select = !!this.props.select
        let searchable = !!this.props.searchable
        let sub = !!this.props.sub

        let updateSearch = debounce(200, false, (page, items, selectSearch, isEmployee) => {
            this.props.network.getClientOrderList(page, items, selectSearch, isEmployee, this.props.createdExternal, 0, 'all', this.state.filters)
        })

        this.state = {
            page: 0,
            items: 10,
            type,
            direction,
            select,
            searchable,
            selectedClientOrder: {},
            clientOrderOptions: [],
            selectSearch: '',
            selectOpen: false,
            firstTime: true,
            opened: {},
            scrollTop: 0,
            sub,
            onLoaded: null,
            selected: this.props.selected ? this.props.selected : {},
            updateSearch,
            filters: {},
            column: 'clientOrderCode',
            coldirection: 'desc',
            clientOrderList: [],
            processingFile: false,
        }

        this.updateFiltersDebounced = debounce(500, false, (filters) => {
            let {
                search: { data: searchText },
            } = this.props

            this.setState({ filters, page: 0 }, () => {
                this.props.network.getClientOrderList(
                    this.state.page,
                    this.state.items,
                    searchText,
                    !!this.props.isEmployee,
                    this.props.createdExternal,
                    'all',
                    this.state.filters
                )
            })
        })

        this.refreshDebounced = debounce(500, false, this.refresh)
    }

    store() {
        let {
            page,
            items,
            type,
            direction,
            select,
            searchable,
            selectedClientOrder,
            clientOrderOptions,
            selectSearch,
            selectOpen,
            firstTime,
            sub,
            opened,
            selected,
            scrollTop,
        } = this.state

        let toStore = {
            page,
            items,
            type,
            direction,
            select,
            searchable,
            selectedClientOrder,
            clientOrderOptions,
            selectSearch,
            selectOpen,
            firstTime,
            sub,
            opened,
            selected,
            scrollTop,
            createdExternal: !!this.props.createdExternal,
        }

        this.props.storeClientOrderListState(toStore)
    }

    restore(state) {
        let restored = false
        if (state.createdExternal === !!this.props.createdExternal) {
            this.setState(state)
            restored = true
        }
        this.props.clearClientOrderListState({})
        return restored
    }

    componentWillUnmount() {
        let { select } = this.props
        if (!select) {
            this.store()
        }
        this.state.updateSearch.cancel()
    }

    componentDidMount() {
        let { select, selectSearch, scrollTop, page, items } = this.state
        let {
            search: { data: searchText },
            searchable,
            isEmployee,
            clientOrderListState,
            workerId,
            createdExternal,
            network,
        } = this.props

        network.getMappingData(isEmployee)

        if (clientOrderListState && clientOrderListState.stored) {
            if (this.restore(clientOrderListState.state)) {
                ({ select, searchable, selectSearch, page, items, scrollTop } = clientOrderListState.state)

                if (scrollTop > 0) {
                    let scrollTarget = scrollTop //Math.ceil(scrollTop / 311) * 311;
                    this.setState({
                        scrollTarget,
                        onLoaded: () => {
                            let { scrollTarget } = this.state
                            let container = document.getElementsByClassName('clientOrderListContainer')[0]

                            setTimeout(() => {
                                container.scrollTo({ top: scrollTarget, left: 0, behavior: 'smooth' })
                            }, 1000)
                        },
                    })
                }
            }
        }

        if (!searchable) {
            searchText = ''
        }

        if (select) {
            searchText = selectSearch
        }

        this.setToolbar()

        if (isEmployee) {
            this.props.network.getRecentClientOrderList(page, items, searchText, workerId, !!isEmployee, createdExternal)
        } else {
            this.props.network.getClientOrderList(page, items, searchText, !!isEmployee, createdExternal, 'all', this.state.filters)
        }
    }

    updateFilters = (filters) => {
        this.updateFiltersDebounced(filters)
    }

    setToolbar() {
        let { login } = this.props
        let { page: currentPage } = this.state

        let pageItems = [
            {
                name: `${parseInt(currentPage) + 1}`,
                position: undefined,
                type: 'text',
            } /*,
				{
					name: `${pageCount}`,
					position: undefined,
					type: 'text'
				}*/,
        ]

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_job) {
            extraTools.push({
                name: 'Crea nuovo ordine cliente',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, clientOrders, newEl, clientOrderEdit))
                },
            })
        }

        const toolbar = [
            ...extraTools,
            {
                name: '',
                icon: 'arrow left',
                position: 'right',
                action: () => {
                    this.prev()
                },
            },
            ...pageItems,
            {
                name: '',
                icon: 'arrow right',
                position: undefined,
                action: () => {
                    this.next()
                },
            },
        ]

        const filters = {
            // TODO
            clientOrderCode: {
                control: Input,
                name: 'clientOrderCode',
                input: 'text',
                placeholder: 'Codice ordine cliente',
                label: 'Codice ordine cliente',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.clientOrderCode = { value: e.target.value, key: 'clientOrderCode', operator: '=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.clientOrderCode
                    this.updateFilters(filters)
                },
            },
            client: {
                control: Input,
                name: 'client',
                input: 'text',
                placeholder: 'Cliente',
                label: 'Cliente',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.client = { value: e.target.value, key: 'client', operator: '=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.client
                    this.updateFilters(filters)
                },
            },
            description: {
                control: Input,
                name: 'description',
                input: 'text',
                placeholder: 'Descrizione',
                label: 'Descrizione',
                onChange: (e) => {
                    const { filters } = this.state

                    filters.description = { value: e.target.value, key: 'description', operator: '=' }
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.description
                    this.updateFilters(filters)
                },
            },
            active: {
                control: Checkbox,
                name: 'active',
                placeholder: 'Attiva',
                label: 'Attiva',
                onChange: (e, data) => {
                    const { filters } = this.state

                    filters.active = data.checked
                    this.updateFilters(filters)
                },
                onReset: () => {
                    const { filters } = this.state

                    delete filters.active
                    this.updateFilters(filters)
                },
            },
        }

        initializePage(this, toolbar, filters)
    }

    refresh() {
        let { page, items } = this.state
        let { isEmployee, workerId, createdExternal, network, search } = this.props

        if (isEmployee) {
            this.props.network.getRecentClientOrderList(page, items, search.data, workerId, isEmployee, createdExternal)
        } else {
            network.getClientOrderList(page, items, search.data, isEmployee, createdExternal, 'all', this.state.filters)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { page, items, select, selectSearch, firstTime } = this.state
        let { isEmployee, workerId, createdExternal, organization, getClientOrderList, network, deleteClientOrder, search, onLoaded } = this.props
        let { getClientOrderList: prevGetClientOrderList } = prevProps

        if (page !== prevState.page) {
            this.setToolbar()
        }

        if (!getClientOrderList.fetching && prevGetClientOrderList.fetching) {
            this.setState({
                clientOrderList: [...getClientOrderList.data.data],
                // clientOrderList: [],
            })
        }

        if (this.props.createdExternal !== prevProps.createdExternal) {
            this.setState({ page: 0 })
        }

        if (
            (!this.props.deleteMapping.fetching && prevProps.deleteMapping.fetching) ||
            (!this.props.createMapping.fetching && prevProps.createMapping.fetching)
        ) {
            network.getMappingData(isEmployee)
        }

        if (
            !select &&
            (page !== prevState.page ||
                items !== prevState.items ||
                (deleteClientOrder.fetching !== prevProps.deleteClientOrder.fetching && !deleteClientOrder.fetching) ||
                (this.props.deletingCost !== prevProps.deletingCost &&
                    this.props.deleteCostResult !== prevProps.deleteCostResult &&
                    this.props.deleteCostStatus.success) ||
                (this.props.creatingClientOrder !== prevProps.creatingClientOrder &&
                    this.props.createResult !== prevProps.createResult &&
                    this.props.createStatus.success) ||
                (this.props.creatingWorkOrder !== prevProps.creatingWorkOrder &&
                    this.props.createWorkOrderResult !== prevProps.createWorkOrderResult &&
                    this.props.createWorkOrderStatus.success) ||
                organization.data !== prevProps.organization.data)
        ) {
            this.refreshDebounced()
        } else if (!select && search !== prevProps.search) {
            this.setState({
                page: 0,
            })

            if (isEmployee) {
                this.props.network.getRecentClientOrderList(0, 10, search.data, workerId, isEmployee, !!createdExternal)
            } else {
                network.getClientOrderList(0, 10, search.data, isEmployee, createdExternal, 'all', this.state.filters)
            }
        } else if (select && getClientOrderList !== prevProps.getClientOrderList) {
            if (getClientOrderList.status.success) {
                let clientOrderOptions = []
                let data = getClientOrderList.data.data
                for (let i in data) {
                    let clientOrder = data[i][0]
                    clientOrderOptions.push({
                        text: clientOrder.clientOrderCode,
                        key: clientOrder.clientOrderCode,
                        value: clientOrder,
                    })
                }

                this.setState({ clientOrderOptions, selectedClientOrder: clientOrderOptions[0], selectOpen: false })
            }
        } else if (select && selectSearch !== prevState.selectSearch) {
            this.state.updateSearch(page, items, selectSearch, isEmployee)
        }

        if (!getClientOrderList.fetching && prevProps.getClientOrderList.fetching) {
            if (onLoaded) {
                onLoaded()
                this.setState({ onLoaded: null })
            }
            if (firstTime) {
                this.setState({ firstTime: false })
            } else {
                setTimeout(() => {
                    this.setState({ selectOpen: true })
                }, 200)
            }
        }
    }

    goToPage(page) {
        this.setState({
            page,
        })
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    onFileSelected = (acceptedFiles) => {
        const { network } = this.props

        // Do something with the files
        this.setState({ processingFile: true })
        const reader = new FileReader()
        reader.onload = async (e) => {
            const list = e.target.result.replace(/\r/g, '').split('\n')
            if (list.length > 0) {
                let header = list[0].split(',')
                let startindex = 0

                let iCodarti = 0
                let iQta = 1
                let iCodord = 2
                let iDate = 3
                let iDelivery = 4

                if (header[0] === 'codarti') {
                    startindex = 1

                    for (let j = 0; j < header.length; j++) {
                        const string = header[j]
                        switch (string) {
                            case 'codarti':
                                iCodarti = j
                                break
                            case 'qta':
                                iQta = j
                                break
                            case 'codord':
                                iCodord = j
                                break
                            case 'date':
                                iDate = j
                                break
                            case 'deliverydate':
                                iDelivery = j
                                break
                            default:
                                break
                        }
                    }
                }

                const cliords = {}
                const workords = []

                for (let i = startindex; i < list.length; i++) {
                    if (list[i] && list[i].length > 0) {
                        const splitted = list[i].split(',')

                        const cliord = {
                            clientOrderCode: splitted[iCodord],
                            description: splitted[iCodord],
                            creationDate: moment(splitted[iDate], 'DD/MM/YYYY').format('YYYY-MM-DD'),
                            deliveryDate: moment(splitted[iDelivery], 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        }
                        cliords[splitted[iCodord]] = cliord

                        const workOrder = {
                            workCode: splitted[iCodarti],
                            clientOrderCode: splitted[iCodord],
                            product: splitted[iCodarti],
                            productQuantity: splitted[iQta],
                        }
                        workords.push(workOrder)
                    }
                }

                for (const key in cliords) {
                    await network.createClientOrder(null, cliords[key])
                }

                for (const wo of workords) {
                    await network.createWorkOrder(wo)
                }
                this.setState({ processingFile: false })
            }
        }
        reader.readAsText(acceptedFiles[0])
    }

    updateSort() {
        const { column, coldirection: direction } = this.state

        const list = [...this.props.getClientOrderList.data.data]
        const newList = list.sort((a, b) => {
            if (column === 'creationDate' || column === 'deliveryDate') {
                const before = moment(a[column]).isBefore(moment(b[column]))
                const condition = direction === 'asc' ? before : !before

                return condition ? -1 : 1
            } else {
                const before = a[column] < b[column]
                const condition = direction === 'asc' ? before : !before

                return condition ? -1 : 1
            }
        })

        this.setState({ clientOrderList: newList })
    }

    render() {
        let {
            getClientOrderList,
            select: _s,
            direction: _d,
            type: _t,
            network,
            noActions,
            selectable,
            getUrgentClientOrderList,
            organization,
            ...rest
        } = this.props
        let { direction, select, column, coldirection, clientOrderList, processingFile } = this.state
        let content = <div />

        let dirobj = {
            [direction === 'row' ? 'overflowX' : 'overflowY']: 'scroll',
            [direction === 'row' ? 'overflowY' : 'overflowX']: 'hidden',
        }

        if (select) {
            content = this.renderSelect()
        } else if (getClientOrderList.fetching || processingFile) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (!select && getClientOrderList.data.data && getClientOrderList.data.data.length > 0) {
            const semdirection = coldirection === 'desc' ? 'descending' : 'ascending'

            return (
                <View
                    className="clientOrderListContainer"
                    fullw
                    fullh
                    {...{ column: direction === 'column', row: direction === 'row' }}
                    style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        ...(select ? {} : dirobj),
                    }}
                    {...rest}
                >
                    <DropFile onFileSelected={this.onFileSelected}>
                        <Table compact sortable celled fixed style={{ width: '99%' }}>
                            <Table.Header>
                                <Table.HeaderCell
                                    sorted={column === 'clientOrderCode' ? semdirection : null}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                column: 'clientOrderCode',
                                                coldirection: coldirection === 'asc' ? 'desc' : 'asc',
                                            },
                                            this.updateSort
                                        )
                                    }}
                                >
                                    Ordine Cliente
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={column === 'workCode' ? semdirection : null}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                column: 'workCode',
                                                coldirection: coldirection === 'asc' ? 'desc' : 'asc',
                                            },
                                            this.updateSort
                                        )
                                    }}
                                >
                                    Articolo
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={column === 'productQuantity' ? semdirection : null}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                column: 'productQuantity',
                                                coldirection: coldirection === 'asc' ? 'desc' : 'asc',
                                            },
                                            this.updateSort
                                        )
                                    }}
                                >
                                    Quantità
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={column === 'creationDate' ? semdirection : null}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                column: 'creationDate',
                                                coldirection: coldirection === 'asc' ? 'desc' : 'asc',
                                            },
                                            this.updateSort
                                        )
                                    }}
                                >
                                    Data ricezione
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={column === 'deliveryDate' ? semdirection : null}
                                    onClick={() => {
                                        this.setState(
                                            {
                                                column: 'deliveryDate',
                                                coldirection: coldirection === 'asc' ? 'desc' : 'asc',
                                            },
                                            this.updateSort
                                        )
                                    }}
                                >
                                    Data consegna
                                </Table.HeaderCell>
                            </Table.Header>
                        </Table>
                        <View fullw fullh style={{ overflowY: 'scroll' }}>
                            <Table compact sortable celled fixed>
                                <Table.Body>
                                    {clientOrderList.map((value, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>{value.clientOrderCode}</Table.Cell>
                                                <Table.Cell>{value.product}</Table.Cell>
                                                <Table.Cell>{value.productQuantity}</Table.Cell>
                                                <Table.Cell>{moment(value.creationDate).format('DD/MM/YYYY')}</Table.Cell>
                                                <Table.Cell>{moment(value.deliveryDate).format('DD/MM/YYYY')}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </View>
                    </DropFile>
                </View>
            )
        } else {
            content = <Empty />
        }

        return (
            <View
                className="clientOrderListContainer"
                fullw
                fullh
                {...{ column: direction === 'column', row: direction === 'row' }}
                style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    ...(select ? {} : dirobj),
                }}
                {...rest}
            >
                <DropFile onFileSelected={this.onFileSelected}>{content}</DropFile>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getClientOrderList,
        getUrgentClientOrderList,
        getRecentClientOrderList,
        deleteClientOrder,
        clientOrderListState,
        createClientOrder: { data: createResult, fetching: creatingClientOrder, status: createStatus },
        createWorkOrder: { data: createWorkOrderResult, fetching: creatingWorkOrder, status: createWorkOrderStatus },
        deleteCost: { data: deleteCostResult, fetching: deletingCost, status: deleteCostStatus },
        search,
        login,
        organization,
        createMapping,
        getMappingData,
        deleteMapping,
    } = state

    const { isEmployee } = ownProps

    return {
        getClientOrderList: isEmployee ? getRecentClientOrderList : getClientOrderList,
        getUrgentClientOrderList,
        deleteClientOrder,
        clientOrderListState,
        search,
        deleteCostResult,
        deletingCost,
        deleteCostStatus,
        createWorkOrderResult,
        creatingWorkOrder,
        createWorkOrderStatus,
        createResult,
        creatingClientOrder,
        createStatus,
        pageCount: getClientOrderList.data.paging ? getClientOrderList.data.paging.pagecount : 0,
        currentPage: getClientOrderList.data.paging ? getClientOrderList.data.paging.page : 0,
        login,
        organization,
        createMapping,
        getMappingData,
        deleteMapping,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),

        storeClientOrderListState: (result) => CLIENTORDERLISTSTATE_ACTIONS.result(result, dispatch),
        clearClientOrderListState: (result) => CLIENTORDERLISTSTATE_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ClientOrders)))
