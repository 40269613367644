export function getWorkOrderListSpan(start, end, isEmployee) {
    return new Promise((resolve, reject) => {
		let { getWorkOrderListSpanRequest, getWorkOrderListSpanError, getWorkOrderListSpanResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_work_order_list_span',
			this.createBundle(
				'get_work_order_list_span',
				{ start, end },
				this.GET,
				getWorkOrderListSpanRequest,
                (result) => {
                    getWorkOrderListSpanResult(result)
                    resolve(result)
                },
                (error) => {
                    getWorkOrderListSpanError(error)
                    reject(error)
                },
				isEmployee
			)
		);
	})
}
