import { Box } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Dimmer, Icon, Loader, Menu, Modal } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Empty, ImageDefault } from '../components'
import { buildFileSelector } from '../lib/util'

class PickImage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 0,
            items: 12,
            image: this.props.value ? this.props.value : '',
            showModal: false,
            fileSelector: buildFileSelector(this.handleUpload),
        }
    }

    componentDidMount() {
        const { page, items } = this.state
        const { network } = this.props
        network.getImageList(page, items)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { network } = this.props

        if (this.props.value !== prevProps.value) {
            this.setState({
                image: this.props.value,
            })
        }

        if (this.props.uploadImage !== prevProps.uploadImage) {
            if (this.props.uploadImage.status.success) {
                this.pickImage(this.props.uploadImage.data.file)
                this.closeModal()
                return
            }
        }

        if (this.state.page !== prevState.page || this.state.items !== prevState.items) {
            network.getImageList(this.state.page, this.state.items)
        }
    }

    pickImage(image) {
        let { onChange } = this.props
        if (onChange) {
            onChange(image)
        }

        this.setState({
            image,
        })
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    uploadImage = () => {
        this.state.fileSelector.click()
    }

    handleUpload = (event, name) => {
        const { network } = this.props
        let obj = { loaded: 0 }
        obj[name] = event.target.files[0]
        this.setState(obj)
        //console.log('Selected file', event.target.files[0]);

        network.uploadImage(event.target.files[0])
    }

    render() {
        const { image } = this.state
        const { fetching, images } = this.props

        return (
            <Modal
                onClose={this.closeModal}
                open={this.state.showModal}
                trigger={
                    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
                        <ImageDefault
                            fromRoot
                            style={{
                                objectFit: 'cover',
                            }}
                            src={image}
                        />

                        <Box p="6">
                            <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
                                <Button
                                    style={{ height: 64 }}
                                    onClick={() => this.setState({ showModal: true })}
                                    colorScheme="blue"
                                    variant="outline"
                                >
                                    Scegli Immagine
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                }
                closeIcon
            >
                <Modal.Header>Selezione immagine</Modal.Header>
                <Modal.Content image scrolling style={{ padding: 0 }}>
                    <View fullw column>
                        <Menu horizontal="true" style={{ borderRadius: '0px' }}>
                            <Menu.Item as="a" icon="upload" name="Carica immagine" onClick={this.uploadImage} position={'left'} />
                            <Menu.Item
                                as="a"
                                icon="arrow left"
                                onClick={() => {
                                    this.prev()
                                }}
                                position={'right'}
                            />
                            <Menu.Item
                                as="a"
                                icon="arrow right"
                                onClick={() => {
                                    this.next()
                                }}
                                position={'none'}
                            />
                        </Menu>
                        <Modal.Description style={{ maxWidth: '100%' }}>
                            {!fetching && (
                                <View fullw wrap row around style={{ padding: 16 }}>
                                    {images && images.length === 0 && <Empty />}
                                    {images &&
                                        images.length > 0 &&
                                        images.map((value, index) => (
                                            <Box
                                                key={index}
                                                onClick={() => {
                                                    this.pickImage(value.picturePath)
                                                }}
                                                style={{
                                                    width: '200px',
                                                    height: '200px',
                                                    marginTop: 16,
                                                    marginBottom: 16,
                                                }}
                                            >
                                                <ImageDefault
                                                    fromRoot
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    src={value.picturePath}
                                                    ui={false}
                                                />
                                                {this.state.picturePath === value.picturePath && (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            bottom: 0,
                                                            left: 0,
                                                            right: 0,
                                                            background: 'rgba(99, 177, 255, 0.60)',
                                                            zIndex: 2,
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        ))}
                                </View>
                            )}
                            {fetching && (
                                <Box p={6} style={{ minHeight: '600px' }}>
                                    <Dimmer active inverted>
                                        <Loader inverted />
                                    </Dimmer>
                                </Box>
                            )}
                        </Modal.Description>
                    </View>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={this.closeModal}>
                        Ok <Icon name="check" />
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getImageList: {
            data: { data },
            fetching,
        },
        uploadImage,
    } = state

    return {
        images: data,
        fetching,
        uploadImage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(PickImage)))
