import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Table } from 'semantic-ui-react'
import { Confirmation } from '../components'

class Item extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, item } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            ...item,
        }

        if (onCostChange && item) {
            onCostChange(this.totalCost(), item.OPERATIONID)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange } = this.props
        let { idCosto, prezzoCosto } = this.state
        if (prezzoCosto !== prevState.prezzoCosto) {
            if (onCostChange) {
                onCostChange(prezzoCosto, idCosto)
            }
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, item } = this.props

        network.deleteItem(item)

        this.setState({ deleting: false })
    }

    totalCost() {
        let { cost, QTY } = this.state
        return cost * QTY
    }

    renderBalanceFooter() {
        let { total, grouped } = this.props

        if (grouped) {
            return (
                <Table.Row>
                    <Table.Cell width="5" />
                    <Table.Cell width="4" />
                    <Table.Cell width="4" />
                    <Table.Cell width="1">Totale:</Table.Cell>
                    <Table.Cell width="2">{!isNaN(total) ? Math.round(total * 100) / 100 : '--'}€</Table.Cell>
                </Table.Row>
            )
        } else {
            return (
                <Table.Row>
                    <Table.Cell width="2" />
                    <Table.Cell width="2" />
                    <Table.Cell width="2" />
                    <Table.Cell width="2" />
                    <Table.Cell width="1" />
                    <Table.Cell width="1" />
                    <Table.Cell width="1" />
                    <Table.Cell width="1">Totale:</Table.Cell>
                    <Table.Cell width="2">{total}€</Table.Cell>
                </Table.Row>
            )
        }
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderBalanceHeader() {
        let { grouped } = this.props

        if (grouped) {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell width="5">Categoria</Table.HeaderCell>
                        <Table.HeaderCell width="4">Quantità</Table.HeaderCell>
                        <Table.HeaderCell width="4">Prezzo medio per pezzo</Table.HeaderCell>
                        <Table.HeaderCell width="1" />
                        <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell width="2">Codice articolo</Table.HeaderCell>
                        <Table.HeaderCell width="2">Descrizione</Table.HeaderCell>
                        <Table.HeaderCell width="2">Categoria</Table.HeaderCell>
                        <Table.HeaderCell width="2">Fornitore</Table.HeaderCell>
                        <Table.HeaderCell width="1">Macchina</Table.HeaderCell>
                        <Table.HeaderCell width="1">Quantità</Table.HeaderCell>
                        <Table.HeaderCell width="1">Prezzo</Table.HeaderCell>
                        <Table.HeaderCell width="1" />
                        <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderTableHeader() {
        let { isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell width="2">Codice articolo</Table.HeaderCell>
                        <Table.HeaderCell width="2">Descrizione</Table.HeaderCell>
                        <Table.HeaderCell width="2">Categoria</Table.HeaderCell>
                        <Table.HeaderCell width="2">Fornitore</Table.HeaderCell>
                        <Table.HeaderCell width="1">Macchina</Table.HeaderCell>
                        <Table.HeaderCell width="1">Quantità</Table.HeaderCell>
                        <Table.HeaderCell width="1">Prezzo</Table.HeaderCell>
                        <Table.HeaderCell width="1" />
                        <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    /*
	<Table.HeaderCell>Cassetto</Table.HeaderCell>
	<Table.Cell>{DRAWERNUMBER}</Table.Cell>
	<Table.HeaderCell>Quantita' utilizzata</Table.HeaderCell>
	<Table.Cell>{QTYUSED}</Table.Cell>
	*/

    renderForBalance() {
        let { grouped } = this.props
        let { cost, supplier, groupcode, itemcode, DESCR, ITEMSUBGROUP, MACHINENUMBER, QTY, totalcost } = this.state

        // TODO totalcost ONLY UNTIL THERE IS AN EDITABLE FIELD

        if (grouped) {
            return (
                <Table.Row>
                    <Table.Cell>{groupcode}</Table.Cell>
                    <Table.Cell>{QTY}</Table.Cell>
                    <Table.Cell>{cost}€</Table.Cell>
                    <Table.Cell>=</Table.Cell>
                    <Table.Cell>{totalcost}€</Table.Cell>
                </Table.Row>
            )
        } else {
            return (
                <Table.Row>
                    <Table.Cell>{itemcode}</Table.Cell>
                    <Table.Cell>{DESCR}</Table.Cell>
                    <Table.Cell>{ITEMSUBGROUP}</Table.Cell>
                    <Table.Cell>{supplier}</Table.Cell>
                    <Table.Cell>{MACHINENUMBER}</Table.Cell>
                    <Table.Cell>{QTY}</Table.Cell>
                    <Table.Cell>{cost}€</Table.Cell>
                    <Table.Cell>=</Table.Cell>
                    <Table.Cell>{this.totalCost()}€</Table.Cell>
                </Table.Row>
            )
        }
    }

    renderForTable() {
        let { isBalance } = this.props
        let { cost, supplier, itemcode, DESCR, ITEMSUBGROUP, MACHINENUMBER, QTY } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    <Table.Cell>{itemcode}</Table.Cell>
                    <Table.Cell>{DESCR}</Table.Cell>
                    <Table.Cell>{ITEMSUBGROUP}</Table.Cell>
                    <Table.Cell>{supplier}</Table.Cell>
                    <Table.Cell>{MACHINENUMBER}</Table.Cell>
                    <Table.Cell>{QTY}</Table.Cell>
                    <Table.Cell>{cost}€</Table.Cell>
                    <Table.Cell>=</Table.Cell>
                    <Table.Cell>{this.totalCost()}€</Table.Cell>
                </Table.Row>
            )
        }
    }

    render() {
        let { type, item, onClick, selected, showNoItem } = this.props
        let {
            deleting,
            cost,
            supplier,
            itemcode,
            itemaliascode,
            DESCR,
            DRAWERNUMBER,
            FUNCTIONCODE,
            ITEMSUBGROUP,
            MACHINENUMBER,
            PACKETSIZE,
            QTY,
            QTYACTIVE,
            QTYCOUNTED,
            QTYGENERAL,
            QTYINASSY,
            QTYNEW,
            QTYONORDER,
            QTYREWORK,
            QTYUSED,
            TRANSCODE,
        } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(item)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        if (item && item.idMacchina === 1 && !showNoItem) {
            return null
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.itemCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questa macchina?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.itemContent}>
                                <View fullw row>
                                    <View fullw column>
                                        <span style={styles.header}>{itemcode}</span>
                                        {supplier && <Box textStyle="description">Fornitore: {supplier}</Box>}
                                        {itemaliascode && <Box textStyle="description">Alias: {itemaliascode}</Box>}
                                        {DESCR && <Box textStyle="description">Descrizione: {DESCR}</Box>}
                                        {DRAWERNUMBER && <Box textStyle="description">Cassetto: {DRAWERNUMBER}</Box>}
                                        {FUNCTIONCODE && <Box textStyle="description">Azione: {FUNCTIONCODE}</Box>}
                                        {ITEMSUBGROUP && <Box textStyle="description">Gruppo: {ITEMSUBGROUP}</Box>}
                                        {MACHINENUMBER && <Box textStyle="description">Macchina: {MACHINENUMBER}</Box>}
                                        {PACKETSIZE && <Box textStyle="description">Dimensioni pacchetto: {PACKETSIZE}</Box>}
                                        {QTY && <Box textStyle="description">Quantità: {QTY}</Box>}
                                        {QTYACTIVE && <Box textStyle="description">qtyactive: {QTYACTIVE}</Box>}
                                        {QTYCOUNTED && <Box textStyle="description">qtycounted: {QTYCOUNTED}</Box>}
                                        {QTYGENERAL && <Box textStyle="description">qtygeneral: {QTYGENERAL}</Box>}
                                        {QTYINASSY && <Box textStyle="description">qtyinassy: {QTYINASSY}</Box>}
                                        {QTYNEW && <Box textStyle="description">qtynew: {QTYNEW}</Box>}
                                        {QTYONORDER && <Box textStyle="description">qtyonorder: {QTYONORDER}</Box>}
                                        {QTYREWORK && <Box textStyle="description">qtyrework: {QTYREWORK}</Box>}
                                        {QTYUSED && <Box textStyle="description">qtyused: {QTYUSED}</Box>}
                                        {cost && <Box textStyle="description">Prezzo: {cost}</Box>}

                                        <Box textStyle="meta" style={{ textTransform: 'capitalize' }}>{TRANSCODE}</Box>
                                    </View>
                                </View>
                            </Box>
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    itemImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    itemContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = () => {
    return {}
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Item)))
