export function createWorkOrder(data, isEmployee) {
    let { createWorkOrderRequest, createWorkOrderError, createWorkOrderResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'create_work_order',
            this.createBundle(
                'create_work_order',
                { work_order: data },
                this.POST,
                createWorkOrderRequest,
                (result) => {
                    createWorkOrderResult(result)
                    resolve(result)
                },
                (error) => {
                    createWorkOrderError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
