import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickImage } from '../components'

class WorkerEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { worker } = this.props.match.params

        if (!id) {
            id = worker
        }

        this.state = {
            workerId: id,
            pin: '',
            workerName: '',
            workerHourlyRate: 0.0,
            dailyHours: 8,
            workerImage: '',
            listingPrices: {},
        }
    }

    componentDidMount() {
        let { workerId } = this.state

        if (workerId) {
            this.props.network.getWorker(workerId)
        }

        this.props.network.getListingList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                // console.log(
                //     'Create result is: ',
                //     this.props.createResult,
                //     this.props.createStatus,
                //     this.props.createStatus.success ? 'Success!' : 'Failure!'
                // )
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getWorker } = this.props
        if (getWorker && getWorker.fetching !== prevProps.getWorker.fetching && !getWorker.fetching) {
            if (getWorker.status.success) {
                let worker = getWorker.data.data
                const { listingPrices } = this.state
                for (const listing of worker.listings) {
                    listingPrices[listing.listingId] = listing.price
                }
                this.setState(worker)
            }
        }
    }

    save() {
        // TODO value checking
        let { workerId, pin, workerName, workerHourlyRate, dailyHours, workerImage, listingPrices } = this.state

        if (workerName === '' || pin === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createWorker({
            workerId,
            pin,
            workerName,
            workerHourlyRate,
            dailyHours,
            workerImage,
            listingPrices,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { creating, pin, workerName, workerHourlyRate, dailyHours, workerImage } = this.state
        const { getListingList } = this.props

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-employee-name"
                            control={Input}
                            label={<span className="label">Nome Impiegato</span>}
                            name="workerName"
                            value={workerName}
                            onChange={(e) => this.handleInput(e)}
                            placeholder="Giorgio"
                        />
                        <Form.Field
                            id="form-input-control-employee-pin"
                            control={Input}
                            label={<span className="label">Pin di accesso</span>}
                            placeholder="0000"
                            name="pin"
                            value={pin}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-employee-cost"
                            control={Input}
                            type="number"
                            label={<span className="label">Prezzo orario</span>}
                            placeholder="0.0"
                            step="0.1"
                            name="workerHourlyRate"
                            value={workerHourlyRate}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-employee-hours"
                            control={Input}
                            type="number"
                            label={<span className="label">Ore giornaliere</span>}
                            placeholder="8"
                            name="dailyHours"
                            value={dailyHours}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-employee-image"
                            control={Input}
                            label={<span className="label">Immagine</span>}
                            placeholder="Immagine"
                            name="workerImage"
                            value={workerImage}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickImage
                            onChange={(image) => {
                                this.setState({ workerImage: image })
                            }}
                            value={this.state.workerImage}
                        />
                    </Form.Group>
                    {getListingList &&
                        getListingList.data &&
                        getListingList.data.data &&
                        getListingList.data.data.length > 0 &&
                        getListingList.data.data.map((value, index) => {
                            return (
                                <Form.Group key={`worker_editor_listing_${index}`}>
                                    <Form.Field
                                        id={`form-textarea-control-employee-listing-${index}`}
                                        control={Input}
                                        label={<span className="label">Prezzo listino {value.listingName}</span>}
                                        placeholder="10.00"
                                        name="listingPrice"
                                        type="number"
                                        step="0.01"
                                        value={this.state.listingPrices[value.listingId] ? this.state.listingPrices[value.listingId] : 0}
                                        onChange={(e) => {
                                            const listingPrices = this.state.listingPrices
                                            listingPrices[value.listingId] = e.target.value
                                            this.setState({ listingPrices })
                                        }}
                                    />
                                </Form.Group>
                            )
                        })}
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Lavoratore inserito con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        createWorker: { data: createResult, fetching: creating, status: createStatus },
        getWorker,
        getListingList,
    } = state

    return {
        createResult,
        creating,
        createStatus,
        getWorker,
        getListingList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WorkerEditor)))
