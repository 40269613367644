export function getRecentMachineList(search, workerId, isEmployee, filters, sorts, page, items) {
    let { getRecentMachineListRequest, getRecentMachineListError, getRecentMachineListResult } = this.props

    /* eslint-disable */
    console.log('getRecentMachineList', { search, workerId, filters, sorts, page, items })

    this.doAction(
        this.WEB_REQUEST_URL + 'get_recent_machine_list',
        this.createBundle(
            'get_recent_machine_list',
            { search, workerId, filters, sorts, page, items },
            this.POST,
            getRecentMachineListRequest,
            getRecentMachineListResult,
            getRecentMachineListError,
            isEmployee
        )
    )
}
