export function getMachine(data) {
    return new Promise((resolve, reject) => {
        let { getMachineRequest, getMachineError, getMachineResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_machine',
            this.createBundle(
                'get_machine',
                { id: data },
                this.GET,
                getMachineRequest,
                (result) => {
                    getMachineResult(result)
                    resolve(result)
                },
                (err) => {
                    // console.log('getMachineError')
                    getMachineError(err)
                    reject(err)
                }
            )
        )
    })
}
