import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { Button, Icon, Input, Modal, Placeholder } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { Article, Empty, PickFile } from '../components'
import { baseViewDispatch, createAlert, initializePage } from '../lib/util'
import CustomerDropdown from '../utility/CustomerDropdown'
import JobDropdown from '../utility/JobDropdown'
import KitDropdown from '../utility/KitDropdown'
import MasterDropdown from '../utility/MasterDropdown'
import MaterialDropdown from '../utility/MaterialDropdown'
import MoldDropdown from '../utility/MoldDropdown'
import PackageDropdown from '../utility/PackageDropdown'
const moment = require('moment-timezone')

const { app, articles, newEl, articleEdit } = routes

class Articles extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
            filters: props.filters ? props.filters : {},
            sorts: props.sorts ? props.sorts : {},
        }

        this.updateFiltersDebounced = debounce(500, false, (filters) => {
            this.setState({ filters, page: 0 }, () => {
                this.state.network.getStdArticleList(this.state.search.data, filters, this.state.sorts, !!this.props.isEmployee)
            })
        })

        this.updateSortsDebounced = debounce(500, false, (sorts) => {
            this.setState({ sorts, page: 0 }, () => {
                this.state.network.getStdArticleList(this.state.search.data, this.state.filters, sorts, !!this.props.isEmployee)
            })
        })
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    updateFilters = (filters) => {
        this.updateFiltersDebounced(filters)
    }

    updateSorts = (sorts) => {
        this.updateSortsDebounced(sorts)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteStdArticle } = this.state
        let { organization } = this.props
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteStdArticle.fetching !== prevState.deleteStdArticle.fetching && !deleteStdArticle.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getStdArticleList(this.state.search.data, this.state.filters, this.state.sorts, !!this.props.isEmployee)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getStdArticleList(this.state.search.data, this.state.filters, this.state.sorts, !!this.props.isEmployee)
            this.setState({ page: 0 })
        }

        if (this.props.jobCode !== prevProps.jobCode) {
            const { filters } = this.state

            filters.articleJob = { value: this.props.jobCode, key: 'articleJob', operator: '=' }
            this.updateFilters(filters)
        }

        if (this.props.articleCodeFilter !== prevProps.articleCodeFilter) {
            const { filters } = this.state

            filters.articleCode = { value: this.props.articleCodeFilter, key: 'articleCode', operator: '=' }
            this.updateFilters(filters)
        }

        if(this.props.searchOrderId  !== prevProps.searchOrderId) {
            const { filters } = this.state

            filters.articleCode = { value: this.props.searchOrderId, key: 'workOrderId', operator: '=' }
            /* eslint-disable */
            console.log('updating filters', filters)
            this.updateFilters(filters)
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        if (sub) {
            return
        }

        let { sub } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if(this.props.searchOrderId) {
            const { filters } = this.state

            filters.articleCode = { value: this.props.searchOrderId, key: 'workOrderId', operator: '=' }
            this.updateFilters(filters)
        }

        if (user && !!user.manage_article) {
            extraTools.push({
                name: 'Crea nuovo articolo',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, articles, newEl, articleEdit))
                },
            })
            extraTools.push({
                name: 'Importa articoli da file',
                icon: 'check',
                action: () => {
                    this.setState({
                        showImport: true,
                    })
                },
            })
        }

        const toolbar = sub
            ? []
            : [
                  ...extraTools,
                  {
                      name: '',
                      icon: 'arrow left',
                      position: 'right',
                      action: () => {
                          this.prev()
                      },
                  },
                  {
                      name: '',
                      icon: 'arrow right',
                      position: undefined,
                      action: () => {
                          this.next()
                      },
                  },
              ]

        const changeSortValue = (obj, value) => {
            const { sorts } = this.state
            sorts[obj.name] = value
            this.updateSorts(sorts)
        }

        const resetSortValue = (obj) => {
            const { sorts } = this.state

            delete sorts[obj.name]
            this.updateSorts(sorts)
        }

        const sorts = sub
            ? {}
            : {
                  articlePrice: {
                      name: 'articlePrice',
                      label: 'Prezzo',
                      onChange: changeSortValue,
                      onReset: resetSortValue,
                  },
                  articleWeight: {
                      name: 'articleWeight',
                      label: 'Peso',
                      onChange: changeSortValue,
                      onReset: resetSortValue,
                  },
                  articleName: {
                      name: 'articleName',
                      label: 'Codice',
                      onChange: changeSortValue,
                      onReset: resetSortValue,
                  },
                  articleDescription: {
                      name: 'articleDescription',
                      label: 'Descrizione',
                      onChange: changeSortValue,
                      onReset: resetSortValue,
                  },
              }

        const filters = sub
            ? {}
            : {
                  articleName: {
                      control: Input,
                      name: 'articleName',
                      input: 'text',
                      placeholder: 'Codice',
                      label: 'Codice',
                      onChange: (e) => {
                          const { filters } = this.state

                          filters.articleName = { value: e.target.value, key: 'articleName', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articleName
                          this.updateFilters(filters)
                      },
                  },
                  articleWeight: {
                      control: Input,
                      name: 'articleWeight',
                      input: 'text',
                      placeholder: 'Peso',
                      label: 'Peso',
                      onChange: (e) => {
                          const { filters } = this.state

                          filters.articleWeight = { value: e.target.value, key: 'articleWeight', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articleWeight
                          this.updateFilters(filters)
                      },
                  },
                  articleDescription: {
                      control: Input,
                      name: 'articleDescription',
                      input: 'text',
                      placeholder: 'Descrizione',
                      label: 'Descrizione',
                      onChange: (e) => {
                          const { filters } = this.state

                          filters.articleDescription = { value: e.target.value, key: 'articleDescription', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articleDescription
                          this.updateFilters(filters)
                      },
                  },
                  articlePrice: {
                      control: Input,
                      name: 'articlePrice',
                      input: 'number',
                      step: 0.01,
                      placeholder: 'Prezzo',
                      label: 'Prezzo',
                      onChange: (e) => {
                          const { filters } = this.state

                          filters.articlePrice = { value: e.target.value, key: 'articlePrice', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articlePrice
                          this.updateFilters(filters)
                      },
                  },
                  articleJob: {
                      control: JobDropdown,
                      single: true,
                      name: 'articleJob',
                      type: 'number',
                      placeholder: 'Ordine cliente',
                      label: 'Ordine cliente',
                      onJobSelected: (ev, data, selected, molds) => {
                          const { filters } = this.state

                          filters.articleJob = { value: selected, key: 'articleJob', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articleJob
                          this.updateFilters(filters)
                      },
                  },
                  articleMold: {
                      control: MoldDropdown,
                      name: 'articleMold',
                      type: 'number',
                      placeholder: 'Stampo',
                      label: 'Stampo',
                      onlySelection: true,
                      onChange: (ev, data, selected, values) => {
                          const { filters } = this.state

                          filters.articleMold = { value: values, key: 'articleMold', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articleCustomer
                          this.updateFilters(filters)
                      },
                  },
                  customerName: {
                      control: Input,
                      input: 'text',
                      name: 'customerName',
                      label: 'Cliente',
                      onChange: (e) => {
                          const { filters } = this.state

                          filters.customerName = { value: e.target.value, key: 'customerName', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.customerName
                          this.updateFilters(filters)
                      },
                  },
                  articleMaterials: {
                      control: MaterialDropdown,
                      single: true,
                      name: 'articleMaterials',
                      type: 'number',
                      placeholder: 'Materiali',
                      label: 'Materiali',
                      onlySelection: true,
                      onChange: (ev, data, selected, values) => {
                          const { filters } = this.state

                          filters.articleMaterials = { value: values, key: 'articleMaterials', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articleMaterials
                          this.updateFilters(filters)
                      },
                  },
                  articleMasters: {
                      control: MasterDropdown,
                      single: true,
                      name: 'articleMasters',
                      type: 'number',
                      placeholder: 'Master',
                      label: 'Master',
                      onlySelection: true,
                      onChange: (ev, data, selected, values) => {
                          const { filters } = this.state

                          filters.articleMasters = { value: values, key: 'articleMasters', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articleMasters
                          this.updateFilters(filters)
                      },
                  },
                  articleKits: {
                      control: KitDropdown,
                      single: true,
                      name: 'articleKits',
                      type: 'number',
                      placeholder: 'Kit',
                      label: 'Kit',
                      onlySelection: true,
                      onChange: (ev, data, selected, values) => {
                          const { filters } = this.state

                          filters.articleKits = { value: values, key: 'articleKits', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articleKits
                          this.updateFilters(filters)
                      },
                  },
                  articlePackages: {
                      control: PackageDropdown,
                      single: true,
                      name: 'articlePackages',
                      type: 'number',
                      placeholder: 'Pacchetti',
                      label: 'Pacchetti',
                      onlySelection: true,
                      onChange: (ev, data, selected, values) => {
                          const { filters } = this.state

                          filters.articlePackages = { value: values, key: 'articlePackages', operator: '=' }
                          this.updateFilters(filters)
                      },
                      onReset: () => {
                          const { filters } = this.state

                          delete filters.articlePackages
                          this.updateFilters(filters)
                      },
                  },
              }

        initializePage(this, toolbar, filters, sorts)

        this.state.network.getStdArticleList(this.state.search.data, this.state.filters, this.state.sorts, !!this.props.isEmployee)
    }

    renderArticle(key, article, style) {
        if (!article) return null

        let { selected } = this.state
        let { onChange, selectable, noActions } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (article) => {
                if (onChange) {
                    onChange(article)
                }
                this.setState({ selected: article })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return (
            <div style={style}>
                <Article {...extraProps} selected={article.articleId === selected.articleId} type="full" key={`article_${key}`} article={article} />
            </div>
        )
    }

    render() {
        let { articles, fetching, page, items } = this.state
        let { noPagination, onChange, selectable, selected, noActions, sub, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (articles && articles.data && Object.keys(articles.data).length > 0) {
            content = []
            const keyArray = Object.keys(articles.data)
            if (!noPagination) {
                for (let i = page * items; i < Math.min(page * items + items, keyArray.length); i++) {
                    content.push(this.renderArticle(i, articles.data[keyArray[i]]))
                }
            } else {
                content = (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List height={height} itemCount={articles.data.length} itemSize={200} width={width}>
                                {({ index, style }) => this.renderArticle(index, articles.data[index], style)}
                            </List>
                        )}
                    </AutoSizer>
                )
            }
        } else {
            content = <Empty />
        }

        return (
            <>
                {!sub && (
                    <Modal onClose={this.closeModal} open={this.state.showImport}>
                        <Modal.Header>Importazione articoli</Modal.Header>
                        <Modal.Content image scrolling style={{ padding: 0 }}>
                            <Box p={6} style={{ width: '100%' }}>
                                <PickFile
                                    // type="archive"
                                    buttonText="Scegli file da importare"
                                    onChange={(fileToImport) => {
                                        this.setState({ fileToImport: fileToImport })
                                    }}
                                    value={this.state.fileToImport}
                                />
                            </Box>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                color="grey"
                                onClick={() => {
                                    this.setState({ showImport: false })
                                }}
                            >
                                Annulla <Icon name="cancel" />
                            </Button>
                            <Button
                                primary
                                onClick={() => {
                                    this.props.network.createArticleImport(this.state.fileToImport).then((result) => {
                                        this.props.success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                                    })
                                    this.setState({ showImport: false })
                                }}
                            >
                                Esegui <Icon name="check" />
                            </Button>
                        </Modal.Actions>
                    </Modal>
                )}
                <View noflex fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                    {content}
                </View>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    let { getStdArticleList, deleteStdArticle, search, organization, login } = state

    let articleList = getStdArticleList
    return {
        articles: articleList.data,
        fetching: articleList.fetching,
        deleteStdArticle,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Articles)))
