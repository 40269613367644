export function getCustomerList(data) {
    return new Promise((resolve, reject) => {
		let { getCustomerListRequest, getCustomerListError, getCustomerListResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_customer_list',
			this.createBundle(
				'get_customer_list',
				{ customer: data },
				this.GET,
				getCustomerListRequest,
                (result) => {
                    getCustomerListResult(result)
                    resolve(result)
                },
                (error) => {
                    getCustomerListError(error)
                    reject(error)
                }
			)
		);
	})
}
