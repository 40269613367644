import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { timeString } from 'lib/util'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Popup, Table } from 'semantic-ui-react'
class Work extends Component {
    constructor(props) {
        super(props)
        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
        }
    }

    renderTableHeader() {
        let { hideDate, hideDescription, hideWorker, hideWorkerHours, hideMachine, hideMachineHours, hideJob } = this.props

        return (
            <Table.Header fullWidth>
                <Table.Row>
                    {!hideDate && <Table.HeaderCell>Data lavoro</Table.HeaderCell>}
                    {!hideJob && <Table.HeaderCell>Commissione</Table.HeaderCell>}
                    {!hideDescription && <Table.HeaderCell>Descrizione</Table.HeaderCell>}
                    {!hideWorker && <Table.HeaderCell>Impiegato</Table.HeaderCell>}
                    {!hideWorkerHours && <Table.HeaderCell>Ore Impiegato</Table.HeaderCell>}
                    {!hideMachine && <Table.HeaderCell>Macchina</Table.HeaderCell>}
                    {!hideMachineHours && <Table.HeaderCell>Ore Macchina</Table.HeaderCell>}
                </Table.Row>
            </Table.Header>
        )
    }

    renderForTable() {
        let { hideDate, hideJob, hideDescription, hideWorker, hideWorkerHours, hideMachine, hideMachineHours, work } = this.props

        return (
            <Table.Row
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick(this.props.work)
                    }
                }}
                className={this.props.selected ? 'rowSelected' : ''}
            >
                {!hideDate && <Table.Cell>{moment(work.workDate).format('ll')}</Table.Cell>}
                {!hideJob && <Table.Cell>{work.jobCode}</Table.Cell>}
                {!hideDescription && <Table.Cell>{work.workDescription ? work.workDescription : work.description}</Table.Cell>}
                {!hideWorker && <Table.Cell>{work.workerName}</Table.Cell>}
                {!hideWorkerHours && <Table.Cell>{timeString(work.workerHours, work.workerMinutes)}</Table.Cell>}
                {!hideMachine && <Table.Cell>{work.machineName}</Table.Cell>}
                {!hideMachineHours && <Table.Cell>{timeString(work.machineHours, work.machineMinutes)}</Table.Cell>}
            </Table.Row>
        )
    }

    renderCompact() {
        let { work } = this.props

        return (
            <View row style={{ width: '50%', alignSelf: 'center', textAlign: 'left' }}>
                <Popup
                    trigger={
                        <div style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            <b style={{ whiteSpace: 'nowrap' }}>{work.jobCode.length > 5 ? work.jobCode.substring(0, 5) : work.jobCode}</b> -{' '}
                            <b>
                                {work.workerHours}
                                {work.workerMinutes > 0 ? `:${work.workerMinutes}` : ''}
                            </b>{' '}
                            |{' '}
                            <b>
                                {work.machineHours}
                                {work.machineMinutes > 0 ? `:${work.machineMinutes}` : ''}
                            </b>
                        </div>
                    }
                >
                    <Popup.Header>{work.jobCode}</Popup.Header>
                    <Popup.Content>
                        <div>{work.workDescription ? work.workDescription : work.description}</div>
                        <div>Ore operaio: {timeString(work.workerHours, work.workerMinutes)}</div>
                        <div>Ore macchina: {timeString(work.machineHours, work.machineMinutes)}</div>
                    </Popup.Content>
                </Popup>
            </View>
        )
    }

    render() {
        let { type, work } = this.props
        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        switch (type) {
            case 'compact':
                return this.renderCompact()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Box style={styles.jobCard} {...extraprops} borderWidth="1px" borderRadius="lg"
                    p="4" >
                            <Box textStyle="content" style={styles.jobContent}>
                                <View fullw row>
                                    <View fullw column>
                                        <span style={styles.header}>{work.workDescription ? work.workDescription : work.description}</span>
                                        <Box textStyle="description">{work.jobCode}</Box>
                                        <Box textStyle="meta" style={{ textTransform: 'capitalize' }}>{moment(work.creationDate).format('LLLL')}</Box>
                                    </View>
                                </View>
                            </Box>
                            <Box textStyle="content" extra>
                                <Icon name="cogs" />
                                Dettagli
                            </Box>
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        height: 128,
        width: 128,
        marginRight: 28,
    },
    jobImage: {
        height: 128,
        width: 128,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = () => {
    return {}
}
export default connect(mapStateToProps)(connectNetwork(Work))
