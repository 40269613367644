export function createLot(data, isEmployee) {
    return new Promise((resolve, reject) => {
		let { createLotRequest, createLotError, createLotResult } = this.props;

        delete data.creationDate;
        delete data.updateDate;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_lot',
			this.createBundle(
				'create_lot',
				{ lot: data },
				this.POST,
				createLotRequest,
                (result) => {
                    createLotResult(result)
                    resolve(result)
                },
                (error) => {
                    createLotError(error)
                    reject(error)
                },
                isEmployee
			)
		);
	})
}
