import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import L from 'leaflet'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import markerIconPng from '../assets/marker-icon.png'
import { Confirmation, ImageDefault } from '../components'
import { floatTimeToString, timeString } from '../lib/util'

const { app, offices, officeEdit } = routes

export const markerIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconRetinaUrl: markerIconPng,
    iconAnchor: [20, 64],
    popupAnchor: [10, -44],
    iconSize: [40, 64],
})

class Office extends Component {
    constructor(props) {
        super(props)

        this.componentContainerRef = null

        this.setComponentContainerRef = (element) => {
            this.componentContainerRef = element
        }

        let { onCostChange, office } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...office,
            zoom: 13,
        }

        if (onCostChange && office) {
            onCostChange(this.totalCost(), office.id)
        }
    }

    totalCost() {
        let { officeHourlyRate, officeHours, officeMinutes } = this.state
        let total = (officeHours + officeMinutes / 60) * parseFloat(officeHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { office, id, network } = this.props

        if ((!office || !office.id) && id) {
            network.getOffice(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getOffice, login } = this.props
        let { id, officeHourlyRate } = this.state
        if (officeHourlyRate !== prevState.officeHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), id)
            }
        }

        // console.log('Office getOffice fetching', getOffice.fetching)
        if (getOffice && getOffice.fetching !== prevProps.getOffice.fetching && !getOffice.fetching) {
            // console.log('Done calling getOffice')
            if (getOffice.status.success) {
                // console.log(getOffice.data)
                let office = getOffice.data.data

                this.setState({ ...office })
                // console.log('Calling Get office', login, office)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No office found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { name, officeHourlyRate, officeHours, officeMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={officeHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    officeHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{officeHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(officeHours, officeMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideOffice, hideOfficeHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideOffice && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideOfficeHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideOffice, hideOfficeHours, isBalance } = this.props
        let { name, officeHours, officeMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideOffice && <Table.Cell>{name}</Table.Cell>}
                    {!hideOfficeHours && <Table.Cell>{timeString(officeHours, officeMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, office } = this.props

        network.deleteOffice(office)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, office, onClick, selected, noActions, traversable } = this.props
        let { deleting, officeId: id, officeName, officeDescription, officeImages, officeLatitude, officeLongitude } = this.state
        const position = [officeLatitude, officeLongitude]

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(office)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            case 'compact':
                return (
                    <div ref={this.setComponentContainerRef}>
                        <Box style={{ height: 200, width: 200 }}>
                            <Box textStyle="header">Office compact {officeName}</Box>
                        </Box>
                    </div>
                )
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.officeCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questa sede?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.officeContent}>
                                <View fullw row>
                                    {officeImages.length > 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={officeImages[0].picturePath} ui={false} style={styles.officeImage} />
                                        </div>
                                    )}
                                    <View fullw column>
                                        <span style={styles.header}>{officeName}</span>
                                        <Box textStyle="description">{officeDescription}</Box>
                                    </View>
                                    <View style={{ width: 200, height: 200 }}>
                                        <Map style={{ height: 200, width: 200 }} center={position} zoom={this.state.zoom}>
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                            <Marker position={position} icon={markerIcon}>
                                                <Popup>
                                                    A pretty CSS3 popup. <br /> Easily customizable.
                                                </Popup>
                                            </Marker>
                                        </Map>
                                    </View>
                                </View>
                            </Box>
                            {!noActions && (
                                <Box textStyle="content" extra>
                                    {user && !!user.manage_office && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, offices, officeEdit, `${id}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            Modifica
                                        </Button>
                                    )}

                                    {user && !!user.manage_office && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            Elimina
                                        </Button>
                                    )}
                                </Box>
                            )}
                            {traversable && (
                                <Box textStyle="content" extra>
                                    <Button
                                        color="yellow"
                                        onClick={() => {
                                            this.props.onTraverse(office)
                                        }}
                                    >
                                        <Icon name="folder" />
                                        Apri
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    officeImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    officeContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    officeCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getOffice } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getOffice, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Office)))
