import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Machine } from '../components'
import { baseViewDispatch } from '../lib/util'
function WidgetContainer() {
    return (
        <View fullw fullh column>
            <Machine
                machine={{
                    machineName: 'Negribossi 220T',
                }}
                type={'full'}
                table={'negribossi1'}
                negribossi
            />
            <Machine
                machine={{
                    machineName: 'Negribossi 130T',
                }}
                type={'full'}
                table={'negribossi2'}
                negribossi
            />
            <Machine
                machine={{
                    machineName: 'Wittmann',
                }}
                type={'full'}
                table={'wittman'}
                wittmann
            />
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WidgetContainer)))
