import { Box } from '@chakra-ui/layout'
import {} from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import SummarySheet from '../job/SummarySheet'
import { mapOrdToOrdering } from '../lib/util'

const { app, recipes, recipeEdit } = routes

class Recipe extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, recipe } = props

        this.state = {
            deleting: false,
            ...recipe,
            recipeMinutes: recipe ? parseFloat(recipe.recipeMinutes) : 0,
        }

        if (onCostChange && recipe) {
            onCostChange(this.totalCost(), recipe.recipeId)
        }
    }

    totalCost() {
        let { recipePrice } = this.state
        let total = recipePrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { recipe, recipeId, network } = this.props

        if ((!recipe || !recipe.recipeId) && recipeId) {
            network.getRecipe(recipeId)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let {
            onRecipeCodeHeaderClick,
            hideRecipe,
            hideRecipeName,
            isBalance,
            hideActions,
            hideBarcode,
            onRowClick,
            sorts,
        } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideRecipe && (
                            <Table.HeaderCell
                                {...(sorts ? { sorted: sorts.recipeCode ? mapOrdToOrdering[sorts.recipeCode] : null } : {})}
                                onClick={onRecipeCodeHeaderClick ? onRecipeCodeHeaderClick : () => {}}
                            >
                                Ricetta
                            </Table.HeaderCell>
                        )}
                        {!hideRecipeName && <Table.HeaderCell>Nome</Table.HeaderCell>}
                        {!hideRecipeName && <Table.HeaderCell>Corrispondenza ricetta Macchinario</Table.HeaderCell>}
                        {!hideBarcode && <Table.HeaderCell>Codice a barre</Table.HeaderCell>}
                        {!hideActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideRecipe, hideRecipeName, hideRecipeUnitPrice, hideRecipeMeasurement, hideBarcode, isBalance, onRowClick, hideActions } = this.props
        let { recipeId, recipeCode, recipeName, recipeIndex } = this.state

        setImmediate(() => {
            try {
                const text = recipeCode
                JsBarcode(`#matbarcode${recipeCode}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } catch (e) {
                console.error(e)
            }
        })

        let scanned = this.props.scannedRecipes ? this.props.scannedRecipes.includes(recipeCode) : false

        // console.log('Ricetta is', scanned)
        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row style={{ backgroundColor: scanned ? 'green' : 'white' }}>
                    {!hideRecipe && <Table.Cell>{recipeCode}</Table.Cell>}
                    {!hideRecipeName && <Table.Cell>{recipeName}</Table.Cell>}
                    {!hideRecipeName && <Table.Cell>{recipeIndex}</Table.Cell>}
                    {!hideBarcode && (
                        <Table.Cell>
                            <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                <svg id={`matbarcode${recipeCode}`}></svg>
                            </div>
                        </Table.Cell>
                    )}
                    {!hideActions && this.state.recipeId && (
                        <Table.Cell className="no-print">
                            {/* <SummarySheet type="recipe" objectToSerialize={this.state} />
                            <Button
                                icon
                                color="yellow"
                                onClick={(e) => {
                                    this.setState({
                                        mergingRecipe: true,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="exchange" />
                            </Button> */}
                            <Button
                                icon
                                color="blue"
                                onClick={(e) => {
                                    if (onRowClick) {
                                        onRowClick(this.state)
                                    }
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="edit" />
                            </Button>
                            <Button
                                icon
                                color="red"
                                onClick={(e) => {
                                    this.props.network.deleteRecipe({
                                        recipeId,
                                        recipeCode,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="remove" />
                            </Button>
                            {/* <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon color="grey">
                                            <Icon name="print" />
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            /> */}
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, recipe } = this.props

        network.deleteStdRecipe(recipe)

        this.setState({ deleting: false })
    }

    render() {
        let { recipeId } = this.state
        let { login, type, recipe, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(recipe)
            }
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = (
                    <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        p="4"
                        overflow="hidden"
                        className={selected ? 'rowSelected' : ''}
                        style={styles.recipeCard}
                        {...extraprops}
                    />
                )
                break
        }

        return <>{content}</>
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    recipeImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    recipeContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    recipeCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getRecipe } = state
    let rrecipeId = null
    if (login && login.data && login.data.user) rrecipeId = login.data.user.rrecipeId

    return { role: rrecipeId, getRecipe, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Recipe)))
