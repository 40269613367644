import moment from 'momentconfig'
import React, { Component } from 'react'
import { DragSource } from 'react-dnd'

import '../App.css'
import { ImageDefault } from '../components'

class DraggableImage extends Component {
    replaceText = (string) => {
        const { arti, pack, customer, worker, currentSession } = this.props

        return string
            .replace('{{deliveryDate}}', arti ? moment(arti.creationDate).format('DD/MM/YY') : '')
            .replace('{{currentDate}}', moment().format('DD/MM/YY'))
            .replace('{{pcsPerPackage}}', currentSession ? currentSession.sessionTargetPackageCount + ' PZ' : '')
            .replace('{{customerAddress}}', arti && arti.customerDescription ? arti.customerDescription : '')
            .replace('{{customerAddress1}}', arti && arti.customerDescription ? arti.customerDescription : '')
            .replace('{{customerAddress2}}', arti && arti.customerDescription ? arti.customerDescription : '')
            .replace('{{customerName}}', customer && customer.customerName ? customer.customerName : '')
            .replace('{{clientArticleCode}}', arti && arti.clientWorkCode ? arti.clientWorkCode : '')
            .replace('{{articleCode}}', arti && arti.workCode ? arti.workCode : '')
            .replace('{{articleName}}', arti && arti.description ? arti.description : '')
            .replace(
                '{{articleQuantity}}',
                currentSession && currentSession.sessionTargetPackageCount ? currentSession.sessionTargetPackageCount : ''
            )
            .replace('{{workerName}}', worker && worker.name ? worker.name : '')
            .replace('{{lotto}}', arti && arti.lotto ? arti.lotto : '')
            .replace('{{warehouseSequential}}', arti.warehouseSequential)
            .replace('{{warehouseQuantity}}', arti.articleQuantity)
            .replace('{{warehousePaddedQuantity}}', arti.paddedArticleQuantity)
            .replace('{{warehousePcsPerPackage}}', arti.articleQuantity + ' PZ')
            .replace('{{warehouseKgs}}', `KG. ${arti.articleQuantity}`)
            .replace('{{seq}}', arti.seq)
            .replace('{{computedImage}}', arti.computedImage)
    }

    render() {
        let { isPreview, key, connectDragSource, value, layoutElementStyle, padding } = this.props
        let paddingToApply = padding ? padding : 0

        return connectDragSource(
            <div
                key={key}
                style={
                    !isPreview
                        ? {
                              left: value.layoutElementX + paddingToApply,
                              top: value.layoutElementY + paddingToApply,
                              position: 'absolute',
                              ...layoutElementStyle,
                          }
                        : { ...layoutElementStyle }
                }
            >
                <ImageDefault
                    fromRoot
                    src={this.replaceText(value.layoutElementValue)}
                    ui={false}
                    style={{ ...layoutElementStyle, transform: null }}
                />
            </div>
        )
    }
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
    }
}

const spec = {
    beginDrag(props, monitor, component) {
        const item = { component, data: props.value, style: props.layoutElementStyle }
        return item
    },
}

export default DragSource('any', spec, collect)(DraggableImage)
