/* eslint react/no-direct-mutation-state: "off" */
import { Box } from '@chakra-ui/layout'
import { Items } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Area, AreaChart, CartesianGrid, Legend, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Button, Checkbox, Form, Icon, Input, Modal, Placeholder, Select, Table, Transition } from 'semantic-ui-react'
import { Confirmation, Cost, CustomTable, DataPoint, DocumentRecap, GT2000Data, ImageDefault, Machine, Work, Worker } from '../components'
import { CostEditor } from '../containers'
import BabypressaData from '../extra/BabypressaData'
import { computeGaps, computeHadesData, Default, floatTimeToString, Mobile, timeObject } from '../lib/util'

const moment = require('moment-timezone')

const { app, clientOrders, finalBalance, clientOrderEdit, costEdit, tempClientOrders, clientOrderMerge } = routes

const COLORS = { quantity: '#00B75B', qOptimal: '#EAFAF1', scrap: '#FFD930', sMax: '#FEF9E7' }

const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    99: 'Termine Lavori',
}

const REASON_COLORS = ['#FDF2E9', '#E9F7EF', '#F9EBEA']
let max = 0

class ClientOrder extends Component {
    constructor(props) {
        super(props)
        this.state = this.compute()
        this.state.costDict = {}

        let { showDetails, forcedOpen } = this.props
        if (showDetails !== undefined) {
            this.state.showDetails = showDetails
        }

        if (forcedOpen) {
            this.state.showDetails = true
        }
        this.state.budgeted = 0
        this.state.billed = 0
        if (this.state.clientOrder) {
            this.state.dataUltimaFatturazione = this.state.clientOrder.dataUltimaFatturazione
        }
        this.state.budgetedModal = false
        this.state.billedModal = false
        this.state.dateModal = false
        this.state.editMapping = false
        this.state.showTaskModal = false
        this.state.showDeliveryDateModal = false

        this.state.chartData = []
        this.state.chartDataReference = []
        this.state.chartWorkOrder = '0'

        this.state.newmaprow = null
        this.state.newmapcolumn = null
        this.state.deletingMapping = false
    }

    compute(forcedClientOrder, forcedWorks) {
        let { fixedClientOrder } = this.props
        if (fixedClientOrder) forcedClientOrder = fixedClientOrder

        let { works: fromProps } = this.props
        if (!fromProps || !fromProps.slice) fromProps = []
        let works = fromProps.slice(0)
        if (forcedWorks) works = forcedWorks.slice(0)

        /*
		//console.log('Computing with this work list');
		console.dir(works);
		console.dir(forcedWorks);
		console.dir(fromProps);
		*/
        let workers = []
        let workerobj = {}
        let machines = []
        let machineobj = {}
        let totalWorkerTime = [0, 0]
        let totalMachineTime = [0, 0]

        let workdict = {}
        let costdict = {}
        let costs = []
        let notWorks = [] // Cost entries don't have a work attached to them

        let { costs: costsFromProps } = this.props
        if (costsFromProps) {
            costs = costsFromProps
            for (let i in costs) {
                costs[i].costPrice = parseFloat(costs[i].costPrice)
            }
        }

        //console.log('ClientOrder costs from props:', costsFromProps);
        for (let i in works) {
            let work = works[i]
            let {
                clientOrderCode,
                workerId,
                machineId,
                workerName,
                machineName,
                workerHours,
                machineHours,
                workerMinutes,
                machineMinutes,
                workerHourlyRate,
                machineHourlyRate,
                costId,
                costDate,
                costDescription,
                producer,
                costPrice,
                registrationDate,
            } = work

            if (!costsFromProps && costId !== null && costId && !costdict[costId]) {
                costdict[costId] = {
                    costId,
                    costDate,
                    clientOrderCode,
                    costDescription,
                    producer,
                    costPrice,
                }
                costs.push(costdict[costId])
            }

            if (!workdict[`${clientOrderCode}_${workerId}_${registrationDate}`]) {
                workdict[`${clientOrderCode}_${workerId}_${registrationDate}`] = true
            } else {
                notWorks.push(parseInt(i))
                continue
            }

            workerHours = parseFloat(workerHours)
            workerMinutes = parseFloat(workerMinutes)
            machineHours = parseFloat(machineHours)
            machineMinutes = parseFloat(machineMinutes)
            totalWorkerTime[0] += workerHours
            totalWorkerTime[1] += workerMinutes
            totalMachineTime[0] += machineHours
            totalMachineTime[1] += machineMinutes

            if (workerId !== null) {
                if (!workerobj[workerId]) {
                    workerobj[workerId] = []
                }

                workerobj[workerId].push({
                    workerId,
                    workerName,
                    workerHours,
                    workerMinutes,
                    workerHourlyRate,
                })
            }

            if (machineId !== null) {
                if (!machineobj[machineId]) {
                    machineobj[machineId] = []
                }

                machineobj[machineId].push({
                    machineId,
                    machineName,
                    machineHours,
                    machineMinutes,
                    machineHourlyRate,
                })
            }
        }

        for (let i = notWorks.length - 1; i >= 0; i--) {
            let toRemove = notWorks[i]
            works.splice(toRemove, 1)
        }

        for (let i in workerobj) {
            let worklist = workerobj[i]
            let { workerId, workerName } = worklist[0]
            let workerHours = 0
            let workerMinutes = 0
            let workerHourlyRate = 0

            for (let j in worklist) {
                let work = worklist[j]
                workerHours += work.workerHours
                workerMinutes += work.workerMinutes
                workerHourlyRate = parseFloat(work.workerHourlyRate)
            }

            workerHours += Math.floor(workerMinutes / 60)
            workerMinutes = workerMinutes % 60

            workers.push({
                workerId,
                workerName,
                workerHours,
                workerMinutes,
                workerHourlyRate,
            })
        }

        for (let i in machineobj) {
            let worklist = machineobj[i]
            let { machineId, machineName } = worklist[0]
            let machineHours = 0
            let machineMinutes = 0
            let machineHourlyRate = 0

            for (let j in worklist) {
                let work = worklist[j]
                machineHours += work.machineHours
                machineMinutes += work.machineMinutes
                machineHourlyRate = parseFloat(work.machineHourlyRate)
            }

            machineHours += Math.floor(machineMinutes / 60)
            machineMinutes = machineMinutes % 60

            machines.push({
                machineId,
                machineName,
                machineHours,
                machineMinutes,
                machineHourlyRate,
            })
        }

        let colors = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black']
        let color = this.props.color
        if (!color) {
            color = colors[parseInt(Math.random() * colors.length)]
        }

        let { clientOrder, isBalance } = this.props

        totalWorkerTime[0] += Math.floor(totalWorkerTime[1] / 60)
        totalWorkerTime[1] = totalWorkerTime[1] % 60
        totalMachineTime[0] += Math.floor(totalMachineTime[1] / 60)
        totalMachineTime[1] = totalMachineTime[1] % 60

        //console.log("Final works list", works);
        //console.log('ClientOrder forcedClientOrder is', forcedClientOrder)

        return {
            wait: true,
            exportModal: false,
            costs,
            workers,
            works,
            machines,
            totalWorkerTime,
            totalMachineTime,
            color,
            showDetails: isBalance ? true : false,
            deleting: false,
            clientOrder: forcedClientOrder ? forcedClientOrder : clientOrder,
            total: 0,
            external: clientOrder ? clientOrder.createdExternal === 1 : false,
            totalWithFixedCosts: true,
            totalWithItems: true,
        }
    }

    componentDidMount() {
        let { noRequests, clientOrder, clientOrderId, network, mappingData, isEmployee } = this.props

        if (!noRequests) {
            if ((!clientOrder || !clientOrder.clientOrderCode) && clientOrderId) {
                network.getClientOrder(clientOrderId)
            }
            // network.getWorkerList()
            // network.getMachineList('')
        }

        // if (!mappingData.data) {
        //     network.getMappingData(isEmployee)
        // }
    }

    componentWillUnmount() {}

    UNSAFE_componentWillReceiveProps(nextProps) {}

    renderControls() {
        let { grouped, chartWorkOrder } = this.state

        let options = []
        for (let i in grouped) {
            options.push({
                key: i,
                value: i,
                text: i,
            })
        }

        if (options.length === 0) {
            return null
        }

        return (
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-clientOrder-type"
                        control={Select}
                        options={options}
                        label={<span className="label">Ordine di lavoro</span>}
                        placeholder="Ordine di lavoro"
                        name="chartWorkOrder"
                        value={chartWorkOrder}
                        onChange={(e, data) => {
                            let id = data.value
                            this.setState({ chartWorkOrder: id })
                        }}
                    />
                </Form.Group>
            </Form>
        )
    }

    renderCharts() {
        let { chartData, chartDataReference } = this.state

        if (chartData.length === 0) {
            return null
        }

        return (
            <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                    data={chartData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    onMouseOver={() => {
                        this.setState({ stopChart: true })
                    }}
                    onMouseLeave={() => {
                        this.setState({ stopChart: false })
                    }}
                >
                    <defs>
                        <linearGradient id="colorCorretti" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.quantity} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.quantity} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorScarti" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.scrap} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.scrap} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        scale="time"
                        domain={['dataMin', 'dataMax']}
                        type="number"
                        dataKey="millis"
                        tickFormatter={(value) => {
                            return moment.unix(value).format('DD/MM HH:mm:ss')
                        }}
                    />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Legend verticalAlign="top" height={36} />
                    <Tooltip
                        labelFormatter={(value) => {
                            return moment.unix(value).format('DD/MM HH:mm:ss')
                        }}
                    />

                    {chartDataReference.map((value, index) => {
                        return (
                            <ReferenceArea
                                key={index}
                                ifOverflow="extendDomain"
                                // alwaysShow={true}
                                label={value.label}
                                x1={value.x1}
                                x2={value.x2}
                                y1={value.y1}
                                y2={value.y2}
                                fill={value.fillColor}
                                fillOpacity={value.opacity}
                            />
                        )
                    })}
                    <Area type="monotone" dataKey="corretti" stroke={COLORS.quantity} fillOpacity={1} fill="url(#colorCorretti)" />
                    <Area type="monotone" dataKey="scarti" stroke={COLORS.scrap} fillOpacity={1} fill="url(#colorScarti)" />
                    {/* <ReferenceLine y={350} label='Ottimale{'\''} stroke='red' strokeDasharray='3 3' /> */}
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            getClientOrder,
            costs,
            showDetails,
            clientOrderId,
            works: worksFromProps,
            network,
            getFromId,
            login,
            organization,
            fetchingMap,
            mappingData,
            deleteCost,
        } = this.props

        if (!deleteCost.fetching && prevProps.deleteCost.fetching && deleteCost.request_data.cost.costId) {
            let { costs } = this.state
            let toRemove = null
            for (const index in costs) {
                const cost = costs[index]
                if (cost.costId === deleteCost.request_data.cost.costId) {
                    toRemove = index
                    break
                }
            }

            if (toRemove !== null) {
                costs.splice(toRemove, 1)
                this.setState({ costs })
            }
        }

        let { chartWorkOrder } = this.state

        // TODO reload (call getclientOrder) when company changes, and redirect to home is the clientOrder doesn't exist in the new company

        if (getClientOrder && getClientOrder.fetching !== prevProps.getClientOrder.fetching && !getClientOrder.fetching) {
            if (getClientOrder.status.success && getClientOrder.data[clientOrderId]) {
                //console.log(getClientOrder.data);
                let clientOrder = getClientOrder.data[clientOrderId][0]
                let works = getClientOrder.data[clientOrderId]
                if (worksFromProps) {
                    works = worksFromProps
                }
                let newState = this.compute(clientOrder, works)
                this.setState({ clientOrder, works, ...newState })

                //console.log('Got clientOrder, now checking if in need of hades request', login && login.data.user && login.data.user.companies, login)
                if (login && login.data.user && login.data.user.companies) {
                    let company = login.data.user.companies[organization.data[0]]
                    //console.log('Getting from id both filter and not')
                    network.getFromIdFiltering(company, clientOrderId, [
                        {
                            key: 'timestamp',
                            symbol: '>',
                            value: moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                        },
                    ])
                    network.getFromId(company, clientOrderId)
                }
                return
            }
        }

        //console.log('ClientOrder Did update clientOrder:', prevProps.clientOrder, this.props.clientOrder, clientOrderId)
        if (!clientOrderId && prevProps.clientOrder && prevProps.clientOrder.clientOrderCode !== this.props.clientOrder.clientOrderCode) {
            this.setState({
                clientOrder: this.props.clientOrder,
            })
        }

        if (getFromId && getFromId.fetching !== prevProps.getFromId.fetching && !getFromId.fetching) {
            this.setState(computeHadesData(this.props))
        }

        if (
            (getFromId && getFromId.fetching !== prevProps.getFromId.fetching && !getFromId.fetching) ||
            chartWorkOrder !== prevState.chartWorkOrder
        ) {
            if (getFromId.status.success && getFromId.data) {
                //console.log('getFromId data is:', getFromId)

                let {
                    // getFromId,
                    getFromId: {
                        request_data: { id: requestedClientOrder },
                        data: { data: hadesData },
                    },
                } = this.props

                if (!this.state.clientOrder || this.state.clientOrder.clientOrderCode !== requestedClientOrder) {
                    return null
                }

                //console.log('Data from filtering is', hadesData)

                let fixedData = []
                if (hadesData) {
                    let condensed = []
                    for (let i = 0; i < hadesData.length; i++) {
                        if (i % 1 === 0) {
                            // TODO choose resolution
                            hadesData[i].millis = new Date(hadesData[i].timestamp).getTime() / 1000
                            condensed.push(hadesData[i])
                        }
                    }
                    fixedData = condensed
                }

                //console.log('fixedData is: ', fixedData)

                let _data = []
                if (this.state.buffer) {
                    for (let i in this.state.buffer) {
                        this.state.buffer[i].millis = new Date(this.state.buffer[i].timestamp).getTime() / 1000
                    }
                    _data = [...fixedData, ...this.state.buffer]
                } else {
                    _data = [...fixedData]
                }

                let { chartDataReference, chartData } = this.state

                if (_data.length === 0) return null

                chartDataReference = []
                chartData = []
                _data.sort((a, b) => {
                    return a.millis - b.millis
                })

                //console.log('fixedData is: ', _data)

                for (let d of _data) {
                    let { timestamp, millis, quantity, scrap, reason, id, sub_id, terminal } = d

                    if (quantity < 0) quantity = 0
                    if (scrap < 0) scrap = 0

                    if (sub_id !== chartWorkOrder) {
                        //console.log('Skipping data of different work order (', sub_id, ')')
                        // Skip data not from this work order
                        continue
                    }

                    if (chartData.some((x) => x.timestamp === timestamp)) {
                        //console.log('already in', timestamp);
                        // if alreasy has that timestamp
                        continue
                    }

                    let maxQty = Math.max(quantity, scrap)
                    if (maxQty > max) max = maxQty

                    if (chartDataReference.length !== 0) {
                        let last = chartDataReference.splice(-1)[0]
                        if (last.id === reason) {
                            last.x2 = millis
                        } else {
                            last.x2 = millis
                            chartDataReference.push({ ...last })
                            last.id = reason
                            last.x1 = millis
                            last.x2 = millis
                            last.label = REASON_LABELS[reason]
                            last.fillColor = REASON_COLORS[reason]
                        }
                        chartDataReference.push(last)
                    } else {
                        let current = {
                            id: reason,
                            label: REASON_LABELS[reason],
                            x1: millis,
                            x2: millis,
                            y1: 0,
                            fillColor: REASON_COLORS[reason],
                            opacity: '0.5',
                        }
                        chartDataReference.push(current)
                    }
                    for (let item of chartDataReference) {
                        item.y2 = max + 50
                    }
                    chartData.push({
                        timestamp,
                        millis,
                        corretti: quantity,
                        scarti: scrap,
                        clientOrder: id,
                        lavoro: sub_id,
                        macchina: terminal,
                    })
                }
                let { quantity, scrap } = _data.pop()
                let radialQuantity = [
                    {
                        name: 'Ottimale',
                        value: 1000,
                        fill: COLORS.qOptimal,
                    },
                    {
                        name: 'quatita',
                        value: quantity,
                        fill: COLORS.quantity,
                    },
                ]
                let radialScrap = [
                    {
                        name: 'Massimo',
                        value: 500,
                        fill: COLORS.sMax,
                    },
                    {
                        name: 'scarti',
                        value: scrap,
                        fill: COLORS.scrap,
                    },
                ]

                return this.setState({
                    chartData: [...chartData],
                    chartDataReference: [...chartDataReference],
                    buffer: [],
                    radialQuantity,
                    radialScrap,
                })
            }
        }

        // let newState = this.compute(clientOrder, works)
        // this.setState({ clientOrder, works, ...newState })
        // return

        if (costs !== prevProps.costs) {
            this.setState(this.compute(this.state.clientOrder, this.state.works))
            return
        }

        if (!!showDetails !== !!prevProps.showDetails) {
            this.setState({ showDetails: !!showDetails })
        }
    }

    renderCompact() {
        let { onClick, selected } = this.props
        let { color, clientOrder } = this.state

        let extraProps = {
            onClick: onClick
                ? () => {
                      onClick(clientOrder)
                  }
                : null,
        }

        return (
            <Box className={selected ? 'rowSelected' : ''} {...extraProps} style={compact_styles.clientOrderCard} color={color}>
                <Box textStyle="content" style={compact_styles.clientOrderContent}>
                    <View fullw column>
                        <div style={compact_styles.imageContainer}>
                            <ImageDefault fromRoot src={clientOrder.image} ui={false} style={compact_styles.clientOrderImage} />
                        </div>
                        <View fullw column>
                            <span style={compact_styles.header}>{clientOrder.clientOrderCode}</span>
                            <Box textStyle="description">
                                {clientOrder.clientOrderDescription ? clientOrder.clientOrderDescription : clientOrder.description}
                            </Box>
                            <Box textStyle="description">Programma: {clientOrder.programmaCommessa}</Box>
                            <Box textStyle="meta" style={{ textTransform: 'capitalize' }}>
                                {moment(clientOrder.creationDate).format('LLLL')}
                            </Box>
                        </View>
                    </View>
                </Box>
            </Box>
        )
    }

    onCostChange(newCost, shorthand) {
        let { costDict, totalWithItems } = this.state
        let total = 0
        costDict[shorthand] = newCost

        for (let i in costDict) {
            //console.log('Cost dict key: ', i)
            if (i === 'cost' && !totalWithItems) continue
            if (i === 'item' && !totalWithItems) continue
            total += costDict[i]
        }

        this.setState({
            costDict,
            total,
        })
    }

    renderDataTable() {
        let { grouped } = this.state
        let { isBalance } = this.props

        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }

        let orderBy = (a, b) => {
            return a.millis - b.millis
        }

        const stats = computeGaps(grouped)

        return <CustomTable {...extraProps} orderBy={orderBy} name="Dati" shorthand="data" values={stats} child={DataPoint} />
    }

    renderGT2000Table() {
        let { isBalance, getGT2000DataFilter } = this.props

        //console.log('Rendering GT2000 table with data', getGT2000DataFilter)
        if (!getGT2000DataFilter || !getGT2000DataFilter.data) return null

        let gt2000datalist = getGT2000DataFilter.data

        return <CustomTable name="Dati GT2000 relativi alla ordine cliente" shorthand="gt2000data" values={gt2000datalist.data} child={GT2000Data} />
    }

    renderBabypressaTable() {
        let { isBalance, getBabypressaDataFilter } = this.props

        //console.log('Rendering BabyPressa table with data', getBabypressaDataFilter)
        if (!getBabypressaDataFilter || !getBabypressaDataFilter.data) return null

        let babypressadatalist = getBabypressaDataFilter.data

        return (
            <CustomTable
                name="Dati Babypressa relativi alla ordine cliente"
                shorthand="babypressadata"
                values={babypressadatalist.data}
                child={BabypressaData}
            />
        )
    }

    // renderMachineTable() {
    //     let { grouped } = this.state
    //     let { isBalance } = this.props
    //     let extraProps = {}
    //     if (isBalance) {
    //         extraProps = {
    //             isBalance,
    //             onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
    //         }
    //     }

    //     let orderBy = (a, b) => {
    //         return a.millis - b.millis
    //     }

    //     const stats = computeGaps(grouped)

    //     return <CustomTable {...extraProps} orderBy={orderBy} name="Dati" shorthand="data" values={stats} child={DataPoint} />

    //     // return <CustomTable {...extraProps} name='Macchine{'\''} shorthand='machine{'\''} values={machines} child={Machine} />
    // }

    computeMachineTimes() {
        let { machines, hadesMachines } = this.state
        let { machineList } = this.props

        if (!hadesMachines) {
            if (!machines) {
                return []
            } else {
                return machines
            }
        }

        if (machineList) {
            for (let i in hadesMachines) {
                let machine = hadesMachines[i]
                let { machineIdentifier } = machine
                let timeTotal = 0
                for (let i in machine.workSpans) {
                    timeTotal += machine.workSpans[i].size
                }

                let seconds = timeTotal / 1000
                let minutes = seconds / 60
                let workHours = minutes / 60
                let workMinutes = (workHours % 1) * 60
                workHours = Math.floor(workHours)

                for (let reference of machineList) {
                    //console.log('Checking', machineIdentifier, reference.machineIdentifier)
                    if (reference.machineIdentifier === machineIdentifier) {
                        let skip = false
                        for (let toCheck in machines) {
                            if (machines[toCheck].machineId === reference.machineId) {
                                // Skip this one because it's already present
                                // TODO add machine time instead of replacing
                                skip = true
                                machines[toCheck].machineHours = workHours
                                machines[toCheck].machineMinutes = workMinutes
                                machines[toCheck].time = timeTotal
                                break
                            }
                        }

                        /*
							machineId,
							machineName,
							machineHours,
							machineMinutes,
							machineHourlyRate
						*/

                        //console.log('About to add machine', reference, skip)
                        if (!skip) {
                            machines.push({
                                ...reference,
                                machineHours: workHours,
                                machineMinutes: workMinutes,
                                time: timeTotal,
                            })
                        }
                    }
                }
            }
        }

        return machines
    }

    renderMachineTable(machines) {
        let { isBalance } = this.props
        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }

        //console.log('Machines are', machines)

        return <CustomTable {...extraProps} showNoMachine name="Macchine" shorthand="machine" values={machines} child={Machine} />
    }

    computeWorkerTimes() {
        let { workers, hadesWorkers } = this.state
        let { workerList } = this.props

        if (!hadesWorkers) {
            if (!workers) {
                return []
            } else {
                return workers
            }
        }

        if (workerList) {
            for (let i in hadesWorkers) {
                let worker = hadesWorkers[i]
                let { workerIdentifier } = worker
                let timeTotal = 0
                for (let i in worker.workSpans) {
                    timeTotal += worker.workSpans[i].size
                }

                let seconds = timeTotal / 1000
                let minutes = seconds / 60
                let workHours = minutes / 60
                let workMinutes = (workHours % 1) * 60
                workHours = Math.floor(workHours)

                for (let reference of workerList) {
                    if (reference.workerIdentifier === workerIdentifier) {
                        let skip = false
                        for (let toCheck in workers) {
                            if (workers[toCheck].workerId === reference.workerId) {
                                // Skip this one because it's already present
                                // TODO add worker time instead of replacing
                                skip = true
                                workers[toCheck].workerHours = workHours
                                workers[toCheck].workerMinutes = workMinutes
                                workers[toCheck].time = timeTotal
                                break
                            }
                        }

                        /*
							workerId,
							workerName,
							workerHours,
							workerMinutes,
							workerHourlyRate
						*/

                        if (!skip) {
                            workers.push({
                                ...reference,
                                workerHours: workHours,
                                workerMinutes: workMinutes,
                                time: timeTotal,
                            })
                        }
                    }
                }
            }
        }

        return workers
    }

    renderWorkerTable(workers) {
        let { isBalance } = this.props
        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }

        //console.log('Workers are', workers)

        return <CustomTable {...extraProps} name="Impiegati" shorthand="worker" values={workers} child={Worker} />
    }

    renderWorkTable() {
        let { works } = this.state
        let { isBalance } = this.props
        let extraProps = {}

        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }
        return <CustomTable {...extraProps} name="Lavori" shorthand="work" values={works} child={Work} required="registrationDate" />
    }

    renderCostTable() {
        let { costs } = this.state
        let { isBalance } = this.props
        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }
        return <CustomTable {...extraProps} name="Costi" shorthand="cost" values={costs} child={Cost} />
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, clientOrder } = this.props

        network.deleteClientOrder(clientOrder)

        this.setState({ deleting: false })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    renderCardContent(user, workerTimeObject, machineTimeObject, direction) {
        let { mappingData, type, noHours, noActions, role } = this.props
        let {
            budgeted,
            billed,
            dataUltimaFatturazione,
            clientOrder,
            budgetedModal,
            billedModal,
            dateModal,
            editMapping,
            newmaprow,
            newmapcolumn,
        } = this.state

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }

        return (
            <View fullw {...extraViewProps} around>
                <View around style={extraViewProps.row ? styles.imageContainer : styles.imageContainerMobile}>
                    <ImageDefault
                        fromRoot
                        src={clientOrder.image}
                        ui={false}
                        style={extraViewProps.row ? styles.clientOrderImage : styles.clientOrderImageMobile}
                    />
                </View>
                <View fullw {...extraViewProps} around>
                    <View
                        fullw
                        column
                        style={{
                            maxWidth: '95%',
                        }}
                    >
                        <Default>
                            <span style={styles.header}>{clientOrder.clientOrderCode}</span>
                        </Default>
                        <Mobile>
                            <span style={styles.smallHeader}>{clientOrder.clientOrderCode}</span>
                        </Mobile>
                        <Box textStyle="description">
                            {clientOrder.clientOrderDescription ? clientOrder.clientOrderDescription : clientOrder.description}
                        </Box>
                        <Box textStyle="meta" style={{ textTransform: 'capitalize' }}>
                            Creata {moment(clientOrder.creationDate).format('LLLL')}
                        </Box>
                        {mappingData &&
                            mappingData.data &&
                            clientOrder &&
                            clientOrder.clientOrderCode &&
                            mappingData.data[clientOrder.clientOrderCode] && (
                                <Box textStyle="meta">
                                    Posizione dello stampo nell{"'"}armadio: RIGA {mappingData.data[clientOrder.clientOrderCode].row} COLONNA{' '}
                                    {mappingData.data[clientOrder.clientOrderCode].column}{' '}
                                    <Icon
                                        onClick={() => {
                                            this.setState({
                                                deletingMapping: true,
                                            })
                                        }}
                                        name="trash"
                                    />
                                </Box>
                            )}
                        {clientOrder.workOrders
                            ? clientOrder.workOrders.map((value, index) => {
                                  return (
                                      <Box textStyle="meta" key={`work_order_${value.workOrderId}`}>
                                          ODL {index + 1}: {value.workCode}
                                      </Box>
                                  )
                              })
                            : null}
                        {!noActions && user && !!user.manage_job && (
                            <Box textStyle="meta">
                                Ultima fatturazione: {clientOrder.lastBillDate ? moment(clientOrder.lastBillDate).format('LL') : '----'}{' '}
                                <Icon
                                    onClick={() => {
                                        // Format: YYYY-MM-DD
                                        let momobj = moment(clientOrder.lastBillDate)

                                        let data = new Date()
                                        if (momobj.isValid()) {
                                            data = momobj.toDate()
                                        }

                                        this.setState({
                                            lastBillDate: data,
                                            dateModal: true,
                                        })
                                    }}
                                    name="edit"
                                />
                                <Modal open={dateModal}>
                                    <Modal.Header>Modifica cifra preventivata ordine cliente {clientOrder.clientOrderCode}</Modal.Header>
                                    <Modal.Content>
                                        <Form>
                                            <Form.Field
                                                control={DatePicker}
                                                label={<span className="label">Data ultima fatturazione</span>}
                                                name="dataUltimaFatturazione"
                                                value={dataUltimaFatturazione}
                                                dateFormat="dd/MM/yyyy"
                                                selected={dataUltimaFatturazione}
                                                onChange={(data) => {
                                                    this.setState({
                                                        dataUltimaFatturazione: data,
                                                    })
                                                }}
                                            />
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button
                                            primary
                                            onClick={() => {
                                                this.setState({ dateModal: false })
                                            }}
                                        >
                                            Annulla <Icon name="check" />
                                        </Button>
                                        <Button
                                            primary
                                            onClick={() => {
                                                let { clientOrder, dataUltimaFatturazione } = this.state
                                                let { network } = this.props
                                                clientOrder.dataUltimaFatturazione = dataUltimaFatturazione
                                                this.setState({ clientOrder, dateModal: false })

                                                let { clientOrderCode } = clientOrder
                                                network.createClientOrder(clientOrderCode, {
                                                    clientOrderCode,
                                                    dataUltimaFatturazione,
                                                })
                                            }}
                                        >
                                            Conferma <Icon name="check" />
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            </Box>
                        )}

                        <Modal open={editMapping}>
                            <Modal.Header>Modifica mappatura stampo {clientOrder.clientOrderCode}</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            control={Input}
                                            label={<span className="label">Riga</span>}
                                            name="row"
                                            type="number"
                                            value={newmaprow}
                                            onChange={(value) => {
                                                this.setState({ newmaprow: value.target.value })
                                            }}
                                        />
                                        <Form.Field
                                            control={Input}
                                            label={<span className="label">Riga</span>}
                                            name="row"
                                            type="number"
                                            value={newmapcolumn}
                                            onChange={(value) => {
                                                this.setState({ newmapcolumn: value.target.value })
                                            }}
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    primary
                                    onClick={() => {
                                        this.setState({ editMapping: false })
                                    }}
                                >
                                    Annulla <Icon name="check" />
                                </Button>
                                <Button
                                    primary
                                    onClick={() => {
                                        let { clientOrder, newmaprow, newmapcolumn } = this.state
                                        let { network } = this.props

                                        network.createMapping({
                                            clientOrderCode: clientOrder.clientOrderCode,
                                            row: newmaprow,
                                            column: newmapcolumn,
                                        })

                                        this.setState({
                                            newmaprow: null,
                                            newmapcolumn: null,
                                            editMapping: false,
                                        })
                                    }}
                                >
                                    Conferma <Icon name="check" />
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </View>
                    <View fullw column>
                        <View fullw column style={{ marginTop: 4, justifyContent: 'center', minHeight: 60 }}>
                            {clientOrder.client && (
                                <View fullw row style={{ justifyContent: 'center', textAlign: 'center' }}>
                                    <span style={{ fontWeight: 'lighter', marginTop: 2, marginRight: 16 }}>Cliente:</span>
                                    <Box textStyle="header" style={{ marginTop: 0 }}>
                                        {clientOrder.client}
                                    </Box>
                                </View>
                            )}
                            {clientOrder.deliveryDate && (
                                <View fullw row style={{ marginTop: 4, justifyContent: 'center', textAlign: 'center' }}>
                                    <span style={{ fontWeight: 'lighter', marginTop: 2, marginRight: 16 }}>Consegna:</span>
                                    <Box textStyle="header" style={{ marginTop: 0 }}>
                                        {moment(clientOrder.deliveryDate).format('DD/MM/YYYY')}
                                    </Box>
                                    <span style={{ fontWeight: 'lighter', marginTop: 2, marginLeft: 8 }}>
                                        (Settimana{' '}
                                        <span style={{ fontWeight: 'bold', marginTop: 0 }}>{moment(clientOrder.deliveryDate).get('week')}</span>)
                                    </span>
                                </View>
                            )}
                        </View>
                        {/* {!noHours && !noActions && (
                            <View style={{ minHeight: 103 }} fullw row around>
                                <Box alignItems="center" style={{ marginBottom: 0, alignSelf: 'center' }}>
                                    <Box fontSize="lg" textAlign={['center', 'center']}>Ore operaio</Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{workerTimeObject.hstring}</Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{workerTimeObject.mstring}</Box>
                                </Box>
                                <Box alignItems="center" style={{ alignSelf: 'center' }}>
                                    <Box fontSize="lg" textAlign={['center', 'center']}>Ore macchina</Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{machineTimeObject.hstring}</Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{machineTimeObject.mstring}</Box>
                                </Box>
                            </View>
                        )} */}
                        {!noActions && (!role || role === 'admin') && (
                            <View fullw row around>
                                <Box alignItems="center" style={{ marginBottom: 0, alignSelf: 'center' }}>
                                    <Box fontSize="lg" textAlign={['center', 'center']}>
                                        Preventivato{' '}
                                        <Icon
                                            onClick={() => {
                                                this.setState({
                                                    budgeted: this.state.clientOrder.budgeted,
                                                    budgetedModal: true,
                                                })
                                            }}
                                            name="edit"
                                        />
                                        <Modal open={budgetedModal}>
                                            <Modal.Header>Modifica cifra preventivata ordine cliente {clientOrder.clientOrderCode}</Modal.Header>
                                            <Modal.Content>
                                                <Form>
                                                    <Form.Field
                                                        control={Input}
                                                        type="number"
                                                        label={<span className="label">Preventivato</span>}
                                                        placeholder="0.0"
                                                        step="0.1"
                                                        name="budgeted"
                                                        value={budgeted}
                                                        className="rightTextField"
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                </Form>{' '}
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button
                                                    primary
                                                    onClick={() => {
                                                        this.setState({ budgetedModal: false })
                                                    }}
                                                >
                                                    Annulla <Icon name="check" />
                                                </Button>
                                                <Button
                                                    primary
                                                    onClick={() => {
                                                        let { clientOrder, budgeted } = this.state
                                                        let { network } = this.props
                                                        clientOrder.budgeted = budgeted
                                                        this.setState({ clientOrder, budgetedModal: false })

                                                        let { clientOrderCode } = clientOrder
                                                        network.createClientOrder(clientOrderCode, {
                                                            clientOrderCode,
                                                            budgeted,
                                                        })
                                                    }}
                                                >
                                                    Conferma <Icon name="check" />
                                                </Button>
                                            </Modal.Actions>
                                        </Modal>
                                    </Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallestStatistic">
                                        {clientOrder.budgeted ? clientOrder.budgeted : '----'}€
                                    </Box>
                                </Box>
                                <Box alignItems="center" style={{ alignSelf: 'center' }}>
                                    <Box fontSize="lg" textAlign={['center', 'center']}>
                                        Fatturato{' '}
                                        <Icon
                                            onClick={() => {
                                                this.setState({
                                                    budgeted: this.state.clientOrder.budgeted,
                                                    billedModal: true,
                                                })
                                            }}
                                            name="edit"
                                        />
                                        <Modal open={billedModal}>
                                            <Modal.Header>Modifica cifra preventivata ordine cliente {clientOrder.clientOrderCode}</Modal.Header>
                                            <Modal.Content>
                                                <Form>
                                                    <Form.Group widths="equal">
                                                        <Form.Field
                                                            control={Input}
                                                            type="number"
                                                            label={<span className="label">Fatturato</span>}
                                                            placeholder="0.0"
                                                            step="0.1"
                                                            name="billed"
                                                            value={billed}
                                                            className="rightTextField"
                                                            onChange={(e) => this.handleInput(e)}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button
                                                    primary
                                                    onClick={() => {
                                                        this.setState({ billedModal: false })
                                                    }}
                                                >
                                                    Annulla <Icon name="check" />
                                                </Button>
                                                <Button
                                                    primary
                                                    onClick={() => {
                                                        let { clientOrder, billed } = this.state
                                                        let { network } = this.props
                                                        clientOrder.billed = billed
                                                        this.setState({ clientOrder, billedModal: false })

                                                        let { clientOrderCode } = clientOrder
                                                        network.createClientOrder(clientOrderCode, {
                                                            clientOrderCode,
                                                            billed,
                                                        })
                                                    }}
                                                >
                                                    Conferma <Icon name="check" />
                                                </Button>
                                            </Modal.Actions>
                                        </Modal>
                                    </Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallestStatistic">
                                        {clientOrder.billed ? clientOrder.billed : '----'}€
                                    </Box>
                                </Box>
                            </View>
                        )}
                    </View>
                </View>
            </View>
        )
    }

    renderExportModal() {
        let { clientOrder, exportModal, wait } = this.state

        let articlecode = ''

        if (clientOrder && clientOrder.clientOrderCode && clientOrder.clientOrderCode.length > 8) {
            articlecode = clientOrder.clientOrderCode.substring(0, 7)
        }

        return (
            <Modal open={exportModal}>
                <Modal.Header>Esportazione documenti</Modal.Header>
                <Modal.Content>
                    Esportazione documenti per la ordine cliente {clientOrder.clientOrderCode}
                    Premi il pulsante calcola per avviare il processo, poi scarica quando e{"'"} tutto pronto
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: true, exportModal: false })
                        }}
                    >
                        Annulla <Icon name="cancel" />
                    </Button>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: false })
                        }}
                    >
                        Calcola <Icon name="computer" />
                    </Button>
                    <DocumentRecap
                        wait={wait}
                        articlecode={articlecode}
                        onDone={(data) => {
                            //console.log('On done called', data)
                            this.setState({ wait: true })
                        }}
                        element={
                            <Button
                                primary
                                icon
                                onClick={() => {
                                    this.setState({ wait: true, exportModal: false })
                                }}
                            >
                                Scarica <Icon name="download" />
                            </Button>
                        }
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        let { login, type, noActions, onClick, selected, isBalance, onOpened, forcedOpen, role, mappingData } = this.props
        let {
            color,
            clientOrder,
            deleting,
            total,
            totalWorkerTime,
            totalMachineTime,
            totalWithItems,
            totalWithFixedCosts,
            costDict,
            external,
            machines,
            workers,
            deletingMapping,
            showTaskModal,
            showDeliveryDateModal,
            showCostDialog,
        } = this.state

        if (!clientOrder) {
            return (
                <Box style={{ width: '100%', margin: 8 }}>
                    <Box textStyle="content">
                        <Placeholder>
                            <Placeholder.Header image>
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    </Box>
                </Box>
            )
        }

        let partial = (costDict['worker'] ? costDict['worker'] : 0) + (costDict['machine'] ? costDict['machine'] : 0)

        let totalhours = totalWorkerTime[0] + totalMachineTime[0] + (totalWorkerTime[1] + totalMachineTime[1]) / 60
        let averageHourlyCost = Math.round((partial / totalhours) * 100) / 100

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        let cardExtraStyle = {}

        if (clientOrder.urgencyLevel > 0) {
            cardExtraStyle.backgroundColor = '#ffe6a6'
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(clientOrder)
            }
        }

        if (type === 'compact') {
            return this.renderCompact()
        }

        //----------------------------------------------------------------------------
        let computedWorkers = this.computeWorkerTimes()
        let computedMachines = this.computeMachineTimes()

        let workerTimeObject
        let machineTimeObject

        const CONVERT_MILLIS = 60 * 60 * 1000
        if (computedWorkers.length !== 0 && computedMachines.length !== 0) {
            let workers = computedWorkers
            let machines = computedMachines

            let workerTimeTotal = 0
            for (let i in workers) {
                if (workers[i].time) {
                    workerTimeTotal += workers[i].time
                } else {
                    workerTimeTotal += (workers[i].workerHours + workers[i].workerMinutes / 60) * CONVERT_MILLIS
                }
            }
            let workerHours = workerTimeTotal / CONVERT_MILLIS
            let workerMinutes = (workerHours % 1) * 60
            workerHours = Math.floor(workerHours)

            let machineTimeTotal = 0
            for (let i in machines) {
                if (machines[i].machineId === 1) continue // skip no_machine
                if (machines[i].time) {
                    machineTimeTotal += machines[i].time
                } else {
                    machineTimeTotal += (machines[i].machineHours + machines[i].machineMinutes / 60) * CONVERT_MILLIS
                }
            }
            let machineHours = machineTimeTotal / CONVERT_MILLIS
            let machineMinutes = (machineHours % 1) * 60
            machineHours = Math.floor(machineHours)

            totalWorkerTime = [workerHours, workerMinutes]
            totalMachineTime = [machineHours, machineMinutes]

            if (totalWorkerTime || totalMachineTime) totalhours = 0

            if (totalWorkerTime) {
                workerTimeObject = timeObject(totalWorkerTime[0], totalWorkerTime[1])
                workerHours = totalWorkerTime[0]
                workerMinutes = totalWorkerTime[1]
                totalhours += workerTimeTotal
            }
            if (totalMachineTime) {
                machineTimeObject = timeObject(totalMachineTime[0], totalMachineTime[1])
                machineHours = totalMachineTime[0]
                machineMinutes = totalMachineTime[1]
                totalhours += machineTimeTotal
            }
        } else {
            if (totalWorkerTime) {
                workerTimeObject = timeObject(totalWorkerTime[0], totalWorkerTime[1])
            }
            if (totalMachineTime) {
                machineTimeObject = timeObject(totalMachineTime[0], totalMachineTime[1])
            }
        }

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        total = Math.round(total * 100) / 100

        totalhours = totalWorkerTime[0] + totalMachineTime[0] + (totalWorkerTime[1] + totalMachineTime[1]) / 60
        averageHourlyCost = Math.round((partial / totalhours) * 100) / 100

        if (type === 'table') {
            return (
                <Table.Row>
                    <Table.Cell>{clientOrder.clientOrderCode}</Table.Cell>
                    <Table.Cell>{clientOrder.product}</Table.Cell>
                    <Table.Cell>{clientOrder.productQuantity}</Table.Cell>
                    <Table.Cell>{moment(clientOrder.creationDate).format('DD/MM/YYYY')}</Table.Cell>
                    <Table.Cell>{moment(clientOrder.deliveryDate).format('DD/MM/YYYY')}</Table.Cell>
                </Table.Row>
            )
        }

        return (
            <View className="clientOrder-content-container" noflex fullw column between>
                {/* <View fullw column between> */}
                <Box
                    id={clientOrder.clientOrderCode}
                    className={selected ? 'rowSelected' : ''}
                    style={{ ...styles.clientOrderCard, ...cardExtraStyle }}
                    {...extraprops}
                    color={color}
                >
                    {this.renderExportModal()}
                    {/* {clientOrder && (
                        <TaskModal
                            clientOrder={clientOrder}
                            showModal={showTaskModal}
                            onConfirm={() => {
                                this.setState({ showTaskModal: false })
                            }}
                            onCancel={() => {
                                this.setState({ showTaskModal: false })
                            }}
                        />
                    )} */}
                    {/* {clientOrder && (
                        <DeliveryDateModal
                            clientOrder={clientOrder}
                            showModal={showDeliveryDateModal}
                            onConfirm={() => {
                                this.setState({ showDeliveryDateModal: false })
                            }}
                            onCancel={() => {
                                this.setState({ showDeliveryDateModal: false })
                            }}
                        />
                    )} */}
                    <Confirmation
                        message="Vuoi davvero eliminare questo ordine cliente? Tutte le lavorazioni e i costi saranno perduti!"
                        showModal={deleting}
                        onConfirm={() => {
                            this.remove()
                        }}
                        onCancel={() => {
                            this.setState({ deleting: false })
                        }}
                    />
                    {/* <Confirmation
                        message="Vuoi davvero eliminare questo mappatura?"
                        showModal={deletingMapping}
                        onConfirm={() => {
                            this.props.network.deleteMapping({
                                clientOrderCode: clientOrder.clientOrderCode,
                            })
                            this.setState({ deletingMapping: false })
                        }}
                        onCancel={() => {
                            this.setState({ deletingMapping: false })
                        }}
                    /> */}
                    <Modal
                        onClose={() => {
                            this.closeModal()
                        }}
                        open={showCostDialog}
                    >
                        <Modal.Header>Aggiunta costo</Modal.Header>
                        <CostEditor
                            sub
                            onChange={(cost) => {
                                //console.log('Creating cost', cost)
                                this.setState({ tempCost: cost })
                            }}
                            clientOrder={clientOrder.clientOrderCode}
                        />
                        <Modal.Actions>
                            <Button
                                primary
                                onClick={() => {
                                    this.setState({
                                        showCostDialog: false,
                                    })
                                }}
                            >
                                Annulla <Icon name="cancel" />
                            </Button>
                            <Button
                                primary
                                onClick={() => {
                                    const cost = { ...this.state.tempCost }
                                    cost.costDate = moment(this.state.tempCost.costDate).local().format('YYYY-MM-DD HH:mm:ss')

                                    this.props.network.createCost(cost)
                                    const { costs } = this.state
                                    costs.push(cost)

                                    this.setState({
                                        showCostDialog: false,
                                        costs,
                                        tempCost: null,
                                    })
                                }}
                            >
                                Conferma <Icon name="check" />
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <Box textStyle="content" style={styles.clientOrderContent}>
                        <Default>{this.renderCardContent(user, workerTimeObject, machineTimeObject)}</Default>
                        <Mobile>{this.renderCardContent(user, workerTimeObject, machineTimeObject, 'column')}</Mobile>
                    </Box>
                    {type === 'full' && !noActions && (
                        <Box textStyle="content" extra>
                            {/* {!forcedOpen && !external && (
                                <Button
                                    onClick={() => {
                                        if (onOpened) {
                                            onOpened(clientOrder, true, () => {
                                                this.props.history.push(route_from(app, clientOrders, `${clientOrder.clientOrderCode}`))
                                            })
                                        } else {
                                            this.props.history.push(route_from(app, clientOrders, `${clientOrder.clientOrderCode}`))
                                        }
                                    }}
                                >
                                    <Icon name="folder open" />
                                    <Default>Apri</Default>
                                </Button>
                            )} */}
                            {user && !!user.manage_job && (
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.props.history.push(route_from(app, clientOrders, clientOrderEdit, `${clientOrder.clientOrderCode}`))
                                    }}
                                >
                                    <Icon name="edit" />
                                    Modifica
                                </Button>
                            )}
                            {user && !!user.manage_job && (
                                <Button
                                    color="red"
                                    onClick={() => {
                                        this.openRemoveDialog()
                                    }}
                                >
                                    <Icon name="delete" />
                                    Elimina
                                </Button>
                            )}
                            {forcedOpen && !external && (!role || role === 'admin') && (
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        if (onOpened) {
                                            onOpened(clientOrder, true, () => {
                                                this.props.history.push(route_from(app, clientOrders, clientOrder.clientOrderCode, finalBalance))
                                            })
                                        } else {
                                            this.props.history.push(route_from(app, clientOrders, clientOrder.clientOrderCode, finalBalance))
                                        }
                                    }}
                                >
                                    <Icon name="book" />
                                    <Default>Consuntivo</Default>
                                </Button>
                            )}
                            {/* {user && !!user.manage_job && (
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.setState({ exportModal: true })
                                    }}
                                >
                                    <Icon name="clipboard list" />
                                    <Default>Esporta documenti relativi alla ordine cliente</Default>
                                </Button>
                            )} */}
                        </Box>
                    )}
                    {type === 'full' && (forcedOpen || isBalance) && (
                        <Transition visible={forcedOpen || isBalance ? true : this.state.showDetails} animation="slide down" duration={200}>
                            <Box textStyle="content">
                                <View fullw column>
                                    {this.renderControls()}
                                    {this.renderCharts()}
                                    {this.renderDataTable()}
                                    {this.renderMachineTable(machines)}
                                    {this.renderWorkerTable(workers)}
                                    {this.renderCostTable()}
                                    {!isBalance && this.renderWorkTable()}
                                    {/* {this.renderGT2000Table()} */}
                                    {/* {this.renderBabypressaTable()} */}
                                    {isBalance && (
                                        <>
                                            <Items
                                                type="grouped"
                                                onCostChange={(total, shorthand) => this.onCostChange(total, shorthand)}
                                                isBalance
                                                clientOrderId={clientOrder.clientOrderCode}
                                            />
                                            <View className="no-print" around fullw row style={{ marginBottom: 16 }}>
                                                <Checkbox
                                                    toggle
                                                    value={totalWithFixedCosts}
                                                    checked={totalWithFixedCosts}
                                                    label={<span className="label">Includi costi fissi nel calcolo totale</span>}
                                                    onChange={(e, data) => {
                                                        this.setState({ totalWithFixedCosts: data.checked }, () => {
                                                            this.onCostChange(0, 0)
                                                        })
                                                    }}
                                                />
                                                <Checkbox
                                                    toggle
                                                    value={totalWithItems}
                                                    checked={totalWithItems}
                                                    label={<span className="label">Includi materiale nel calcolo totale</span>}
                                                    onChange={(e, data) => {
                                                        this.setState({ totalWithItems: data.checked }, () => {
                                                            this.onCostChange(0, 0)
                                                        })
                                                    }}
                                                />
                                            </View>
                                            <View className="only-print" around fullw row style={{ marginBottom: 16 }}>
                                                <span>
                                                    {totalWithFixedCosts ? 'Costi fissi inclusi nel totale' : 'Costi fissi non inclusi nel totale'}
                                                </span>
                                            </View>
                                            <View className="only-print" around fullw row style={{ marginBottom: 16 }}>
                                                <span>{totalWithItems ? 'Materiale incluso nel totale' : 'Materiale non incluso nel totale'}</span>
                                            </View>
                                        </>
                                    )}
                                    {isBalance && (
                                        <Table>
                                            <Table.Header fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{ textAlign: 'right' }} width="4">
                                                        Totale ore
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width="1" />
                                                    <Table.HeaderCell style={{ textAlign: 'center' }} width="2">
                                                        Prezzo orario medio
                                                    </Table.HeaderCell>
                                                    {totalWithFixedCosts && <Table.HeaderCell width="1" />}
                                                    {totalWithFixedCosts && (
                                                        <Table.HeaderCell style={{ textAlign: 'center' }} width="2">
                                                            Costi aggiuntivi
                                                        </Table.HeaderCell>
                                                    )}
                                                    {totalWithItems && <Table.HeaderCell width="1" />}
                                                    {totalWithItems && (
                                                        <Table.HeaderCell style={{ textAlign: 'center' }} width="2">
                                                            Costi materiale
                                                        </Table.HeaderCell>
                                                    )}
                                                    <Table.HeaderCell width="1" />
                                                    <Table.HeaderCell width="4">Totale</Table.HeaderCell>
                                                </Table.Row>

                                                <Table.Row>
                                                    <Table.Cell style={{ textAlign: 'right' }}>{floatTimeToString(totalhours)}</Table.Cell>
                                                    <Table.Cell>x</Table.Cell>
                                                    <Table.Cell style={{ textAlign: 'center' }}>
                                                        {isNaN(averageHourlyCost) ? '--' : averageHourlyCost}€/h
                                                    </Table.Cell>
                                                    {totalWithFixedCosts && <Table.Cell>+</Table.Cell>}
                                                    {totalWithFixedCosts && (
                                                        <Table.Cell style={{ textAlign: 'center' }}>
                                                            {!costDict['cost'] || isNaN(costDict['cost']) ? '--' : costDict['cost']}€
                                                        </Table.Cell>
                                                    )}
                                                    {totalWithItems && <Table.Cell>+</Table.Cell>}
                                                    {totalWithItems && (
                                                        <Table.Cell style={{ textAlign: 'center' }}>
                                                            {!costDict['item'] || isNaN(costDict['item'])
                                                                ? '--'
                                                                : Math.round(costDict['item'] * 100) / 100}
                                                            €
                                                        </Table.Cell>
                                                    )}
                                                    <Table.Cell>=</Table.Cell>
                                                    <Table.Cell>{total}€</Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    )}
                                </View>
                            </Box>
                        </Transition>
                    )}
                </Box>
            </View>
        )
    }
}

const compact_styles = {
    header: {
        fontSize: '20pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '18pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        width: 50,
        height: 50,
    },
    clientOrderImage: {
        width: 50,
        height: 50,
        objectFit: 'contain',
    },
    clientOrderContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clientOrderCard: {
        width: 200,
        minWidth: 200,
        height: 200,
        margin: 8,
    },
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    imageContainerMobile: {
        width: 100,
        height: 75,
        margin: 'auto',
    },
    clientOrderImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    clientOrderImageMobile: {
        width: 100,
        height: 75,
        objectFit: 'contain',
    },
    clientOrderContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    clientOrderCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let {
        getClientOrder,
        login,
        organization,
        getMachineList: {
            data: { data: machineList },
        },
        getWorkerList: {
            data: { data: workerList },
        },
        getMappingData: { data: mappingData, fetching: fetchingMap, status: mapStatus },
        getBabypressaDataFilter,
        getGT2000DataFilter,
        getFromId,
        getFromIdFiltering,
        realtime: { data },
        deleteCost,
    } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return {
        mappingData,
        fetchingMap,
        mapStatus,
        getBabypressaDataFilter,
        getGT2000DataFilter,
        getClientOrder,
        role: rcode,
        machineList,
        workerList,
        getFromId,
        getFromIdFiltering,
        data,
        organization,
        login,
        deleteCost,
    }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(ClientOrder)))
