import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { Confirmation } from '../components'
import { floatTimeToString, timeString } from '../lib/util'

const { app, categories, categoryEdit } = routes

class Category extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, category } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...category,
            categoryMinutes: category ? parseFloat(category.categoryMinutes) : 0,
        }

        if (onCostChange && category) {
            onCostChange(this.totalCost(), category.id)
        }
    }

    totalCost() {
        let { categoryHourlyRate, categoryHours, categoryMinutes } = this.state
        let total = (categoryHours + categoryMinutes / 60) * parseFloat(categoryHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { category, id, network } = this.props

        if ((!category || !category.id) && id) {
            network.getCategory(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getCategory, login } = this.props
        let { id, categoryHourlyRate } = this.state
        if (categoryHourlyRate !== prevState.categoryHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), id)
            }
        }

        // console.log('Category getCategory fetching', getCategory.fetching)
        if (getCategory && getCategory.fetching !== prevProps.getCategory.fetching && !getCategory.fetching) {
            // console.log('Done calling getCategory')
            if (getCategory.status.success) {
                // console.log(getCategory.data)
                let category = getCategory.data.data

                this.setState({ ...category })
                // console.log('Calling Get category', login, category)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No category found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { name, categoryHourlyRate, categoryHours, categoryMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={categoryHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    categoryHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{categoryHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(categoryHours, categoryMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideCategory, hideCategoryHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideCategory && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideCategoryHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideCategory, hideCategoryHours, isBalance } = this.props
        let { name, categoryHours, categoryMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideCategory && <Table.Cell>{name}</Table.Cell>}
                    {!hideCategoryHours && <Table.Cell>{timeString(categoryHours, categoryMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, category } = this.props

        network.deleteCategory(category.id)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, category, onClick, selected, noActions } = this.props
        let {
            deleting,
            id,
            name,
            // categoryImage,
        } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(category)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.categoryCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questa categoria?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.categoryContent}>
                                <View fullw row>
                                    {/* <div style={styles.imageContainer}>
										<ImageDefault
											fromRoot
											src={categoryImage}
											ui={false}
											style={styles.categoryImage}
										/>
									</div> */}
                                    <View fullw column>
                                        <span style={styles.header}>{name}</span>
                                        {/* {!noActions &&
										(!role || role === 'admin') && (
											<Box textStyle="description">
												Prezzo orario macchina: {categoryHourlyRate}€/h
											</Box>
										)} */}
                                    </View>
                                </View>
                            </Box>
                            {!noActions && (
                                <Box textStyle="content" extra>
                                    {user && !!user.manage_category && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, categories, categoryEdit, `${id}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            Modifica
                                        </Button>
                                    )}

                                    {user && !!user.manage_category && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            Elimina
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    categoryImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    categoryContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    categoryCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getCategory } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getCategory, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Category)))
