import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Confirmation, CustomTable, Work } from '../components'
import MenuBar from '../layout/MenuBar'
import { floatTimeToObject } from '../lib/util'
class Works extends Component {
    constructor(props) {
        super(props)
        const { sub, deletable, noPagination } = props

        let menuitems = []

        if (deletable) {
            menuitems.push({
                name: 'Elimina',
                icon: 'delete',
                position: 'left',
                disabled: () => {
                    return false
                },
                action: () => {
                    this.startDelete()
                },
            })
        }
        /*
		if (this.state.job === '00') {
			menuitems.push({
				name: 'Assegna lavori selezionati',
				position: undefined,
				action: () => {
					this.doAssign();
				}
			});

			menuitems.push({
				name: 'Crea nuova commessa con lavori selezionati',
				position: undefined,
				action: () => {
					this.createJobAndAssign();
				}
			});
		}
		*/
        if (!sub && !noPagination) {
            menuitems.push({
                name: '',
                icon: 'arrow left',
                position: 'right',
                action: () => {
                    this.prev()
                },
            })

            menuitems.push({
                name: '',
                icon: 'arrow right',
                position: undefined,
                action: () => {
                    this.next()
                },
            })
        }

        this.state = {
            page: 0,
            items: props.noPagination ? -1 : 20,
            selected: props.selected ? props.selected : {},
            job: props.job ? props.job : '',
            deleting: false,
            showCreationDialog: false,
            currentDateRange: [],
            toolbar: menuitems,
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { page, items, search, searchable, assignWork, network, job } = this.state
        let {
            date,
            worker,
            fetching,
            getWorkList,
            dateRange,
            invertSelection,
            onChange,
            works,
            isEmployee,
            fixedWorks,
            minus,
            organization,
        } = this.props

        let searchText = search.data
        if (!searchable) {
            searchText = ''
        }

        let { currentDateRange } = this.state

        if (dateRange && (!moment(dateRange[0]).isSame(moment(currentDateRange[0])) || !moment(dateRange[1]).isSame(moment(currentDateRange[1])))) {
            this.setState({ currentDateRange: [moment(dateRange[0]), moment(dateRange[1])] })
        }

        if (
            !fixedWorks &&
            !date &&
            !worker &&
            (page !== prevState.page ||
                items !== this.state.items ||
                search !== prevState.search ||
                (assignWork !== prevState.assignWork && !assignWork.fetching && prevState.assignWork.fetching) ||
                (this.props.creatingWork !== prevProps.creatingWork && !this.props.creatingWork && this.props.createStatus.success) ||
                (this.props.deletingWork !== prevProps.deletingWork &&
                    this.props.deleteResult !== prevProps.deleteResult &&
                    this.props.deleteStatus.success) ||
                minus !== prevProps.minus ||
                (dateRange &&
                    (!moment(dateRange[0]).isSame(moment(currentDateRange[0])) || !moment(dateRange[1]).isSame(moment(currentDateRange[1])))) ||
                organization.data !== prevProps.organization.data)
        ) {
            let dates = null
            if (dateRange) {
                dates = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
            }

            setTimeout(() => {
                network.getWorkList(page, items, searchText, job, dates, minus)
                this.setState({ selected: {} })
            }, 100)
        } else if (
            (!fixedWorks && date && worker && (getWorkList !== prevState.getWorkList || date !== prevProps.date)) ||
            (this.props.creatingWork !== prevProps.creatingWork && !this.props.creatingWork && this.props.createStatus.success) ||
            (this.props.deletingWork !== prevProps.deletingWork &&
                this.props.deleteResult !== prevProps.deleteResult &&
                this.props.deleteStatus.success) ||
            minus !== prevProps.minus ||
            dateRange !== prevProps.dateRange ||
            organization.data !== prevProps.organization.data
        ) {
            network.getWorkListForDate(date, worker, !!isEmployee)
            this.setState({ selected: {} })
        }

        if (fixedWorks && prevProps.fixedWorks !== this.props.fixedWorks) {
            this.setState({ selected: {} })
        }

        if (fetching !== prevProps.fetching && !fetching && invertSelection && onChange) {
            onChange(works.data)
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { network, page, items, job, toolbar } = this.state
        let { fixedWorks, date, worker, sub, noPagination, dateRange, isEmployee, deletable, minus } = this.props

        if (!sub) {
            this.props.toolbarResult(toolbar)
        }

        if (!fixedWorks) {
            if (date) {
                network.getWorkListForDate(date, worker, isEmployee)
            } else {
                let dates = null
                if (dateRange) {
                    dates = [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD')]
                }
                network.getWorkList(page, items, '', job, dates, minus)
            }
        } else {
            this.setState({
                works: fixedWorks,
                fetching: false,
            })
        }
    }

    invert(selected, works) {
        let inverted = []
        for (let i in works) {
            let work = works[i]
            let id = `${work.registrationDate}_${work.jobCode}_${work.workerId}`
            if (!(id in selected)) {
                inverted.push(work)
            }
        }
        return inverted
    }

    select(work, skipChange) {
        let { onChange, invertSelection, fixedWorks } = this.props
        let {
            selected,
            works: { data: works },
        } = this.state

        if (fixedWorks) {
            works = fixedWorks
        }

        let id = `${work.registrationDate}_${work.jobCode}_${work.workerId}`
        if (selected[id]) {
            delete selected[id]
        } else {
            selected[id] = work
        }

        if (!skipChange && onChange) {
            if (invertSelection) {
                onChange(this.invert(selected, works))
            } else onChange(selected)
        }

        this.setState({ selected })
    }

    startDelete() {
        this.setState({
            deleting: true,
        })
    }

    endDelete() {
        this.setState({
            deleting: false,
        })
    }

    doDelete() {
        let { selected } = this.state
        let { network, isEmployee, onDelete } = this.props
        /*
		for (let i in selected) {
			let work = selected[i];
			network.deleteWork(
				{
					workerId: work.workerId,
					jobCode: work.jobCode,
					registrationDate: moment(work.registrationDate).format('YYYY-MM-DD HH:mm:ss')
				},
				!!isEmployee
			);
		}*/
        let toDelete = []
        for (let i in selected) {
            let work = selected[i]
            toDelete.push({
                workerId: work.workerId,
                jobCode: work.jobCode,
                registrationDate: moment(work.registrationDate).format('YYYY-MM-DD HH:mm:ss'),
            })
        }

        network.deleteWorkList(toDelete, isEmployee)
        if(onDelete) {
            onDelete(toDelete);
        }
    }

    createJobAndAssign() {
        this.setState({
            showCreationDialog: true,
        })
    }

    doAssign() {
        let { selected, jobToAssign } = this.state
        let { network } = this.props
        let works = []

        for (let i in selected) {
            works.push(selected[i])
        }

        network.assignWork(works, jobToAssign)
    }

    closeModal() {
        this.setState({
            showCreationDialog: false,
        })
    }

    render() {
        let { isEmployee, job, worker, date, fixedWorks, compact, childProps, controls, ...rest } = this.props
        let {
            works: { data: works },
            deletingWork,
            deleting,
        } = this.state
        let content = <div />

        if (fixedWorks) {
            works = fixedWorks
        }

        let totalworkerhours = 0
        let totalmachinehours = 0
        let workerTimeObject = {}
        let machineTimeObject = {}
        if (works && isEmployee) {
            for (let work of works) {
                totalworkerhours += work.workerHours + work.workerMinutes / 60
                totalmachinehours += work.machineHours + work.machineMinutes / 60
            }
        }
        workerTimeObject = floatTimeToObject(totalworkerhours)
        machineTimeObject = floatTimeToObject(totalmachinehours)

        /*
		if(compact) {
			direction = {
				row: true,
				wrap: true
			};
		} else {
			direction = {
				column: true
			};
		}
*/
        if (this.state.fetching || deletingWork) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (works) {
            return (
                <View fullw fullh column>
                    <Confirmation
                        showModal={deleting}
                        message="Sicuro di voler eliminare i lavori? Questa azione non e{'\''} reversibile."
                        onConfirm={() => {
                            this.doDelete()
                            this.endDelete()
                        }}
                        onCancel={() => {
                            this.endDelete()
                        }}
                    />

                    {controls && <MenuBar toolbar={this.state.toolbar} />}
                    <View
                        style={{
                            height: this.props.fullh ? '100%' : 'calc(100% - 100px)',
                            overflowY: compact ? 'hidden' : 'scroll',
                        }}
                        fullw
                        column
                    >
                        <CustomTable
                            {...rest}
                            {...{ compact, childProps }}
                            nolabel={true}
                            name="Lavori"
                            shorthand="work"
                            values={works}
                            child={Work}
                            required="registrationDate"
                            selected={(work) => {
                                let id = `${work.registrationDate}_${work.jobCode}_${work.workerId}`
                                let bool = id in this.state.selected
                                if (this.props.invertSelection) {
                                    bool = !bool
                                }
                                return bool
                            }}
                            onRowClick={(work) => {
                                this.select(work)
                            }}
                        />
                        {isEmployee && date && (
                            <View style={{ minHeight: 103 }} fullw row around>
                                <Box alignItems="center" style={{ marginBottom: 0, alignSelf: 'center' }}>
                                    <Box fontSize="lg" textAlign={['center', 'center']}>Totale ore operaio</Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{workerTimeObject.hstring}</Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{workerTimeObject.mstring}</Box>
                                </Box>
                                <Box alignItems="center" style={{ alignSelf: 'center' }}>
                                    <Box fontSize="lg" textAlign={['center', 'center']}>Totale ore macchina</Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{machineTimeObject.hstring}</Box>
                                    <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{machineTimeObject.mstring}</Box>
                                </Box>
                            </View>
                        )}
                    </View>
                </View>
            )
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        getWorkList,
        getWorkListForDate,
        assignWork,
        search,
        organization,
        createWork: { data: createResult, fetching: creatingWork, status: createStatus },
        deleteWorkList: { data: deleteResult, fetching: deletingWork, status: deleteStatus, request_data: deleteRequest },
    } = state
    let { date } = ownProps
    let works = date ? getWorkListForDate.data : getWorkList.data

    return {
        works,
        fetching: date ? getWorkListForDate.fetching : getWorkList.fetching,
        assignWork,
        search,
        organization,
        createResult,
        creatingWork,
        createStatus,
        deleteResult,
        deletingWork,
        deleteStatus,
        deleteRequest,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Works)))
