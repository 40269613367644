import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Empty, WorkOrder } from '../components'

const { app, workorders, newEl, workorderEdit } = routes

class WorkOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteWorkOrder } = this.state
        let { organization } = this.props
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteWorkOrder.fetching !== prevState.deleteWorkOrder.fetching && !deleteWorkOrder.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getWorkOrderList(this.state.search.data)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getWorkOrderList(this.state.search.data)
            this.setState({ page: 0 })
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { sub } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_workorder) {
            extraTools.push({
                name: 'Crea nuova collezione',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, workorders, newEl, workorderEdit))
                },
            })
        }

        if (!sub) {
            this.props.toolbarResult([
                ...extraTools,
                // Not paginated yet
                // {
                // 	name: '',
                // 	icon: 'arrow left',
                // 	position: 'right',
                // 	action: () => {
                // 		this.prev()
                // 	}
                // },
                // {
                // 	name: '',
                // 	icon: 'arrow right',
                // 	position: undefined,
                // 	action: () => {
                // 		this.next()
                // 	}
                // }
            ])
        }

        this.state.network.getWorkOrderList(this.state.search.data)
    }

    renderWorkOrder(key, workorder) {
        let { selected } = this.state
        let { onChange, selectable, noActions } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (workorder) => {
                if (onChange) {
                    onChange(workorder)
                }
                this.setState({ selected: workorder })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return (
            <WorkOrder
                {...extraProps}
                selected={workorder.workorderId === selected.workorderId}
                type="full"
                key={`workorder_${key}`}
                workorder={workorder}
            />
        )
    }

    render() {
        let { workorders, fetching } = this.state
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (workorders && workorders.data && workorders.data.length > 0) {
            content = Object.keys(workorders.data).map((key) => {
                let value = workorders.data[key]
                return this.renderWorkOrder(key, value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View noflex fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { getWorkOrderList, deleteWorkOrder, search, organization, login } = state

    let workorderList = getWorkOrderList

    return {
        workorders: workorderList.data,
        fetching: workorderList.fetching,
        deleteWorkOrder,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WorkOrders)))

