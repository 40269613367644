import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import SummarySheet from '../job/SummarySheet'
import { mapOrdToOrdering } from '../lib/util'
import MoldDropdown from '../utility/MoldDropdown'

const { app, molds, moldEdit } = routes

class Mold extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, mold } = props

        this.state = {
            deleting: false,
            ...mold,
            moldMinutes: mold ? parseFloat(mold.moldMinutes) : 0,
        }

        if (onCostChange && mold) {
            onCostChange(this.totalCost(), mold.moldCode)
        }
    }

    totalCost() {
        let { moldPrice } = this.state
        let total = moldPrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { mold, moldCode, network } = this.props

        if ((!mold || !mold.moldCode) && moldCode) {
            network.getMold(moldCode)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { sorts, onMoldCodeHeaderClick, hideMold, hideMoldDescription, hideMoldSupplier, noActions, hideBarcode, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideMold && (
                            <Table.HeaderCell
                                {...(sorts ? { sorted: sorts.moldCode ? mapOrdToOrdering[sorts.moldCode] : null } : {})}
                                onClick={onMoldCodeHeaderClick ? onMoldCodeHeaderClick : () => {}}
                            >
                                Codice
                            </Table.HeaderCell>
                        )}
                        {!hideMoldDescription && <Table.HeaderCell>Descrizione</Table.HeaderCell>}
                        {!hideBarcode && <Table.HeaderCell>Codice a barre</Table.HeaderCell>}
                        {!noActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideMold, hideMoldDescription, hideBarcode, isBalance, noActions, onRowClick } = this.props
        let { moldCode, moldDescription, moldPercent, moldPrice, moldSupplier, moldSupplierId } = this.state

        setImmediate(() => {
            try {
                const text = moldCode
                JsBarcode(`#moldbarcode${moldCode}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } catch (e) {
                console.error(e)
            }
        })

        if (isBalance) {
            return this.renderForBalance()
        } else {
            // console.log("Render table body mold", noActions);
            return (
                <Table.Row>
                    {!hideMold && <Table.Cell>{moldCode}</Table.Cell>}
                    {!hideMoldDescription && <Table.Cell>{moldDescription}</Table.Cell>}
                    {!hideBarcode && (
                        <Table.Cell>
                            <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                <svg id={`moldbarcode${moldCode}`}></svg>
                            </div>
                        </Table.Cell>
                    )}
                    {!noActions && (
                        <Table.Cell>
                            <SummarySheet type="mold" objectToSerialize={this.state} />
                            {/* <Button
                                icon
                                color="yellow"
                                onClick={(e) => {
                                    this.setState({
                                        mergingMold: true,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="exchange" />
                            </Button> */}
                            <Button
                                icon
                                color="blue"
                                onClick={(e) => {
                                    if (onRowClick) {
                                        onRowClick(this.state)
                                    }
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="edit" />
                            </Button>
                            <Button
                                icon
                                color="red"
                                onClick={(e) => {
                                    this.props.network.deleteMold({
                                        moldCode,
                                        moldDescription,
                                        moldPercent,
                                        moldPrice,
                                        moldSupplier,
                                        moldSupplierId,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="remove" />
                            </Button>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon color="grey">
                                            <Icon name="print" />
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            />
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, mold } = this.props

        network.deleteStdMold(mold)

        this.setState({ deleting: false })
    }

    render() {
        let { mergeDestinations, mergingMold, moldCode } = this.state
        let { login, type, mold, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(mold)
            }
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = <Box classDescription={selected ? 'rowSelected' : ''} style={styles.moldCard} {...extraprops} />
                break
        }

        return (
            <>
                <Modal key={`modal_mold_${moldCode}`} closeIcon open={!!mergingMold} onClose={() => this.setState({ mergingMold: false })}>
                    <Modal.Header>Unendo stampo {moldCode} ad un altro</Modal.Header>
                    <Modal.Content>
                        <MoldDropdown
                            selected={mergeDestinations}
                            onChange={(ev, data, selected, molds) => {
                                this.setState({
                                    mergeDestinations: molds,
                                })
                            }}
                            onlySelection
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            color="red"
                            onClick={() => {
                                this.props.network.mergeMold({
                                    sourceId: this.state.moldCode,
                                    destinationId: this.state.mergeDestinations[0].moldCode,
                                })
                                this.setState({ mergingMold: false })
                            }}
                            disabled={
                                !this.state.mergeDestinations ||
                                this.state.mergeDestinations.length !== 1 ||
                                this.state.moldCode === this.state.mergeDestinations[0].moldCode
                            }
                        >
                            Effettua unione AZIONE IRREVERSIBILE, VERRA{'\''} ELIMINATO LO STAMPO {'"'}{this.state.moldDescription}{'"'} ({this.state.moldCode}) e
                            tutte le informazioni collegate!
                        </Button>
                    </Modal.Content>
                </Modal>
                {content}
            </>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    moldImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    moldContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    moldCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getMold } = state
    let rmoldCode = null
    if (login && login.data && login.data.user) rmoldCode = login.data.user.rmoldCode

    return { role: rmoldCode, getMold, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Mold)))
