import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import moment from 'moment'
import 'moment/locale/it'
import { baseViewDispatch, getRandomColorDark, hadesApi, stringToColor } from '../lib/util'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import { connectNetwork } from 'lib/NetworkProvider'
import { Box } from '@chakra-ui/react'

const Ind40Charts = ({ machine, selectedMonth }) => {
    const [chartData, setChartData] = useState([])

    const code = '0'
    const lineId = machine.machineHadesRef

    useEffect(() => {
        fetchData(selectedMonth)
    }, [selectedMonth])

    const fetchData = async (month) => {
        const startDate = moment(month).startOf('month').toISOString()
        const endDate = moment(month).endOf('month').toISOString()

        const filters = [
            {
                key: 'timestamp',
                symbol: '>',
                value: startDate,
            },
            {
                key: 'timestamp',
                symbol: '<',
                value: endDate,
            },
        ]

        try {
            let response = await hadesApi.get(`${process.env.REACT_APP_HADES}table/line/${lineId}`, {
                params: {
                    id: `${code}`,
                    sub_id: '0',
                    filters,
                },
            })
            processData(response.data)
        } catch (error) {
            console.error('Error fetching data: ', error)
        }
    }

    const processData = (apiData) => {
        const formattedData = apiData.map((item) => {
            // Convert timestamp to Italian date format
            const formattedTimestamp = moment(item.timestamp).locale('it').format('L LT')

            const newobject = {
                timestamp: formattedTimestamp,
            }

            for (const key in item) {
                if (typeof item[key] === 'number') {
                    newobject[key] = item[key]
                }
            }

            return newobject
        })

        setChartData(formattedData)
    }

    const series = []
    if (chartData && chartData.length > 0) {
        for (const key in chartData[0]) {
            if (key !== 'timestamp') {
                series.push({
                    key,
                    color: stringToColor(key),
                })
            }
        }
    }

    return (
        <Box borderWidth="1px" borderRadius="lg" p="4" overflow="hidden">
            <h1>Data Chart {machine.machineName}</h1>
            <LineChart width={1820} height={900} data={chartData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                {series.map((value, index) => {
                    return <Line key={`${lineId}_series_${index}_${value.key}`} type="monotone" dataKey={value.key} stroke={value.color} />
                })}
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
                <Legend />
            </LineChart>
        </Box>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList: {
            fetching: fetchMachines,
            data: { data: machines },
        },
    } = state

    return {
        fetchMachines,
        machines,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Ind40Charts)))
