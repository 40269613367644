export function getWorkOrderDetailed(data, isEmployee) {
    return new Promise((resolve, reject) => {
        let { getWorkOrderDetailedRequest, getWorkOrderDetailedError, getWorkOrderDetailedResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_workorder_detailed',
            this.createBundle(
                'get_workorder_detailed',
                { workorder: data },
                this.GET,
                getWorkOrderDetailedRequest,
                (result) => {
                    getWorkOrderDetailedResult(result)
                    resolve(result)
                },
                (error) => {
                    getWorkOrderDetailedError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
