import { Box } from '@chakra-ui/layout'
import {} from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import SummarySheet from './SummarySheet'
import { mapOrdToOrdering } from '../lib/util'

const { app, listings, listingEdit } = routes

class Listing extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, listing } = props

        this.state = {
            deleting: false,
            ...listing,
            listingMinutes: listing ? parseFloat(listing.listingMinutes) : 0,
        }

        if (onCostChange && listing) {
            onCostChange(this.totalCost(), listing.listingId)
        }
    }

    totalCost() {
        let { listingPrice } = this.state
        let total = listingPrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { listing, listingId, network } = this.props

        if ((!listing || !listing.listingId) && listingId) {
            network.getListing(listingId)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let {
            onListingCodeHeaderClick,
            hideListing,
            hideListingName,
            hideListingUnitPrice,
            hideListingMeasurement,
            isBalance,
            hideActions,
            hideBarcode,
            onRowClick,
            sorts,
        } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideListing && (
                            <Table.HeaderCell
                                {...(sorts ? { sorted: sorts.listingCode ? mapOrdToOrdering[sorts.listingCode] : null } : {})}
                                onClick={onListingCodeHeaderClick ? onListingCodeHeaderClick : () => {}}
                            >
                                Listino
                            </Table.HeaderCell>
                        )}
                        {!hideListingName && <Table.HeaderCell>Nome</Table.HeaderCell>}
                        {!hideActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let {
            hideListing,
            hideListingName,
            isBalance,
            hideActions,
        } = this.props
        let { listingId, listingCode, listingName } = this.state

        setImmediate(() => {
            try {
                const text = listingCode
                JsBarcode(`#matbarcode${listingCode}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } catch (e) {
                console.error(e)
            }
        })

        let scanned = this.props.scannedListings ? this.props.scannedListings.includes(listingCode) : false

        // console.log('Listino is', scanned)
        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row style={{ backgroundColor: scanned ? 'green' : 'white' }}>
                    {!hideListing && <Table.Cell>{listingId}</Table.Cell>}
                    {!hideListingName && <Table.Cell>{listingName}</Table.Cell>}
                    {!hideActions && this.state.listingId && (
                        <Table.Cell className="no-print">
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, listing } = this.props

        network.deleteStdListing(listing)

        this.setState({ deleting: false })
    }

    render() {
        let { mergeDestinations, mergingListing, listingId } = this.state
        let { login, type, listing, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(listing)
            }
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = (
                    <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        p="4"
                        overflow="hidden"
                        className={selected ? 'rowSelected' : ''}
                        style={styles.listingCard}
                        {...extraprops}
                    />
                )
                break
        }

        return <>{content}</>
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    listingImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    listingContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    listingCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getListing } = state
    let rlistingId = null
    if (login && login.data && login.data.user) rlistingId = login.data.user.rlistingId

    return { role: rlistingId, getListing, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Listing)))
