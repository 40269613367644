export function getPackageList(data, filters, sorts) {
    return new Promise((resolve, reject) => {
        let { getPackageListRequest, getPackageListError, getPackageListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_package_list',
            this.createBundle(
                'get_package_list',
                { package: data, filters, sorts },
                this.GET,
                getPackageListRequest,
                (result) => {
                    getPackageListResult(result)
                    resolve(result)
                },
                (error) => {
                    getPackageListError(error)
                    reject(error)
                }
            )
        )
    })
}
