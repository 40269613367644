import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder } from 'semantic-ui-react'
import { Empty, Worker } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'

const { app, employees, newEl, workerEdit } = routes

class Workers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteWorker } = this.state
        let { organization } = this.props
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteWorker.fetching !== prevState.deleteWorker.fetching && !deleteWorker.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getWorkerList(this.state.search.data)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getWorkerList(this.state.search.data)
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_job) {
            extraTools.push({
                name: 'Crea nuovo impiegato',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, employees, newEl, workerEdit))
                },
            })
        }

        initializePage(this, [
            ...extraTools,
            // Not paginated yer
            // {
            // 	name: '',
            // 	icon: 'arrow left',
            // 	position: 'right',
            // 	action: () => {
            // 		this.prev()
            // 	}
            // },
            // {
            // 	name: '',
            // 	icon: 'arrow right',
            // 	position: undefined,
            // 	action: () => {
            // 		this.next()
            // 	}
            // }
        ], {})
        this.state.network.getWorkerList(this.state.search.data)
    }

    renderWorker(key, worker) {
        return <Worker type="full" key={`worker_${worker.workerId}`} worker={worker} />
    }

    render() {
        let content = <div />

        if (this.state.fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box style={{ width: '100%' }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (this.state.workers.data && this.state.workers.data.length > 0) {
            content = this.state.workers.data.map((value, index) => {
                return this.renderWorker(index, value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getWorkerList, search, deleteWorker, organization, login } = state

    return {
        workers: getWorkerList.data,
        fetching: getWorkerList.fetching,
        deleteWorker,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Workers)))
