import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Header, Popup, Table } from 'semantic-ui-react'
import { ImageDefault } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'
function MoldMappingTable(props) {
    const { isEmployee, network } = props
    const [mapping, setMapping] = useState({ width: 0, height: 0, mapping: {} })
    const [dirty, setDirty] = useState(false)
    const componentRef = useRef()

    useEffect(() => {
        network.getMappingData(!!isEmployee).then((data) => {
            const mappingData = data.data
            const mapping = []
            let width = 0
            let height = 0

            for (const key in mappingData) {
                const mold = mappingData[key]
                if (mold.column > width) {
                    width = mold.column
                }

                if (mold.row > height) {
                    height = mold.row
                }

                if (!mapping[mold.row]) mapping[mold.row] = {}

                mapping[mold.row][mold.column] = mold
            }

            for (let i = 1; i <= height; i++) {
                if (!mapping[i]) {
                    mapping[i] = {}
                }

                for (let j = 1; j <= width; j++) {
                    if (!mapping[i][j]) {
                        mapping[i][j] = null
                    }
                }
            }

            setMapping({ width, height, mapping })
            setDirty(false)
        })
    }, [dirty])

    useEffect(() => {
        initializePage(
            props,
            [
                {
                    name: 'Stampa',
                    icon: 'print',
                    position: 'right',
                    action: () => {},
                },
            ],
            []
        )
    }, [])

    const columnHeader = []
    columnHeader.push(<Table.HeaderCell></Table.HeaderCell>)
    for (let i = 0; i < mapping.width; i++) {
        columnHeader.push(<Table.HeaderCell style={{ textAlign: 'center' }}>{i + 1}</Table.HeaderCell>)
    }

    const rowHeader = []
    rowHeader.push(<Table.HeaderCell></Table.HeaderCell>)
    for (let i = 0; i < mapping.height; i++) {
        rowHeader.push(<Table.Cell style={{ textAlign: 'center' }}>{i + 1}</Table.Cell>)
    }

    return (
        <>
            <ReactToPrint trigger={() => <button>Stampa</button>} content={() => componentRef.current} />
            <div ref={componentRef}>
                <Table celled definition>
                    <Table.Header>{columnHeader}</Table.Header>
                    {Object.keys(mapping.mapping).map((i) => {
                        const rowContent = Object.keys(mapping.mapping[i]).map((j) => {
                            const mold = mapping.mapping[i][j]
                            if (mold) {
                                return (
                                    <Table.Cell>
                                        <Popup
                                            content={<ImageDefault fromRoot src={mold.image} rounded size="large" />}
                                            trigger={
                                                <Header as="h4" image>
                                                    <ImageDefault fromRoot src={mold.image} rounded size="small" />
                                                    <Header.Content>
                                                        {mold.jobCode}
                                                        <Header.Subheader>{mold.client}</Header.Subheader>
                                                    </Header.Content>
                                                </Header>
                                            }
                                        />
                                    </Table.Cell>
                                )
                            } else {
                                return <Table.Cell style={{ backgroundColor: 'grey' }}></Table.Cell>
                            }
                        })
                        return <Table.Row key={`mold-mapping-outer-${i}`}>{[rowHeader[i], ...rowContent]}</Table.Row>
                    })}
                </Table>
            </div>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MoldMappingTable)))
