import { connectNetwork } from '../lib/NetworkProvider'
import { routes, route_from } from '../lib/routes'
import View from '../lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch, createAlert } from '../lib/util'
import { Label } from '../components'
import { Draggable } from 'leaflet'
import DraggableText from '../labeling/DraggableText'
import { Segment, Form, Select } from 'semantic-ui-react'
import DraggableBarcode from '../labeling/DraggableBarcode'

moment.locale('it')

const { app, labels } = routes

class LabelEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id

        let { label } = this.props.match.params

        if (!id) {
            id = label
        }

        this.state = {
            updating: false,
            id,
            labelId: id,
        }
    }

    componentDidMount() {
        let { labelId } = this.state
        let { sub, network } = this.props

        if (!sub) {
            this.props.toolbarResult([
                {
                    name: 'Salva',
                    icon: 'save',
                    position: 'right',
                    action: () => {
                        this.save()
                    },
                },
            ])
        }

        if (labelId) {
            network.getLabel(labelId)
            network.getCustomerList()
            network.getCategoList()
            network.getPackageList()
            this.setState({ updating: true })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { success, error } = this.props

        if (this.props.creatingLabel !== prevProps.creatingLabel) {
            if (!this.props.creatingLabel) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    success(
                        createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Visualizza', () => {
                            this.props.history.push(route_from(app, labels, `${this.state.finalLabelCode}`))
                        })
                    )
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    error(createAlert('Errore del server', 'Riprova piú tardi'))
                    this.setState({
                        status: 'error',
                        errorMessage: '',
                    })
                }
            }
        }

        let { getLabel } = this.props
        if (getLabel && getLabel.fetching !== prevProps.getLabel.fetching && !getLabel.fetching) {
            if (getLabel.status.success) {
                let label = getLabel.data.data
                this.setState(label)
            }
        }
    }

    save() {
        let { id, labelId, layoutId, packageId, customerId, articleId, labelName, labelDescription, layout, customer, pack, article } = this.state

        let { warning } = this.props

        this.props.network.createLabel({
            labelId,
            layoutId,
            packageId,
            customerId,
            articleId,
            labelName,
            labelDescription,
            layout,
            customer,
            pack,
            article,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.obj === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })

        let { onChange } = this.props
        if (onChange) {
            let { labelId, layoutId, packageId, customerId, articleId, labelName, labelDescription } = this.state
            onChange({
                labelId,
                layoutId,
                packageId,
                customerId,
                articleId,
                labelName,
                labelDescription,
            })
        }
    }

    render() {
        let { id, labelId, layoutId, packageId, customerId, articleId, labelName, labelDescription, layout, customer, pack, article } = this.state

        const { packageoptions, customeroptions, artioptions, fetching, fetchArtis, fetchCustomers, customers } = this.props

        const label = {
            labelId,
            layoutId,
            packageId,
            customerId,
            articleId,
            labelName,
            labelDescription,
            layout,
            customer,
            pack,
            article,
        }

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <View fullw column>
                    <Form
                        loading={fetching || fetchArtis || fetchCustomers}
                        success={this.state.status === 'success'}
                        error={this.state.status === 'error'}
                        warning={this.state.status === 'warning'}
                    >
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-package"
                                control={Select}
                                search
                                options={packageoptions}
                                label="Confezione a cui fa riferimento (Opzionale)"
                                placeholder="Confezione a cui fa riferimento (Opzionale)"
                                name="packageId"
                                value={packageId}
                                onChange={(e, data) => {
                                    let id = data.value

                                    this.setState({ packageId: id })
                                }}
                            />
                            <Form.Field
                                id="form-input-control-customer"
                                control={Select}
                                search
                                options={customeroptions}
                                label="Cliente a cui fa riferimento (Opzionale)"
                                placeholder="Cliente a cui fa riferimento (Opzionale)"
                                name="customerId"
                                value={customerId}
                                onChange={(e, data) => {
                                    let id = data.value

                                    this.setState({ customerId: id })
                                }}
                            />
                            <Form.Field
                                id="form-input-control-arti"
                                control={Select}
                                search
                                options={artioptions}
                                label="ARTI a cui fa riferimento (Opzionale)"
                                placeholder="ARTI a cui fa riferimento (Opzionale)"
                                name="articleId"
                                value={articleId}
                                onChange={(e, data) => {
                                    let id = data.value

                                    this.setState({ articleId: id })
                                }}
                            />
                        </Form.Group>
                    </Form>
                </View>
                <View fullw fullh row>
                    <View fullw fullh column style={{ marginRight: 32, overflowX: 'hidden', overflowY: 'scroll' }}>
                        <Segment style={{ padding: 64, marginRight: 16 }}>
                            <DraggableText
                                key={'articleName'}
                                layoutElementStyle={{
                                    textAlign: 'center',
                                    width: 400,
                                    fontSize: 48,
                                    paddingTop: 25,
                                    fontWeight: 'bold',
                                }}
                                value={{
                                    layoutElementId: 0,
                                    layoutElementName: 'articleName',
                                    layoutElementValue: '{{articleName}}',
                                    layoutElementType: 1,
                                    layoutElementX: 0,
                                    layoutElementY: 0,
                                    isNewElement: true,
                                }}
                                isPreview
                            />
                        </Segment>
                        <Segment style={{ padding: 64, marginRight: 16 }}>
                            <DraggableText
                                key={'nrDoc'}
                                layoutElementStyle={{
                                    width: 200,
                                    textAlign: 'left',
                                    fontSize: 22,
                                    fontWeight: 'bold',
                                    transform: 'rotate(90deg) translate(63px, 0px)',
                                }}
                                value={{
                                    layoutElementId: 0,
                                    layoutElementName: 'nrDoc',
                                    layoutElementValue: 'Nr.Doc.',
                                    layoutElementType: 1,
                                    layoutElementX: 0,
                                    layoutElementY: 0,
                                    isNewElement: true,
                                }}
                                isPreview
                            />
                        </Segment>
                        <Segment style={{ padding: 64, marginRight: 16 }}>
                            <DraggableText
                                key={'codice'}
                                layoutElementStyle={{ width: 80, textAlign: 'center', fontSize: 18 }}
                                value={{
                                    layoutElementId: 0,
                                    layoutElementName: 'codice',
                                    layoutElementValue: 'Codice:',
                                    layoutElementType: 1,
                                    layoutElementX: 0,
                                    layoutElementY: 0,
                                    isNewElement: true,
                                }}
                                isPreview
                            />
                        </Segment>
                        <Segment style={{ minHeight: 300, padding: 64, marginRight: 16 }}>
                            <DraggableBarcode
                                key={'articleCode'}
                                layoutElementStyle={{
                                    width: 2,
                                    textAlign: 'left',
                                    fontSize: 22,
                                    fontWeight: 'bold',
                                    transform: 'rotate(90deg) translate(80px, 80px)',
                                }}
                                value={{
                                    layoutElementId: 0,
                                    layoutElementName: 'articleCode',
                                    layoutElementValue: '{{articleCode}}',
                                    layoutElementType: 3,
                                    layoutElementX: 0,
                                    layoutElementY: 0,
                                    isNewElement: true,
                                }}
                                isPreview
                            />
                        </Segment>
                        <Segment style={{ padding: 64, marginRight: 16 }}>
                            <DraggableBarcode
                                key={'articleCode'}
                                layoutElementStyle={{
                                    width: 2,
                                    height: 50,
                                    format: 'CODE128',
                                    displayValue: true,
                                    fontOptions: '',
                                    font: 'monospace',
                                    textAlign: 'center',
                                    textPosition: 'bottom',
                                    textMargin: 2,
                                    fontSize: 20,
                                    background: '#ffffff00',
                                    lineColor: '#000000',
                                    margin: 10,
                                    marginTop: '0',
                                    marginBottom: '0',
                                    marginLeft: '0',
                                    marginRight: '0',
                                }}
                                value={{
                                    layoutElementId: 0,
                                    layoutElementName: 'articleCode',
                                    layoutElementValue: '{{articleCode}}',
                                    layoutElementType: 3,
                                    layoutElementX: 0,
                                    layoutElementY: 0,
                                    isNewElement: true,
                                }}
                                isPreview
                            />
                        </Segment>
                    </View>
                    <View fullw column>
                        <Label type="edit" label={label} />
                    </View>
                </View>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        getCustomerList,
        getCustomerList: {
            data: { data: customers },
            fetching: fetchCustomers,
        },
        getCategoList: {
            data: { data: artis },
            fetching: fetchArtis,
        },
        getPackageList: {
            data: { data: packages },
            fetching,
        },
        createLabel: { data: createResult, fetching: creatingLabel, status: createStatus },
        getLabel,
    } = state

    let packageoptions = []
    for (let i in packages) {
        let obj = packages[i]
        packageoptions.push({
            key: obj.packageId,
            text: `${obj.packageName}: ${obj.packageDescription}`,
            value: obj.packageId,
        })
    }

    let artioptions = []
    for (let i in artis) {
        let obj = artis[i]
        artioptions.push({
            key: obj.codice,
            text: `${obj.codice}: ${obj.descrizio}`,
            value: obj.codice,
        })
    }

    let customeroptions = []
    for (let i in customers) {
        let obj = customers[i]
        customeroptions.push({
            key: obj.customerId,
            text: obj.customerName,
            value: obj.customerId,
        })
    }

    packageoptions.push({
        key: 0,
        text: 'Nessun riferimento a prodotto',
        value: false,
    })
    artioptions.push({
        key: 0,
        text: 'Nessun riferimento a prodotto',
        value: false,
    })
    customeroptions.push({
        key: 0,
        text: 'Nessun riferimento a prodotto',
        value: false,
    })

    return {
        getCustomerList,
        packages,
        customers,
        artis,
        packageoptions,
        customeroptions,
        artioptions,
        fetchCustomers,
        fetchArtis,
        fetching,
        createResult,
        creatingLabel,
        createStatus,
        getLabel,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(LabelEditor)))
