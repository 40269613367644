import { Box, HStack, Input, InputGroup, InputLeftAddon, VStack } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { AsyncPaginate } from 'react-select-async-paginate'

function DropdownSearch({
    entity = 'Mold',
    keyfield = 'moldCode',
    descriptionfield = 'moldDescription',
    placeholder = 'Seleziona stampo',
    isMulti,
    isSearchable,
    network,
    value,
    onChange,
    singleValueInitialDescription,
}) {
    const [selected, setSelected] = useState([])
    const [dirty, setDirty] = useState(false)
    const lcEntity = entity.toLowerCase()

    useEffect(() => {
        if (isMulti && value && value.length && !selected.length) {
            const arr = []
            for (const element of value) {
                arr.push(element)
            }
            setSelected(arr)
            setDirty(!dirty)
        } else if (!isMulti && value && (!selected || selected.length === 0)) {
            setSelected([{ [keyfield]: value, [descriptionfield]: singleValueInitialDescription ? singleValueInitialDescription : value }])
        }
    }, [value])

    useEffect(() => {}, [])

    const loadOptions = async (searchQuery, loadedOptions, { page }) => {
        const response = await network[`get${entity}List`](searchQuery, false, [], [], page, 10)
            .then((data) => {
                const elementlist = data.data
                const elementobj = {}

                let elementOptions = []
                for (let i in elementlist) {
                    let element = elementlist[i]
                    elementobj[element[`${keyfield}`]] = element
                    elementOptions.push(element)
                }

                return elementOptions
            })
            .catch((e) => {
                return []
            })

        return {
            options: response,
            hasMore: response.length >= 10,
            additional: {
                page: searchQuery ? 2 : page + 1,
            },
        }
    }

    return (
        <Box w="100%">
            <AsyncPaginate
                key={`${entity}_${isMulti}_dropdown`}
                value={selected}
                loadOptions={loadOptions}
                getOptionValue={(option) => option[`${keyfield}`]}
                getOptionLabel={(option) => `${option[`${keyfield}`]} - ${option[`${descriptionfield}`]}`}
                onChange={(value) => {
                    /* eslint-disable */
                    console.log('On change value is', value)
                    if (isMulti) {
                        setSelected(value)
                        if (onChange) {
                            onChange(value)
                        }
                    } else {
                        setSelected([value])
                        if (onChange) {
                            onChange([value])
                        }
                    }
                    setDirty(!dirty)
                }}
                isMulti={isMulti}
                isSearchable={isSearchable}
                placeholder={placeholder}
                additional={{
                    page: 0,
                }}
            />

            {isMulti &&
                selected &&
                selected.map &&
                selected.map((value, index) => {
                    let content = null
                    switch (entity) {
                        case 'Machine':
                            content = !value[`${keyfield}`] ? null : (
                                <HStack w="100%">
                                    <InputGroup>
                                        <InputLeftAddon children={`Ricetta su macchina ${value.machineName}`} />
                                        <DropdownSearch
                                            isMulti={false}
                                            onChange={(recipe) => {
                                                value.recipeId = recipe[0].recipeId
                                            }}
                                            value={value.recipeId}
                                            entity={'Recipe'}
                                            isSearchable={true}
                                            placeholder="Seleziona Ricetta"
                                            keyfield="recipeId"
                                            descriptionfield="recipeName"
                                            network={network}
                                        />
                                    </InputGroup>
                                </HStack>
                            )
                            break
                        case 'Master':
                            content = !value[`${keyfield}`] ? null : (
                                <HStack w="100%">
                                    <InputGroup>
                                        <InputLeftAddon children={`Percentuale Master ${value.masterName}`} />
                                        <Input
                                            id={`form-input-control-master-percent-${index}`}
                                            name="masterPercent"
                                            type="number"
                                            step="0"
                                            value={value.masterPercent}
                                            onChange={(e) => {
                                                value.masterPercent = e.target.value
                                                setDirty(!dirty)
                                            }}
                                            placeholder=""
                                        />
                                    </InputGroup>
                                </HStack>
                            )
                            break
                        case 'Material':
                            content = !value[`${keyfield}`] ? null : (
                                <HStack w="100%">
                                    {/* <InputGroup>
                                        <InputLeftAddon children={`Percentuale Materiale ${value.materialDescription}`} />
                                        <Input
                                            id={`form-input-control-material-percent-${index}`}
                                            name="materialPercent"
                                            type="number"
                                            step="0"
                                            value={value.materialPercent}
                                            onChange={(e) => {
                                                value.materialPercent = e.target.value
                                                setDirty(!dirty)
                                            }}
                                            placeholder=""
                                        />
                                    </InputGroup> */}
                                </HStack>
                            )
                            break
                        case 'Mold':
                            content = !value[`${keyfield}`] ? null : (
                                <HStack w="100%">
                                    <InputGroup>
                                        <InputLeftAddon children={`Tempo ciclo ${value[`${keyfield}`]}`} />
                                        <Input
                                            id={`form-input-control-mold-cycle-${index}`}
                                            name="cycleTime"
                                            type="number"
                                            step="0.01"
                                            value={value.cycleTime ? value.cycleTime : 40}
                                            onChange={(e) => {
                                                value.cycleTime = e.target.value
                                                setDirty(!dirty)
                                            }}
                                            placeholder=""
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputLeftAddon children={`Pezzi per ciclo ${value[`${keyfield}`]}`} />
                                        <Input
                                            id={`form-input-control-mold-pieces-${index}`}
                                            name="piecesPerCycle"
                                            type="number"
                                            value={value.piecesPerCycle ? value.piecesPerCycle : 1}
                                            onChange={(e) => {
                                                value.piecesPerCycle = e.target.value
                                                setDirty(!dirty)
                                            }}
                                            placeholder=""
                                        />
                                    </InputGroup>
                                </HStack>
                            )
                            break
                        case 'Package':
                            content = !value[`${keyfield}`] ? null : (
                                <HStack w="100%">
                                    <InputGroup>
                                        <InputLeftAddon children={`Pezzi per imballo ${value[`${keyfield}`]}`} />
                                        <Input
                                            id={`form-input-control-package-pcs-${index}`}
                                            name="pcsPerPackage"
                                            type="number"
                                            step="0.01"
                                            value={value.pcsPerPackage ? value.pcsPerPackage : 1}
                                            onChange={(e) => {
                                                value.pcsPerPackage = e.target.value
                                                setDirty(!dirty)
                                            }}
                                            placeholder=""
                                        />
                                    </InputGroup>
                                </HStack>
                            )
                            break
                        default:
                            return null
                    }

                    return content ? (
                        <VStack w="100%" mt={4} pl={[4, 32, 64, 128]} pr={[4, 32, 64, 128]} key={`${value[`${keyfield}`]}_${index}`}>
                            {content}
                        </VStack>
                    ) : null
                })}
        </Box>
    )
}

export default withRouter(connectNetwork(DropdownSearch))
