export function getStdArticleList(data, filters, sorts, isEmployee) {
    let { getStdArticleListRequest, getStdArticleListError, getStdArticleListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_article_list',
        this.createBundle(
            'get_article_list',
            { search: data || '', filters, sorts },
            this.POST,
            getStdArticleListRequest,
            getStdArticleListResult,
            getStdArticleListError,
            isEmployee
        )
    )
}
