import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input } from 'semantic-ui-react'

class BlackboxService extends Component {
    constructor(props) {
        super(props)

        let { service } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...service,
        }
    }

    componentDidUpdate() {}

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {

        //network.deleteBlackboxService(service);

        this.setState({ deleting: false })
    }

    save() {
        let { network, blackbox } = this.props
        let { id_service, id_blackbox, name, type: serviceType, path, ip_address, frequency, version, core, install_as_service, ext } = this.state

        network.saveService(blackbox.id_machine, {
            id_service,
            id_blackbox,
            name,
            type: serviceType,
            path,
            ip_address,
            frequency,
            version,
            core,
            install_as_service,
            ext,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    renderContent() {
        let {
            name,
            path,
            ip_address,
            frequency,
        } = this.state

        switch (name) {
            case 'csv_collector':
                return (
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-service-ip"
                                control={Input}
                                label={<span className="label">Indirizzo IP</span>}
                                name="ip_address"
                                value={ip_address}
                                onChange={(e) => this.handleInput(e)}
                                placeholder="127.0.0.1"
                            />
                            <Form.Field
                                id="form-input-control-service-frequency"
                                control={Input}
                                label={<span className="label">Frequenza di aggiornamento</span>}
                                name="frequency"
                                value={frequency}
                                onChange={(e) => this.handleInput(e)}
                                placeholder="127.0.0.1"
                            />
                            <Form.Field
                                id="form-input-control-service-path"
                                control={Input}
                                label={<span className="label">Locazione file</span>}
                                name="path"
                                value={path}
                                onChange={(e) => this.handleInput(e)}
                                placeholder="127.0.0.1"
                            />
                        </Form.Group>
                    </Form>
                )
            default:
                return <View />
        }
    }

    render() {
        let { type, service, onClick } = this.props
        let { name, version } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(service)
            }
        }


        let content = this.renderContent()

        switch (type) {
            default:
                return (
                    <View fullw column between>
                        <span style={styles.header}>
                            {name}@{version}
                        </span>
                        {content}
                        <Button
                            onClick={() => {
                                this.save()
                            }}
                        >
                            Save
                        </Button>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    serviceImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    serviceContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    serviceCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = () => {
    return {}
}

export default withRouter(connect(mapStateToProps)(connectNetwork(BlackboxService)))
