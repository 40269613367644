// import { Chart } from 'react-charts'
import { Box, Flex, Select, Spinner, Text } from '@chakra-ui/react'
import 'moment-duration-format'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { FiSquare } from 'react-icons/fi'
import Sticky from 'react-sticky-el'
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Table } from 'semantic-ui-react'
import { compute_oee } from '../lib/OeeCalc'
import useWindowSize, { breakpoints } from '../lib/useWindowSize'




moment.tz.setDefault('Europe/Rome').locale('it')

const displayTime = (time) => {
    let duration = moment.duration(time, 'seconds')
    return duration.format('hh[h] mm[m] ss[s]')
}

const Recap = ({ allData, selectedCode, point }) => {
    let data = allData[selectedCode] || {}
    if (point !== undefined) data = [data[point]]

    let totalgood = 0
    let totalbad = 0
    let max = 0
    let mean_cycle = 0
    let total_uptime = 0
    let total_downtime = 0

    let total_oee = 0
    let total_rq = 0
    let total_rv = 0
    let total_rf = 0

    let cycles = 0

    for (let i in data) {
        let item = data[i]
        totalgood += item.good
        totalbad += item.bad
        max += item.max_pcs_th_for_uptime ? item.max_pcs_th_for_uptime : 0
        cycles += item.cycles
        // mean_cycle += 3600 / (item.good + item.bad)
        mean_cycle += item.avgcycle
        total_uptime += item.uptime
        total_downtime += item.downtime
        total_oee += item.oee
        total_rq += item.rq
        total_rv += item.rv
        total_rf += item.rf
    }
    let len = Object.keys(data).length
    mean_cycle /= len
    total_oee /= len
    total_rq /= len
    total_rv /= len
    total_rf /= len

    // console.log('Data is', data)

    let desc = 'Giorno'
    if (point !== undefined) desc = `${point} - ${parseInt(point) + 1}`
    return (
        <>
            <Text as="b" color="white">
                OEE {desc}
            </Text>
            <Text color="gray.200">{total_oee.toFixed(2) * 100}%</Text>
            <Text as="b" color="white">
                Pezzi totali
            </Text>
            <Text color="gray.200">{totalgood + totalbad}</Text>

            <Text as="b" color="white">
                Conformi
            </Text>
            <Text color="gray.200">{totalgood}</Text>

            <Text as="b" color="white">
                Non conformi
            </Text>
            <Text color="gray.200">{totalbad}</Text>

            <Text as="b" color="white">
                Cicli macchina
            </Text>
            <Text color="gray.200">{Math.round(cycles)}</Text>

            <Text as="b" color="white">
                Max. Cicli teorici
            </Text>
            <Text color="gray.200">{max !== Infinity ? Math.round(max) : '--'}</Text>

            <Text as="b" color="white">
                Tempo ciclo medio
            </Text>
            <Text color="gray.200">{mean_cycle.toFixed(2)}s</Text>

            <Text as="b" color="white">
                Run
            </Text>
            <Text color="gray.200">{displayTime(total_uptime)}</Text>

            <Text as="b" color="white">
                Fermate
            </Text>
            <Text color="gray.200">{displayTime(total_downtime)}</Text>

            <Text as="b" color="white">
                Perdita per qualità
            </Text>
            <Text color="gray.200">{total_rq.toFixed(2) * 100}%</Text>

            <Text as="b" color="white">
                Perdita per rallentamento
            </Text>
            <Text color="gray.200">{total_rv.toFixed(2) * 100}%</Text>

            <Text as="b" color="white">
                Perdita per fermate
            </Text>
            <Text color="gray.200">{total_rf.toFixed(2) * 100}%</Text>
        </>
    )
}

const renderCustomizedLabel = (props) => {
    const { content, ...rest } = props

    return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />
}

const Downs = ({ alarms, allData, selectedCode, point }) => {
    let data = allData[selectedCode] || {}
    if (point != undefined) data = [data[point]]
    // console.log(data)
    let total_downtime_al = {}
    for (let i in data) {
        let item = data[i]
        for (let al in item.downtime_al) {
            if (!total_downtime_al[al]) total_downtime_al[al] = 0
            total_downtime_al[al] += item.downtime_al[al]
        }
    }
    // console.log(total_downtime_al)

    const dataStops = React.useMemo(() => {
        let data = alarms.map((x) => {
            const secondary = Math.max(0, total_downtime_al[x.code]) || 0
            return {
                primary: x.name,
                secondary: !isNaN(secondary) ? secondary : 0,
            }
        })
        const secondary = Math.max(0, total_downtime_al[0]) || 0
        data = [
            {
                primary: 'Generico',
                secondary: !isNaN(secondary) ? secondary : 0,
            },
            ...data,
        ]
        // console.log(data)
        return [
            {
                label: 'Tempo stop',
                color: '#e53e3e',
                data,
            },
        ]
    }, [alarms, total_downtime_al])

    const seriesStops = React.useMemo(
        () => ({
            type: 'bar',
        }),
        []
    )
    const axesStops = React.useMemo(
        () => [
            { primary: true, type: 'ordinal', position: 'bottom' },
            {
                position: 'left',
                type: 'linear',
                hardMin: 1,
                stacked: false,
            },
        ],
        []
    )
    // return alarms.length && <Chart onClick={console.log} data={dataStops} series={seriesStops} axes={axesStops} tooltip />
    return null
}
const Scrap = ({ alarms, allData, selectedCode, point }) => {
    let data = allData[selectedCode] || {}
    if (point != undefined) data = [data[point]]

    let totalbad = 0

    for (let i in data) {
        let item = data[i]
        totalbad += item.bad
    }

    const dataScrap = React.useMemo(() => {
        return [
            {
                label: '# Scarti',
                color: '#d69e2e',
                data: [
                    {
                        primary: 'Generici',
                        secondary: totalbad,
                    },
                ],
            },
        ]
    }, [alarms, totalbad])

    const seriesScrap = React.useMemo(
        () => ({
            type: 'bar',
        }),
        []
    )
    const axesScrap = React.useMemo(
        () => [
            { primary: true, type: 'ordinal', position: 'bottom' },
            {
                position: 'left',
                type: 'linear',
                hardMin: 0,
                stacked: false,
            },
        ],
        []
    )
    return null //<Chart onClick={console.log} data={dataScrap} series={seriesScrap} axes={axesScrap} tooltip />
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <Table celled>
                <Table.Header>
                    <Table.HeaderCell>Metrica</Table.HeaderCell>
                    <Table.HeaderCell>Valore</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {payload
                        ? payload.map((value, index) => {
                              return (
                                  <Table.Row key={`oee-tooltip-${index}`}>
                                      <Table.Cell style={{ color: value.fill, fontWeight: 'bold' }} color={value.fill}>
                                          {value.dataKey}
                                      </Table.Cell>
                                      <Table.Cell style={{ color: value.fill, fontWeight: 'bold' }} color={value.fill}>
                                          {value.value}
                                      </Table.Cell>
                                  </Table.Row>
                              )
                          })
                        : null}
                </Table.Body>
            </Table>
        )
    }

    return null
}

const Oee = ({ machine }) => {
    // let [date, setDate] = useState(moment().startOf('week').toDate())
    let [date, setDate] = useState(moment().toDate())
    let [loading, setLoading] = useState(false)
    let [computed, setComputed] = useState({})
    let [point, setPoint] = useState()
    let [oeeData, setOeeData] = useState({})
    let [codes, setCodes] = useState([])
    let [selectedCode, setSelectedCode] = useState()
    let [oeeCodeData, setOeeCodeData] = useState([])
    let [oeeDataLoaded, setOeeDataLoaded] = useState(false)
    const size = useWindowSize()

    // let { data: machineData } = useSWR(`/machine/${machine}`)
    const oldId = machine // machineData || {}

    // const params = useMemo(
    //     () => ({
    //         params: { machine },
    //     }),
    //     [machine]
    // )
    // let { data: alarmData } = useSWR([`/alarms`, params])

    const alarmData = []

    let hours = 10
    const data = React.useMemo(() => oeeCodeData, [oeeCodeData])

    const series = React.useMemo(
        () => ({
            type: 'bar',
        }),
        []
    )
    const axes = React.useMemo(
        () => [
            {
                primary: true,
                type: 'ordinal',
                position: size.width > breakpoints.lg ? 'bottom' : 'left',
                maxLabelRotation: 0,
            },
            {
                position: size.width > breakpoints.lg ? 'left' : 'bottom',
                type: 'linear',
                hardMin: 0,
                hardMax: 100,
                stacked: true,
                labelFormatter: () => {
                    return ''
                },
                maxLabelRotation: 0,
            },
        ],
        [size]
    )

    let alarms = alarmData ? alarmData.map((x) => ({ code: x.code, name: x.name })) || [] : []
    let isTablet = size.width >= breakpoints.md && size.width < breakpoints.lg

    useEffect(() => {
        async function compute() {
            if (!oldId) return
            setLoading(true)
            setOeeCodeData([])
            setCodes([])
            setSelectedCode()
            const start = moment(date).clone().set('h', '08')
            const end = moment(date).clone().set('h', '18')

            const oeedata = await compute_oee(oldId, moment.utc(start), moment.utc(end))
            setComputed(oeedata)

            // console.log('compute oee', oeedata)
            let keys = Object.keys(oeedata || {})
            keys.sort((a, b) => parseInt(a) - parseInt(b))
            setCodes(keys)
            let oeeData = {}
            for (let k of keys) {
                if (!oeeData[k]) {
                    oeeData[k] = {
                        oee: [],
                        rq: [],
                        rv: [],
                        rf: [],
                        good: [],
                        bad: [],
                        avgcycle: [],
                        stop: [],
                        cycles: [],
                    }
                }
                let data = oeedata[k]
                let hours = Object.keys(data)
                hours.sort((a, b) => parseInt(a) - parseInt(b))
                for (let h of hours) {
                    // console.log(k, h)
                    let hdata = oeedata[k][h]
                    // console.log('hdata', hdata)
                    let primary = `${h}:00 - ${parseInt(h) + 1}:00`
                    // 0 data breaks the chart mah...
                    let oee = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.oee * 100)),
                    }
                    let rq = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.rq * 100)),
                    }
                    let rv = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.rv * 100)),
                    }
                    let rf = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.rf * 100)),
                    }
                    // console.log(oee.secondary + rq.secondary + rv.secondary + rf.secondary)
                    oeeData[k].oee.push(oee)
                    oeeData[k].rq.push(rq)
                    oeeData[k].rv.push(rv)
                    oeeData[k].rf.push(rf)

                    let good = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.good * 100)),
                    }
                    let bad = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.bad * 100)),
                    }
                    let avgcycle = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.avgcycle * 100)),
                    }
                    let stop = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.downtime * 100)),
                    }
                    let cycles = {
                        primary,
                        secondary: Math.max(0, Math.round(hdata.cycles * 100)),
                    }
                    oeeData[k].good.push(good)
                    oeeData[k].bad.push(bad)
                    oeeData[k].avgcycle.push(avgcycle)
                    oeeData[k].stop.push(stop)
                    oeeData[k].cycles.push(cycles)
                }
            }
            setOeeData(oeeData)
            let key = keys[0]
            setSelectedCode(key)
            setLoading(false)
            setOeeDataLoaded(true)
        }
        compute()
    }, [machine, date])

    useEffect(() => {
        let defCodeData = oeeData[selectedCode]
        if (!defCodeData) return

        setOeeCodeData([
            {
                label: 'OEE',
                color: '#38a169',
                data: [...defCodeData.oee],
            },
            {
                label: 'RQ',
                color: '#d69e2e',
                data: [...defCodeData.rq],
            },
            {
                label: 'RV',
                color: '#3182ce',
                data: [...defCodeData.rv],
            },
            {
                label: 'RF',
                color: '#e53e3e',
                data: [...defCodeData.rf],
            },
            {
                label: 'Buoni',
                color: '#000000',
                data: [...defCodeData.good],
            },
            {
                label: 'Scarti',
                color: '#000000',
                data: [...defCodeData.bad],
            },
            {
                label: 'Tempo ciclo medio',
                color: '#000000',
                data: [...defCodeData.avgcycle],
            },
            {
                label: 'Fermate',
                color: '#000000',
                data: [...defCodeData.stop],
            },
            {
                label: 'Cicli',
                color: '#000000',
                data: [...defCodeData.cycles],
            },
        ])
    }, [oeeData, selectedCode])

    if (!oeeDataLoaded) {
        return null
    }

    const datapoints = data && data.length > 0 ? data[0].data.length : 0
    const rechartsdata = []
    if (datapoints > 0) {
        for (let i = 0; i < datapoints; i++) {
            const obj = {}
            for (const series of data) {
                obj[series.label] = series.data[i].secondary
                obj.name = series.data[i].primary
            }
            rechartsdata.push(obj)
        }
    }

    // console.log('Datapoints are', rechartsdata)

    return (
        <>
            {/* <Alert status="warning">
                <AlertIcon />
                Nell{'\''}ambiente demo alcuni dati potrebbero non essere coerenti!
            </Alert> */}
            <Flex className="scrollarea" w="100%" h="calc(100% - 48px)" overflow="hidden">
                {/* CARD */}
                <Box w="100%" display={{ md: 'flex' }}>
                    <Box w={{ base: '100%', md: 'calc(100% - 250px)' }} p="10px">
                        {/* OEE */}
                        <Flex w="100%" h={{ base: `${size.height / 10}px`, lg: '95%' }} direction="column" align="center">
                            {/* LEGEND */}
                            <Flex w="100%" h="50px" p="0px 20px" justify="space-between" flexWrap="wrap-reverse">
                                <Flex w={{ base: '80%', md: '300px' }} justify="space-between">
                                    <Flex align="center">
                                        <Box as={FiSquare} fill="green.500" />
                                        <Text as="b">OEE</Text>
                                    </Flex>
                                    <Flex align="center">
                                        <Box as={FiSquare} fill="yellow.500" />
                                        <Text as="b">RQ</Text>
                                    </Flex>
                                    <Flex align="center">
                                        <Box as={FiSquare} fill="blue.500" />
                                        <Text as="b">RV</Text>
                                    </Flex>
                                    <Flex align="center">
                                        <Box as={FiSquare} fill="red.500" />
                                        <Text as="b">RF</Text>
                                    </Flex>
                                </Flex>
                                <Flex align="center" textAlign="end" zIndex={3}>
                                    <DayPickerInput
                                        value={date}
                                        dayPickerProps={{
                                            month: date,
                                        }}
                                        onDayChange={(day) => setDate(day)}
                                    />
                                    <Select value={selectedCode} onChange={(e) => setSelectedCode(e.target.value)}>
                                        {codes.map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </Select>
                                </Flex>
                            </Flex>
                            {loading && (
                                <Flex>
                                    <Spinner />
                                </Flex>
                            )}

                            {/* CHART */}
                            <Flex h="calc(100% - 50px)" w="100%" ml={{ lg: -100 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={rechartsdata}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />

                                        <YAxis yAxisId="stats" domain={[0, 100]} allowDataOverflow={true} />
                                        <YAxis yAxisId="numbers" orientation="right" />

                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend />
                                        <Bar yAxisId="stats" dataKey="OEE" stackId="a" fill="#23ce6b" />
                                        <Bar yAxisId="stats" dataKey="RQ" stackId="a" fill="#f1d302" />
                                        <Bar yAxisId="stats" dataKey="RV" stackId="a" fill="#446df6" />
                                        <Bar yAxisId="stats" dataKey="RF" stackId="a" fill="#d33f49" />

                                        {/* <Bar yAxisId="numbers" stackId="b" dataKey="Buoni" fill="#82ca9d" />
                                        <Bar yAxisId="numbers" stackId="c" dataKey="Scarti" fill="#FEDE00" /> */}
                                    </BarChart>
                                </ResponsiveContainer>
                                {/* <Chart
                                    onClick={(data) => {
                                        let { xValue } = data || {}
                                        if (!xValue) return setPoint()
                                        let [point] = xValue.match(/^[0-9]+/)
                                        setPoint(point)
                                    }}
                                    data={data}
                                    series={series}
                                    axes={axes}
                                    tooltip
                                /> */}
                            </Flex>
                        </Flex>
                        {/* DOWNSIDE */}
                        {/* <Box w="95%" h={{ lg: '50%' }} display={{ lg: 'flex' }} justify="space-around" align="center" ml="auto">
                            <Flex
                                w={{ base: '100%', lg: '60%' }}
                                h={{ base: '300px', lg: '100%' }}
                                maxH="400px"
                                direction="column"
                                align="center"
                                zIndex={2}
                            >
                                <Text as="b">FERMI</Text>
                                <Flex h="90%" w="100%" justify="center">
                                    <Downs alarms={alarms} allData={computed} selectedCode={selectedCode} point={point} />
                                </Flex>
                            </Flex>
                            <Flex
                                w={{ base: '100%', lg: '30%' }}
                                h={{ base: '300px', lg: '100%' }}
                                maxH="400px"
                                direction="column"
                                align="center"
                                zIndex={1}
                            >
                                <Text as="b">SCARTI</Text>
                                <Flex h="80%" w="80%" justify="center">
                                    <Scrap alarms={alarms} allData={computed} selectedCode={selectedCode} point={point} />
                                </Flex>
                            </Flex>
                        </Box> */}
                    </Box>
                    <Box flexShrink="0" w={{ base: '100%', md: '250px' }} bg="gray.500">
                        {/* RIGHT DATA */}
                        {isTablet && (
                            <Sticky scrollElement=".scrollarea">
                                <Flex bg="gray.500" size="100%" direction="column" justify="space-between" align="center" p={2}>
                                    <Recap allData={computed} selectedCode={selectedCode} point={point} />
                                </Flex>
                            </Sticky>
                        )}
                        {!isTablet && (
                            <Flex bg="gray.500" size="100%" direction="column" justify="space-between" align="center" p={2}>
                                <Recap allData={computed} selectedCode={selectedCode} point={point} />
                            </Flex>
                        )}
                    </Box>
                </Box>
            </Flex>
        </>
    )
}

export default Oee
