import { Box, Text } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Step, Table } from 'semantic-ui-react'
import { Container, Grid, GridItem, Center } from '@chakra-ui/react'
import { Articles } from '../containers'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from '../lib/View'
import Material from './Material'
import MachinePicker from '../job/MachinePicker'
import WorkOrders from './WorkOrders'

function MaterialCheck(props) {
    const {
        network,
        toolbarResult,
        filtersResult,
        sortsResult,
        isEmployee,
        foundWorkOrders,
        foundArticles,
        location: { search },
    } = props
    const [scannedMaterials, setScannedMaterials] = useState([])
    const [scannedCode, setScannedCode] = useState('')
    const [selectedArticle, setSelectedArticle] = useState(null)
    const [selectedWorkOrder, setSelectedWorkOrder] = useState(null)
    const [step, setStep] = useState(0)

    useEffect(() => {
        if (search && search.indexOf('workorder=') !== -1) {
            const workOrderId = search.split('workorder=')[1]
            network.getWorkOrderDetailed(workOrderId, true).then((result) => {
                setSelectedWorkOrder(result.data)
                setSelectedArticle(result.data)
                setScannedCode('')
                setStep(1)
            })
        }
    }, [search])

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    useEffect(() => {
        setImmediate(() => {
            const scannedCodeEl = document.getElementById('form-input-control-article-code')
            if (scannedCodeEl) {
                scannedCodeEl.focus()
                scannedCodeEl.onblur = (event) => {
                    setTimeout(() => {
                        var blurEl = document.getElementById('form-input-control-article-code')
                        if (blurEl) {
                            blurEl.focus()
                        }
                    }, 10)
                }
            }
        })
    }, [step])

    // if (step === 2) {
    //     if (selectedArticle && selectedArticle.molds) {
    //         for (const mold of selectedArticle.molds) {
    //             if (mold.moldCode === scannedCode) {
    //                 setScannedCode('')
    //                 setStep(2)
    //             }
    //         }
    //     } else {
    //         setStep(2)
    //     }
    // }

    if (step === 0 && foundWorkOrders && foundWorkOrders.length === 1 && scannedCode && foundWorkOrders[0].workOrderId == scannedCode) {
        setSelectedWorkOrder(foundWorkOrders[0])
        setSelectedArticle(foundWorkOrders[0])
        setScannedCode('')
        setStep(1)
    }

    // if (step === 1 && foundArticles && foundArticles.length === 1 && scannedCode) {
    //     setSelectedArticle(foundArticles[0])
    //     setScannedCode('')
    //     setStep(2)
    // }

    // if (step === 2) {
    //     if (selectedArticle && selectedArticle.materials) {
    //         for (const material of selectedArticle.materials) {
    //             if (material.materialCode === scannedCode) {
    //                 setScannedMaterials([...scannedMaterials, material.moldCode])
    //             }
    //         }
    //         if (scannedMaterials.length === selectedArticle.materials.length) {
    //             setScannedCode('')
    //             setStep(3)
    //         }
    //     } else {
    //         setScannedCode('')
    //         setStep(3)
    //     }
    // }

    return (
        <>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-article-code"
                        control={Input}
                        label={<span className="label">Scansione</span>}
                        name="scannedCode"
                        value={scannedCode}
                        onChange={(e) => {
                            setScannedCode(e.target.value)
                        }}
                        placeholder="SCANSIONA QUI"
                        style={{
                            fontSize: 64,
                        }}
                    />
                </Form.Group>
            </Form>
            <Step.Group style={{ width: '100%' }}>
                <Step active={step === 0}>
                    <Icon name="file alternate outline" />
                    <Step.Content>
                        <Step.Title>Codice produzione</Step.Title>
                        <Step.Description>Scansiona l{"'"}ordine di produzione</Step.Description>
                    </Step.Content>
                </Step>
                {/* <Step active={step === 1}>
                    <Icon name="file alternate outline" />
                    <Step.Content>
                        <Step.Title>Articolo</Step.Title>
                        <Step.Description>Scansiona o seleziona l{"'"}articolo da produrre</Step.Description>
                    </Step.Content>
                </Step> */}

                {/* <Step active={step === 1}>
                    <Icon name="tablet alternate" />
                    <Step.Content>
                        <Step.Title>Stampo</Step.Title>
                        <Step.Description>Scansiona lo stampo corretto</Step.Description>
                    </Step.Content>
                </Step> */}

                {/* <Step active={step === 2}>
                    <Icon name="cube" />
                    <Step.Content>
                        <Step.Title>Materiale</Step.Title>
                        <Step.Description>Scansiona il materiale corretto</Step.Description>
                    </Step.Content>
                </Step> */}
                <Step active={step === 1}>
                    <Icon name="check" />
                    <Step.Content>
                        <Step.Title>Fine</Step.Title>
                        <Step.Description>Procedura completa</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Table celled>
                {step === 0 && (
                    <>
                        <WorkOrders
                            noActions
                            onRowClick={(result) => {
                                setSelectedWorkOrder(result)
                                setSelectedArticle(result)
                                setScannedCode('')
                                setStep(1)
                            }}
                            isEmployee={isEmployee}
                            hideInputs
                            searchOrderId={scannedCode}
                        />
                    </>
                )}
                {/* {step === 1 && (
                    <>
                        <Articles
                            isEmployee={isEmployee}
                            noActions
                            selectable
                            onChange={(article) => {
                                // console.log('Selected article', article)
                                setSelectedArticle(article)
                                setScannedCode('')
                                setStep(2)
                            }}
                            articleCodeFilter={scannedCode}
                            searchOrderId={selectedWorkOrder ? selectedWorkOrder.workOrderId : 0}
                            sub
                        />
                    </>
                )} */}
                {/* {step === 1 && (
                    <>
                        <Mold type="header" hideActions hideBarcode />
                        <Table.Body>
                            {selectedArticle && selectedArticle.molds
                                ? selectedArticle.molds.map((value, index) => {
                                      return <Mold key={value.moldId} type="table" mold={value} hideActions hideBarcode isEmployee={isEmployee} />
                                  })
                                : null}
                        </Table.Body>
                    </>
                )} */}

                {/* {step === 2 && (
                    <>
                        <Material type="header" hideActions hideBarcode />
                        <Table.Body>
                            {selectedArticle && selectedArticle.materials
                                ? selectedArticle.materials.map((value, index) => {
                                      return (
                                          <Material
                                              key={value.materialId}
                                              type="table"
                                              material={value}
                                              hideActions
                                              hideBarcode
                                              isEmployee={isEmployee}
                                              scannedMaterials={scannedMaterials}
                                          />
                                      )
                                  })
                                : null}
                        </Table.Body>
                    </>
                )} */}
            </Table>
            {step === 1 && (
                <Box p={16} w="100%" h="100%" style={{ color: 'black', fontSize: '32pt' }}>
                    <Text pb={8}>Cliccare il tasto play per inviare la ricetta alla macchina</Text>
                    <Container maxW="100%" centerContent>
                        <Box padding="2" color="black" maxW="100%">
                            <Grid mr={8} ml={8} mb={8} p={8} templateColumns="repeat(4, 1fr)" gap={6} border="1px" borderColor="black">
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text as="b">Ord.</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text as="b">Ord.Cli.</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text as="b">Note</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text as="b">Da produrre</Text>
                                    </Center>
                                </GridItem>

                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text>{selectedWorkOrder.workCode}</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text>{selectedWorkOrder.jobName}</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text>{selectedWorkOrder.description}</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text>{selectedWorkOrder.remainingQuantity}</Text>
                                    </Center>
                                </GridItem>
                            </Grid>
                            <Grid m={8} p={8} templateColumns="repeat(4, 1fr)" gap={6} border="1px" borderColor="black">
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text as="b">Articolo</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text as="b">Codice</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text as="b">Descrizione</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text as="b">Note</Text>
                                    </Center>
                                </GridItem>

                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text>{selectedArticle.articleName}</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text>{selectedArticle.articleCode}</Text>
                                    </Center>
                                </GridItem>
                                <GridItem w="100%" h="10">
                                    <Center>
                                        <Text>{selectedArticle.articleNotes}</Text>
                                    </Center>
                                </GridItem>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
            )}
            {step === 2 && (
                <Box p={16} w="100%" h="100%" style={{ color: 'white', backgroundColor: 'green' }}>
                    Dati inviati alla macchina {selectedArticle && selectedArticle.machine ? selectedArticle.machine.machineName : ''} con successo!
                </Box>
            )}
            <View fullw style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                {step > 0 && step <= 1 && (
                    <Button
                        onClick={() => {
                            setStep(step - 1)
                            setScannedMaterials([])
                        }}
                    >
                        Indietro
                    </Button>
                )}
                {step === 1 && (
                    <>
                        <Button
                            color="red"
                            onClick={() => {
                                setScannedMaterials([])
                                setStep(3)
                                setTimeout(() => {
                                    setStep(0)
                                }, 10000)
                            }}
                        >
                            Annulla
                        </Button>
                        {/* <Button
                            color="green"
                            onClick={() => {
                                setScannedMaterials([])
                                setStep(3)
                                setTimeout(() => {
                                    setStep(0)
                                }, 10000)
                            }}
                        >
                            Fine
                        </Button> */}
                        <MachinePicker
                            isEmployee
                            inProduction={false}
                            multiple
                            articleFilter={selectedArticle}
                            onMachineChosen={async (machine) => {
                                /* eslint-disable */
                                console.log('Adding production...', machine)
                                const workOrderId = selectedWorkOrder.workOrderId
                                const workCode = selectedWorkOrder.workCode
                                const jobCode = selectedWorkOrder.jobCode
                                let recipe = null
                                let machinewdata = null

                                for (const mac of selectedArticle.machines) {
                                    if (mac.machineId === machine.machineId) {
                                        recipe = mac.recipe
                                        machinewdata = mac
                                    }
                                }

                                /* eslint-disable */
                                console.log('Recipe ID', machine.machineId, machinewdata, recipe)
                                if (machine.machineId === 4) {
                                    const recipeMachineSpecificData = JSON.parse(recipe.recipeMachineSpecificData)

                                    const base = 451
                                    await network.createCommand(
                                        {
                                            node: 1005,
                                            action: 1,
                                            value: workOrderId,
                                            endpoint: machine.machineEndpoint,
                                        },
                                        true
                                    )
                                    for (const i in recipeMachineSpecificData) {
                                        const index = parseInt(i)
                                        if (index >= 5) {
                                            continue
                                        }
                                        for (const j in recipeMachineSpecificData[i]) {
                                            const column = parseInt(j)
                                            if (column >= 5) {
                                                continue
                                            }
                                            const register = base + index * 5 + column
                                            const value = parseFloat(recipeMachineSpecificData[i][j]) * 10
                                            console.log('Writing value', recipeMachineSpecificData[i][j], value, 'to register', register)
                                            await network.createCommand(
                                                {
                                                    node: register,
                                                    action: 1,
                                                    value,
                                                    endpoint: machine.machineEndpoint,
                                                },
                                                true
                                            )
                                        }
                                    }
                                } else if (machine.machineId === 2) {
                                    const recipeMachineSpecificData = JSON.parse(recipe.recipeMachineSpecificData)

                                    const stringwid = `${workOrderId}`
                                    let basestr = 7052
                                    let byte = 0
                                    for (let i = 0; i < stringwid.length; i+=2) {
                                        let character1 = stringwid[i]
                                        let character2 = i+1 < stringwid.length ? stringwid[i+1] : null
                                        await network.createCommand(
                                            {
                                                node: basestr + byte,
                                                action: 1,
                                                value: character1.charCodeAt(0) << 8 + (character2 ? character2.charCodeAt(0) : 0),
                                                endpoint: machine.machineEndpoint,
                                            },
                                            true
                                        )

                                        byte++
                                    }

                                    await network.createCommand(
                                        {
                                            node: basestr + byte,
                                            action: 1,
                                            value: 0,
                                            endpoint: machine.machineEndpoint,
                                        },
                                        true
                                    )

                                    await network.createCommand(
                                        {
                                            node: 7050,
                                            action: 1,
                                            value: recipe.recipeIndex,
                                            endpoint: machine.machineEndpoint,
                                        },
                                        true
                                    )

                                    await network.createCommand(
                                        {
                                            node: 7044,
                                            action: 1,
                                            value: selectedWorkOrder.productQuantity,
                                            endpoint: machine.machineEndpoint,
                                        },
                                        true
                                    )

                                    await network.createCommand(
                                        {
                                            node: 7045,
                                            action: 1,
                                            value: 0,
                                            endpoint: machine.machineEndpoint,
                                        },
                                        true
                                    )

                                    await network.createCommand(
                                        {
                                            node: 7000,
                                            action: 1,
                                            value: 2,
                                            endpoint: machine.machineEndpoint,
                                        },
                                        true
                                    )
                                } else {
                                    network
                                        .createLot(
                                            {
                                                workOrderId,
                                                machineId: machine.machineId,
                                            },
                                            true
                                        )
                                        .then(async (result) => {
                                            await network.createMachine(
                                                {
                                                    machineId: machine.machineId,
                                                    activeLotId: result.lotId,
                                                },
                                                true
                                            )

                                            await network.createWorkOrder(
                                                {
                                                    workOrderId,
                                                    workCode,
                                                    jobCode,
                                                    inProduction: true,
                                                },
                                                true
                                            )

                                            if (machine.machineHadesRef && machine.machineEndpoint) {
                                                if (machine.nodeJob) {
                                                    await network.createCommand(
                                                        {
                                                            node: machine.nodeJob,
                                                            action: 1,
                                                            value: `${jobCode}`,
                                                            endpoint: machine.machineEndpoint,
                                                        },
                                                        true
                                                    )
                                                }

                                                if (machine.nodeRecipeIndex) {
                                                    await network.createCommand(
                                                        {
                                                            node: machine.nodeRecipeIndex,
                                                            action: 1,
                                                            value: recipe.recipeIndex,
                                                            endpoint: machine.machineEndpoint,
                                                            datatype: 'Int16',
                                                        },
                                                        true
                                                    )
                                                }

                                                if (machine.nodeCustomer) {
                                                    await network.createCommand(
                                                        {
                                                            node: machine.nodeCustomer,
                                                            action: 1,
                                                            value: `${selectedWorkOrder.customerName}`,
                                                            endpoint: machine.machineEndpoint,
                                                        },
                                                        true
                                                    )
                                                }

                                                if (machine.nodeLot) {
                                                    await network.createCommand(
                                                        {
                                                            node: machine.nodeLot,
                                                            action: 1,
                                                            value: `${result.lotId}`,
                                                            endpoint: machine.machineEndpoint,
                                                        },
                                                        true
                                                    )
                                                }

                                                if (machine.nodeArticle) {
                                                    await network.createCommand(
                                                        {
                                                            node: machine.nodeArticle,
                                                            action: 1,
                                                            value: `${selectedWorkOrder.workCode}`,
                                                            endpoint: machine.machineEndpoint,
                                                        },
                                                        true
                                                    )
                                                }

                                                // if (machine.nodeMaterial && selectedWorkOrder.materials && selectedWorkOrder.materials.length > 0) {
                                                //     await network.createCommand(
                                                //         {
                                                //             node: machine.nodeMaterial,
                                                //             action: 1,
                                                //             value: `${selectedWorkOrder.materials[0].materialCode}`,
                                                //             endpoint: machine.machineEndpoint,
                                                //         },
                                                //         true
                                                //     )
                                                // }

                                                // if (machine.nodeMold && selectedWorkOrder.molds && selectedWorkOrder.molds.length > 0) {
                                                //     await network.createCommand(
                                                //         {
                                                //             node: machine.nodeMold,
                                                //             action: 1,
                                                //             value: `${selectedWorkOrder.molds[0].moldCode}`,
                                                //             endpoint: machine.machineEndpoint,
                                                //         },
                                                //         true
                                                //     )

                                                //     if (machine.nodeMoldDescription) {
                                                //         await network.createCommand(
                                                //             {
                                                //                 node: machine.nodeMoldDescription,
                                                //                 action: 1,
                                                //                 value: `${selectedWorkOrder.molds[0].moldDescription}`,
                                                //                 endpoint: machine.machineEndpoint,
                                                //             },
                                                //             true
                                                //         )
                                                //     }

                                                //     if (machine.nodeMoldId) {
                                                //         await network.createCommand(
                                                //             {
                                                //                 node: machine.nodeMoldId,
                                                //                 action: 1,
                                                //                 value: `${selectedWorkOrder.molds[0].moldCode}`,
                                                //                 endpoint: machine.machineEndpoint,
                                                //             },
                                                //             true
                                                //         )
                                                //     }

                                                //     if (machine.nodeCavities) {
                                                //         await network.createCommand(
                                                //             {
                                                //                 node: machine.nodeCavities,
                                                //                 action: 1,
                                                //                 value: `${
                                                //                     selectedWorkOrder.molds[0].piecesPerCycle ? selectedWorkOrder.molds[0].piecesPerCycle : 1
                                                //                 }`,
                                                //                 endpoint: machine.machineEndpoint,
                                                //             },
                                                //             true
                                                //         )
                                                //     }
                                                // }

                                                if (machine.nodeQuantity) {
                                                    await network.createCommand(
                                                        {
                                                            node: machine.nodeQuantity,
                                                            action: 1,
                                                            value: `${selectedWorkOrder.productQuantity - selectedWorkOrder.producedQuantity}`,
                                                            endpoint: machine.machineEndpoint,
                                                            datatype: 'Int32',
                                                        },
                                                        true
                                                    )
                                                }

                                                if (machine.nodePallets) {
                                                    await network.createCommand(
                                                        {
                                                            node: machine.nodePallets,
                                                            action: 1,
                                                            value: `${Math.round(
                                                                (selectedWorkOrder.productQuantity - selectedWorkOrder.producedQuantity) / 120
                                                            )}`, // TODO pallets calc
                                                            endpoint: machine.machineEndpoint,
                                                            datatype: 'Int32',
                                                        },
                                                        true
                                                    )
                                                }

                                                // await this.props.network.createCommand({
                                                //     node: 'ns=2;i=20039',
                                                //     action: 1,
                                                //     value: `${job.jobCode}.ipr`,
                                                //     endpoint: 'opc.tcp://10.199.115.3:4842',
                                                // })

                                                if (machine.nodeChangeRequest) {
                                                    await network.createCommand(
                                                        {
                                                            node: machine.nodeChangeRequest,
                                                            action: 1,
                                                            value: 'true',
                                                            endpoint: machine.machineEndpoint,
                                                            datatype: 'Boolean',
                                                        },
                                                        true
                                                    )
                                                }

                                                // if (machine.downEndpoint) {
                                                //     await this.props.network.createCommand(
                                                //         {
                                                //             node: '',
                                                //             action: 1,
                                                //             value: `${selectedWorkOrder.molds[0].moldCode}.ipr`,
                                                //             endpoint: `${machine.downEndpoint}`,
                                                //         },
                                                //         true
                                                //     )
                                                // }
                                            }
                                        })
                                        .catch((error) => {
                                            console.error('Error while starting production', error)
                                        })
                                }
                            }}
                        />
                    </>
                )}
            </View>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getStdArticleList, getMachineList, getWorkOrderList, deleteMold, mergeMold } = state

    return {
        getMachineList,
        foundArticles: getStdArticleList && getStdArticleList.data && getStdArticleList.data.data ? getStdArticleList.data.data : [],
        foundWorkOrders: getWorkOrderList && getWorkOrderList.data && getWorkOrderList.data.data ? getWorkOrderList.data.data : [],
        deleteMold,
        mergeMold,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MaterialCheck)))
