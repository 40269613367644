export function createDocument(head, body) {
    let { createDocumentRequest, createDocumentError, createDocumentResult } = this.props

    // delete head.creationDate
    // delete head.updateDate

    this.doAction(
        this.WEB_REQUEST_URL + 'app/document/save',
        this.createBundle(
            'app/document/save',
            { documentHead: head, documentBody: body },
            this.POST,
            createDocumentRequest,
            createDocumentResult,
            createDocumentError
        )
    )
}
