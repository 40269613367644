import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Menu } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Contract } from '../components'
import { testContract } from '../lib/util'

const { app, contracts, newEl, contractEdit } = routes

const tabs = {
    received: 'Ricevute',
    sent: 'Inviate',
    accepted: 'Accettate',
}

class Contracts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: tabs.received,
            page: 0,
            items: 10,
            wait: true,
            deposit: -1,
            exportModal: false,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        let { page, items } = this.state
        let { organization, deleteContract, search } = this.props

        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteContract.fetching !== prevProps.deleteContract.fetching && !deleteContract.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getContractList(page, items, search.data)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getContractList(page, items, search.data)
        }
    }

    next = () => {
        let { contract, items } = this.state

        if (!contract.data || contract.data.length < items) {
            return
        }

        this.setState({
            page: this.state.page + 1,
        })
    }

    prev = () => {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount = () => {
        let { sub, network, search } = this.props
        let { deposit, page, items } = this.state
        if (!sub) {
            this.props.toolbarResult([
                {
                    name: 'Crea nuovo appalto',
                    icon: 'add',
                    // disabled: () => true,
                    action: () => {
                        this.props.history.push(route_from(app, contracts, newEl, contractEdit))
                    },
                },
                // {
                // 	name: 'Esporta appalti',
                // 	icon: 'download',
                // 	position: 'right',
                // 	action: () => {
                // 		this.setState({ exportModal: true })
                // 	}
                // },
                {
                    name: '',
                    icon: 'arrow left',
                    position: 'right',
                    action: () => {
                        this.prev()
                    },
                },
                {
                    name: '',
                    icon: 'arrow right',
                    position: undefined,
                    action: () => {
                        this.next()
                    },
                },
            ])
        }

        network.getDepositList()
        network.getContractList(page, items, search.data, deposit)
    }

    renderContract = (key, contract) => {
        let { selected } = this.state
        let { onChange, selectable, noActions, showNoContract } = this.props

        // console.log('renderContract status:', contract.contractStatus)
        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (contract) => {
                if (onChange) {
                    onChange(contract)
                }
                this.setState({ selected: contract })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (showNoContract) {
            extraProps.showNoContract = true
        }

        return (
            <Contract
                {...extraProps}
                selected={contract.contractId === selected.contractId}
                type="full"
                key={`contract_${key}`}
                contract={contract}
            />
        )
    }

    received = () => {
        return testReceived.data.map((value, index) => {
            value.contractStatus = 'received'
            return this.renderContract(index, value)
        })
    }

    sent = () => {
        return testSent.data.map((value, index) => {
            value.contractStatus = 'sent'
            return this.renderContract(index, value)
        })
    }

    accepted = () => {
        return testAccepted.data.map((value, index) => {
            value.contractStatus = 'accepted'
            return this.renderContract(index, value)
        })
    }

    render() {
        let { active } = this.state

        return (
            <View fullw column>
                <View fullw style={{ backgroundColor: 'white', position: 'fixed', zIndex: 42, maxWidth: 1200 }}>
                    <Menu pointing secondary stackable style={{ width: '100%' }}>
                        {Object.keys(tabs).map((key) => (
                            <Menu.Item
                                key={key}
                                name={tabs[key]}
                                active={active === tabs[key]}
                                onClick={() => this.setState({ active: tabs[key] })}
                            />
                        ))}
                    </Menu>
                </View>
                <Box p={6} basic style={{ marginTop: 40 }}>
                    {active === tabs.received ? this.received() : null}
                    {active === tabs.sent ? this.sent() : null}
                    {active === tabs.accepted ? this.accepted() : null}
                    {/* {
						{
							[tabs.received]: this.received(),
							[tabs.sent]: this.sent(),
							[tabs.accepted]: this.accepted()
						}[active]
					} */}
                </Box>
            </View>
        )

        // return (
        //     <Box p={6} placeholder>
        //         <Grid columns={3} divided textAlign="center" style={{ height: '100%' }}>
        //             <Grid.Row style={{ height: '100%' }}>
        //                 <Grid.Column style={styles.column}>
        //                     <Box textStyle="header" icon>
        //                         <Icon name="cloud download" />
        //                         {cols.received}
        //                     </Box>
        //                     {this.received()}
        //                 </Grid.Column>

        //                 <Grid.Column style={styles.column}>
        //                     <Box textStyle="header" icon>
        //                         <Icon name="cloud upload" />
        //                         {cols.sent}
        //                     </Box>
        //                     {this.sent()}
        //                 </Grid.Column>

        //                 <Grid.Column style={styles.column}>
        //                     <Box textStyle="header" icon>
        //                         <Icon name="tasks" />
        //                         {cols.accepted}
        //                     </Box>
        //                     {this.accepted()}
        //                 </Grid.Column>
        //             </Grid.Row>
        //         </Grid>
        //     </Box>
        // )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let { deleteContract, search, organization } = state

    // if (isEmployee) {
    // contractList = getRecentContractList
    // }

    return {
        contract: testData, //contractList.data,
        fetching: false, //contractList.fetching,
        deleteContract,
        search,
        organization,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Contracts))

let statuses = ['received', 'sent', 'accepted']
const testData = { fetching: false, data: [] }
const testReceived = { fetching: false, data: [] }
const testSent = { fetching: false, data: [] }
const testAccepted = { fetching: false, data: [] }

for (let i = 0; i < 3; i++) {
    let contractStatus = statuses[i % 3]
    testData.data.push({
        ...testContract,
        contractId: i,
        contractStatus: statuses[i % 3],
    })
    switch (contractStatus) {
        default:
        case 'received':
            testReceived.data.push({
                ...testContract,
                contractId: i,
                contractStatus: statuses[i % 3],
            })
            break
        case 'sent':
            testSent.data.push({
                ...testContract,
                contractId: i,
                contractStatus: statuses[i % 3],
            })
            break
        case 'accepted':
            testAccepted.data.push({
                ...testContract,
                contractId: i,
                contractStatus: statuses[i % 3],
            })
            break
    }
}

// console.log('Test data:', testData)
// console.log('Test data:', testReceived)
// console.log('Test data:', testSent)
// console.log('Test data:', testAccepted)
