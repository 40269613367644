import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch } from '../lib/util'
import Oee from './Oee'
function OeeVisualizer({ network, table }) {
    // const [datapoints, setDatapoints] = useState([])
    // useEffect(() => {
    //     network
    //         .doRequest({
    //             action: `table/line/${table}`,
    //             method: network.GET,
    //             data: {
    //                 id: '0',
    //                 sub_id: '0',
    //                 limit: '100',
    //                 filters: [
    //                     {
    //                         key: 'timestamp',
    //                         symbol: '>=',
    //                         value: '2020-05-23T00:00:00.000Z',
    //                     },
    //                     // {
    //                     //     key: 'timestamp',
    //                     //     symbol: '>=',
    //                     //     value: '2020-05-30T00:00:00.000Z',
    //                     // },
    //                 ],
    //             },
    //             service: network.HADES,
    //             token: network.MACHINES,
    //         })
    //         .then((data) => {
    //             console.log(data)
    //             setDatapoints(data)
    //         })
    // }, [table])

    // if (datapoints.length === 0) return null

    // const firstDatapoint = datapoints[0]
    return <Oee machine={table} />
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(OeeVisualizer)))
