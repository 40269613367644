import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { Confirmation } from '../components'
import { floatTimeToString, timeString } from '../lib/util'

const { app, corps, corpEdit } = routes

class Corp extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, corp } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...corp,
            corpMinutes: corp ? parseFloat(corp.corpMinutes) : 0,
        }

        if (onCostChange && corp) {
            onCostChange(this.totalCost(), corp.id)
        }
    }

    totalCost() {
        let { discount, corpHours, corpMinutes } = this.state
        let total = (corpHours + corpMinutes / 60) * parseFloat(discount)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { corp, id, network } = this.props

        if ((!corp || !corp.id) && id) {
            network.getCorp(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getCorp, login } = this.props
        let { id, discount } = this.state
        if (discount !== prevState.discount) {
            if (onCostChange) {
                onCostChange(this.totalCost(), id)
            }
        }

        // console.log('Corp getCorp fetching', getCorp.fetching)
        if (getCorp && getCorp.fetching !== prevProps.getCorp.fetching && !getCorp.fetching) {
            // console.log('Done calling getCorp')
            if (getCorp.status.success) {
                // console.log(getCorp.data)
                let corp = getCorp.data.data

                this.setState({ ...corp })
                // console.log('Calling Get corp', login, corp)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No corp found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { name, discount, corpHours, corpMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={discount}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    discount: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{discount}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(corpHours, corpMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideCorp, hideCorpHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideCorp && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideCorpHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideCorp, hideCorpHours, isBalance } = this.props
        let { name, corpHours, corpMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideCorp && <Table.Cell>{name}</Table.Cell>}
                    {!hideCorpHours && <Table.Cell>{timeString(corpHours, corpMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, corp } = this.props

        network.deleteCorp(corp.id)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, corp, onClick, selected, noActions, role } = this.props
        let {
            deleting,
            id,
            name,
            discount,
            // corpImage,
        } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(corp)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.corpCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questa tipologia?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.corpContent}>
                                <View fullw row>
                                    {/* <div style={styles.imageContainer}>
										<ImageDefault
											fromRoot
											src={corpImage}
											ui={false}
											style={styles.corpImage}
										/>
									</div> */}
                                    <View fullw column>
                                        <span style={styles.header}>{name}</span>
                                        {!noActions && (!role || role === 'admin') && <Box textStyle="description">Sconto: {discount}%</Box>}
                                    </View>
                                </View>
                            </Box>
                            {!noActions && (
                                <Box textStyle="content" extra>
                                    {user && !!user.manage_corp && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, corps, corpEdit, `${id}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            Modifica
                                        </Button>
                                    )}

                                    {user && !!user.manage_corp && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            Elimina
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        textTransform: 'capitalize',
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    corpImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    corpContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    corpCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state, ownProps) => {
    let { login, getCorp } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getCorp, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Corp)))
