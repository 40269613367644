import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Header, Icon, Segment } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'
import Ind40Charts from './Ind40Charts'

function Ind40ChartCollection({ network, machines }) {
    const [selectedMonth, setSelectedMonth] = useState(moment().format('YYYY-MM'))

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value)
    }

    const renderMonthOptions = () => {
        const months = []
        const currentYear = new Date().getFullYear()
        for (let year = 2020; year <= currentYear; year++) {
            for (let month = 1; month <= 12; month++) {
                const monthValue = `${year}-${month.toString().padStart(2, '0')}`
                months.push(
                    <option key={monthValue} value={monthValue}>
                        {moment(monthValue).format('MMMM YYYY')}
                    </option>
                )
            }
        }
        return months
    }

    useEffect(() => {
        /* eslint-disable */
        console.log('getMachineList', machines)
        network.getMachineList()
    }, [])

    /* eslint-disable */
    console.log('machines', machines)

    if (!machines) {
        return null
    } else if (machines.length === 0) {
        return (
            <>
                <Segment placeholder>
                    <Header icon>
                        <Icon name="search" />
                        Nessuna macchina trovata
                    </Header>
                </Segment>
            </>
        )
    }

    const segsForRow = 1
    const segs = []

    for (const [i, machine] of machines.entries()) {
        if (machine.machineHadesRef) {
            segs.push(
                <>
                    <Ind40Charts key={machine.machineHadesRef} machine={machine} selectedMonth={selectedMonth} />
                </>
            )
        }
    }

    return (
        <>
            <select value={selectedMonth} onChange={handleMonthChange}>
                {renderMonthOptions()}
            </select>
            {segs}
            {/* <Grid columns={2}>{segs}</Grid> */}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList: {
            fetching: fetchMachines,
            data: { data: machines },
        },
    } = state

    return {
        fetchMachines,
        machines,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Ind40ChartCollection)))
