export function getBadgeScanList(data) {
    return new Promise((resolve, reject) => {
		let { getBadgeScanListRequest, getBadgeScanListError, getBadgeScanListResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_badgescan_list',
			this.createBundle(
				'get_badgescan_list',
				{ badgescan: data },
				this.GET,
				getBadgeScanListRequest,
                (result) => {
                    getBadgeScanListResult(result)
                    resolve(result)
                },
                (error) => {
                    getBadgeScanListError(error)
                    reject(error)
                }
			)
		);
	})
}
