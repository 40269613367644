import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { Confirmation, ImageDefault } from '.'
import { floatTimeToString, timeString } from '../lib/util'

const { app, badgescans, badgescanEdit } = routes

class BadgeScan extends Component {
    constructor(props) {
        super(props)

        this.componentContainerRef = null

        this.setComponentContainerRef = (element) => {
            this.componentContainerRef = element
        }

        let { onCostChange, badgescan } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...badgescan,
            badgescanMinutes: badgescan ? parseFloat(badgescan.badgescanMinutes) : 0,
        }

        if (onCostChange && badgescan) {
            onCostChange(this.totalCost(), badgescan.id)
        }
    }

    totalCost() {
        let { badgescanHourlyRate, badgescanHours, badgescanMinutes } = this.state
        let total = (badgescanHours + badgescanMinutes / 60) * parseFloat(badgescanHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { badgescan, id, network } = this.props

        if ((!badgescan || !badgescan.id) && id) {
            network.getBadgeScan(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getBadgeScan, login } = this.props
        let { id, badgescanHourlyRate } = this.state
        if (badgescanHourlyRate !== prevState.badgescanHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), id)
            }
        }

        // console.log('BadgeScan getBadgeScan fetching', getBadgeScan.fetching)
        if (getBadgeScan && getBadgeScan.fetching !== prevProps.getBadgeScan.fetching && !getBadgeScan.fetching) {
            // console.log('Done calling getBadgeScan')
            if (getBadgeScan.status.success) {
                // console.log(getBadgeScan.data)
                let badgescan = getBadgeScan.data.data

                this.setState({ ...badgescan })
                // console.log('Calling Get badgescan', login, badgescan)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No badgescan found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { name, badgescanHourlyRate, badgescanHours, badgescanMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={badgescanHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    badgescanHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{badgescanHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(badgescanHours, badgescanMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideBadgeScanTime, hideBadgeWorkerName, hideBadgeCode, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideBadgeScanTime && <Table.HeaderCell>Scansione</Table.HeaderCell>}
                        {!hideBadgeCode && <Table.HeaderCell>Badge</Table.HeaderCell>}
                        {!hideBadgeWorkerName && <Table.HeaderCell>Impiegato</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideBadgeScanTime, hideBadgeWorkerName, hideBadgeCode, isBalance } = this.props
        let { badgeScanTime, badgeCode, workerName } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideBadgeScanTime && <Table.Cell>{moment(badgeScanTime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>}
                    {!hideBadgeCode && <Table.Cell>{badgeCode}</Table.Cell>}
                    {!hideBadgeWorkerName && <Table.Cell>{workerName}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, badgescan } = this.props

        network.deleteBadgeScan(badgescan)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, badgescan, onClick, selected, noActions, traversable } = this.props
        let { deleting, badgescanId: id, badgescanName, badgescanDescription, badgescanImages } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(badgescan)
            }
        }

        let col = parseInt(Math.random() * this.state.colors.length)

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            case 'compact':
                return (
                    <div ref={this.setComponentContainerRef}>
                        <Box style={{ height: 200, width: 200 }}>
                            <Box textStyle="header">BadgeScan compact {badgescanName}</Box>
                        </Box>
                    </div>
                )
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.badgescanCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questa collezione?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.badgescanContent}>
                                <View fullw row>
                                    {badgescanImages && badgescanImages.length > 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={badgescanImages[0].picturePath} ui={false} style={styles.badgescanImage} />
                                        </div>
                                    )}
                                    {!badgescanImages ||
                                        (badgescanImages.length === 0 && (
                                            <div style={styles.imageContainer}>
                                                <ImageDefault fromRoot src={null} ui={false} style={styles.badgescanImage} />
                                            </div>
                                        ))}
                                    <View fullw column>
                                        <span style={styles.header}>{badgescanName}</span>
                                        <Box textStyle="description">{badgescanDescription}</Box>
                                    </View>
                                </View>
                            </Box>
                            {!noActions && (
                                <Box textStyle="content" extra>
                                    {user && !!user.manage_badgescan && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, badgescans, badgescanEdit, `${id}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            Modifica
                                        </Button>
                                    )}

                                    {user && !!user.manage_badgescan && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            Elimina
                                        </Button>
                                    )}
                                </Box>
                            )}
                            {traversable && (
                                <Box textStyle="content" extra>
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.onTraverse(badgescan)
                                        }}
                                    >
                                        <Icon name="folder" />
                                        Apri
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    badgescanImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    badgescanContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    badgescanCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getBadgeScan } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getBadgeScan, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(BadgeScan)))
