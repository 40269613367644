import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Table } from 'semantic-ui-react'
import { Confirmation } from '../components'

const { app, deposits, depositEdit } = routes

class Deposit extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, deposit } = props

        this.state = {
            colors: ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'],
            deleting: false,
            ...deposit,
            depositMinutes: deposit ? parseFloat(deposit.depositMinutes) : 0,
        }

        if (onCostChange && deposit) {
            onCostChange(this.totalCost(), deposit.id)
        }
    }

    totalCost() {
        let { depositHourlyRate, depositHours, depositMinutes } = this.state
        let total = (depositHours + depositMinutes / 60) * parseFloat(depositHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { deposit, id, network } = this.props

        if ((!deposit || !deposit.id) && id) {
            network.getDeposit(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { getDeposit, login } = this.props

        // console.log('Deposit getDeposit fetching', getDeposit.fetching)
        if (getDeposit && getDeposit.fetching !== prevProps.getDeposit.fetching && !getDeposit.fetching) {
            // console.log('Done calling getDeposit')
            if (getDeposit.status.success) {
                // console.log(getDeposit.data)
                let deposit = getDeposit.data.data

                this.setState({ ...deposit })
                // console.log('Calling Get deposit', login, deposit)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No deposit found with this id')
            }
        }
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideDeposit, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>{!hideDeposit && <Table.HeaderCell>Nome Deposito</Table.HeaderCell>}</Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideDeposit, isBalance } = this.props
        let { name } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return <Table.Row>{!hideDeposit && <Table.Cell>{name}</Table.Cell>}</Table.Row>
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, deposit } = this.props

        network.deleteDeposit(deposit)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, deposit, onClick, selected, noActions } = this.props
        let {
            deleting,
            id,
            // identifier,
            name,
        } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(deposit)
            }
        }


        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.depositCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questo deposito?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.depositContent}>
                                <View fullw row>
                                    {/* <div style={styles.imageContainer}>
										<ImageDefault
											fromRoot
											src={depositImage}
											ui={false}
											style={styles.depositImage}
										/>
									</div> */}
                                    <View fullw column>
                                        <span style={styles.header}>{name}</span>
                                        {/* {!noActions &&
										(!role || role === 'admin') && (
											// <Box textStyle="description">
											// </Box>
										)} */}
                                    </View>
                                </View>
                            </Box>
                            {!noActions && (
                                <Box textStyle="content" extra>
                                    {user && !!user.manage_deposit && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, deposits, depositEdit, `${id}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            Modifica
                                        </Button>
                                    )}

                                    {user && !!user.manage_deposit && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            Elimina
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    depositImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    depositContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    depositCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getDeposit } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getDeposit, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Deposit)))
