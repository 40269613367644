import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import WorkOrders from 'printing/WorkOrders'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Segment, Select, Statistic } from 'semantic-ui-react'
import { Calendar } from '../components'
import Works from '../job/Works'
import { baseViewDispatch, initializePage, timeObject } from '../lib/util'
import { routes, route_from } from 'lib/routes'

const { employee, materialcheck } = routes

class CalendarView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            worker: null,
            works: [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fetchingWorkers !== this.props.fetchingWorkers && !this.props.fetchingWorkers) {
            this.initializeFilters()
        }
    }

    componentDidMount() {
        const { network, isEmployee } = this.props
        if (!isEmployee) {
            network.getWorkerList()
        }
    }

    initializeFilters() {
        const { workers } = this.props

        let options = [
            {
                key: 'TUTTI',
                text: 'Tutti',
                value: null,
            },
        ]

        for (let i in workers) {
            let worker = workers[i]
            options.push({
                key: worker,
                text: worker.workerName,
                value: worker,
            })
        }

        const filters = {
            calendario_seleziona_impiegato: {
                control: Select,
                name: 'calendario_seleziona_impiegato',
                type: 'number',
                placeholder: 'Seleziona impiegato',
                label: 'Seleziona impiegato',
                options,
                onChange: (e, data) => {
                    let worker = data.value
                    if (worker) {
                        worker.id = worker.workerId
                    }
                    this.setState({
                        worker,
                    })
                },
                onReset: () => {
                    this.setState({
                        worker: null,
                    })
                },
            },
        }

        initializePage(this, [], filters)
    }

    render() {
        const { worker } = this.state
        const { worksByDay, workordersByDay, workday, network } = this.props
        let datestring = workday.format('YYYY-MM-DD')

        let dayTotal = {
            totalWorkerTime: [0, 0],
            totalMachineTime: [0, 0],
            workOrders: 0,
        }
        let totalTime = null
        let totalMachineTime = null

        if (worksByDay && worksByDay[datestring]) {
            for (let work of worksByDay[datestring]) {
                dayTotal.totalWorkerTime[0] += work.workerHours
                dayTotal.totalWorkerTime[1] += work.workerMinutes
                dayTotal.totalMachineTime[0] += work.machineHours
                dayTotal.totalMachineTime[1] += work.machineMinutes
            }

            dayTotal.totalWorkerTime[0] += Math.floor(dayTotal.totalWorkerTime[1] / 60)
            dayTotal.totalWorkerTime[1] = dayTotal.totalWorkerTime[1] % 60
            dayTotal.totalMachineTime[0] += Math.floor(dayTotal.totalMachineTime[1] / 60)
            dayTotal.totalMachineTime[1] = dayTotal.totalMachineTime[1] % 60

            if (dayTotal) {
                totalTime = timeObject(dayTotal.totalWorkerTime[0], dayTotal.totalWorkerTime[1])
                totalMachineTime = timeObject(dayTotal.totalMachineTime[0], dayTotal.totalMachineTime[1])
            }
        }

        return (
            <View row fullw fullh>
                <View column style={{ width: '60%' }}>
                    <Calendar
                        sub
                        worker={worker}
                        isEmployee={!!this.props.isEmployee}
                        inlineWeekday
                        controls
                        showWorkOrders={!!this.props.showWorkOrders}
                    />
                </View>

                <View column style={{ width: '40%' }}>
                    <Segment>
                        <View row fullh>
                            <View row fullh>
                                <Statistic style={{ margin: 0 }} color="black">
                                    <Statistic.Label>{workday.format('dddd')}</Statistic.Label>
                                    <Statistic.Value>{workday.format('DD')}</Statistic.Value>
                                    <Statistic.Label>
                                        {workday.format('MM')}/{workday.format('YYYY')}
                                    </Statistic.Label>
                                </Statistic>
                            </View>
                            <View row fullw fullh around>
                                {totalTime && (
                                    <Statistic style={{ marginBottom: 0, alignSelf: 'center' }}>
                                        <Statistic.Label>Ore operaio</Statistic.Label>
                                        <Statistic.Value className="smallStatistic">{totalTime.hstring}</Statistic.Value>
                                        <Statistic.Value className="smallStatistic">{totalTime.mstring}</Statistic.Value>
                                    </Statistic>
                                )}
                                {totalMachineTime && (
                                    <Statistic style={{ alignSelf: 'center' }}>
                                        <Statistic.Label>Ore macchina</Statistic.Label>
                                        <Statistic.Value className="smallStatistic">{totalMachineTime.hstring}</Statistic.Value>
                                        <Statistic.Value className="smallStatistic">{totalMachineTime.mstring}</Statistic.Value>
                                    </Statistic>
                                )}
                            </View>
                        </View>
                    </Segment>
                    {this.props.showWorkHours && (
                        <Works
                            sub
                            fullh
                            childProps={{ hideDate: true, hideWorker: true, hideDescription: true }}
                            style={{ width: '100%' }}
                            worker={worker ? worker.id : null}
                            fixedWorks={worksByDay && worksByDay[datestring] ? worksByDay[datestring] : []}
                            deletable
                            controls
                            onDelete={(toDelete) => {
                                const monthStart = moment(workday).startOf('month')
                                const monthEnd = moment(workday).endOf('month')

                                network.getWorkListSpan(
                                    monthStart.format('YYYY-MM-DD'),
                                    monthEnd.format('YYYY-MM-DD'),
                                    worker ? worker.id : null,
                                    !!this.props.isEmployee
                                )
                            }}
                        />
                    )}
                    {this.props.showWorkOrders && (
                        <WorkOrders
                            {...this.props}
                            sub
                            fullh
                            style={{ width: '100%' }}
                            worker={worker ? worker.id : null}
                            fixedWorkOrders={workordersByDay && workordersByDay[datestring] ? workordersByDay[datestring] : []}
                            controls
                            hideWorkOrderInternal
                            hideDeliveryDate
                            hideImage
                            noActions
                            hideInputs
                            onRowClick={(workorder) => {
                                if (this.props.isEmployee) {
                                    this.props.history.push(route_from(employee, materialcheck) + '?workorder=' + workorder.workOrderId)
                                }
                            }}
                        />
                    )}
                </View>
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getWorkerList,
        workday: {
            data: { date: workday },
        },
        getWorkListSpan: {
            data: { data: worksByDay },
        },
        getWorkOrderListSpan: {
            data: { data: workordersByDay },
        },
    } = state

    return {
        workers: getWorkerList.data.data,
        fetchingWorkers: getWorkerList.fetching,
        workday,
        worksByDay,
        workordersByDay,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CalendarView)))
