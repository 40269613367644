import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Icon, Label, Popup, Segment, Statistic } from 'semantic-ui-react'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { WORKDAY_ACTIONS } from '../actions/workday'
import { Works } from '../containers'
import MenuBar from '../layout/MenuBar'
import TimeSpan from '../layout/TimeSpan'
import { get_section, routes, route_from } from '../lib/routes'
import { floatTimeToString } from '../lib/util'
const { employee, insertion } = routes

class Calendar extends Component {
    constructor(props) {
        super(props)
        let {
            location: { pathname },
        } = this.props.history

        this.state = {
            sidemenu: get_section(pathname),
            monthStart: moment().startOf('month'),
            monthEnd: moment().endOf('month'),
            selected: {},
            isEmployee: this.props.isEmployee,
            toolbar: [
                {
                    name: 'Oggi',
                    icon: 'calendar outline',
                    position: 'left',
                    action: () => {
                        this.today()
                    },
                },
                {
                    name: '',
                    icon: 'arrow left',
                    position: 'right',
                    action: () => {
                        this.prevMonth()
                    },
                },
                {
                    name: '',
                    icon: 'arrow right',
                    position: undefined,
                    action: () => {
                        this.nextMonth()
                    },
                },
            ],
        }
    }

    today() {
        let mom = moment()
        this.setState({
            monthStart: moment(mom).startOf('month'),
            monthEnd: moment(mom).endOf('month'),
        })
    }

    nextMonth() {
        let mom = moment(this.state.monthStart).endOf('week').add(1, 'months')
        this.setState({
            monthStart: moment(mom).startOf('month'),
            monthEnd: moment(mom).endOf('month'),
        })
    }

    prevMonth() {
        let mom = moment(this.state.monthStart).endOf('week').subtract(1, 'months')
        this.setState({
            monthStart: moment(mom).startOf('month'),
            monthEnd: moment(mom).endOf('month'),
        })
    }

    componentDidMount() {
        let { network, worker, sub, workerHours, showWorkOrders } = this.props

        if (showWorkOrders) {
            network.getWorkOrderListSpan(this.state.monthStart.format('YYYY-MM-DD'), this.state.monthEnd.format('YYYY-MM-DD'), this.state.isEmployee)
        } else {
            network.getWorkListSpan(
                this.state.monthStart.format('YYYY-MM-DD'),
                this.state.monthEnd.format('YYYY-MM-DD'),
                worker ? worker.id : null,
                this.state.isEmployee
            )
        }
        if (!sub) {
            this.props.toolbarResult(this.state.toolbar)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { monthStart, monthEnd } = this.state
        let { network, worker } = this.props

        if (
            prevProps.worker !== worker ||
            (worker && prevProps.worker.id !== worker.id) ||
            !monthStart.isSame(prevState.monthStart) ||
            !monthEnd.isSame(prevState.monthEnd)
        ) {
            network.getWorkListSpan(
                this.state.monthStart.format('YYYY-MM-DD'),
                this.state.monthEnd.format('YYYY-MM-DD'),
                worker ? worker.id : null,
                this.state.isEmployee
            )
        }
    }

    pressDay(day) {
        this.props.history.replace(route_from(employee, insertion))
    }

    renderDays(days, curweek) {
        let {
            worker,
            worksByDay,
            totalPerDay,
            isEmployee,
            inlineWeekday,
            workday,
            hideday,
            customContent,
            showWorkHours,
            showWorkOrders,
        } = this.props

        return days.map((value, index) => {
            let datestring = value.date.format('YYYY-MM-DD')
            let totalTime = 0
            let totalWorkOrders = 0
            if (totalPerDay && totalPerDay[datestring] && totalPerDay[datestring].totalWorkerTime) {
                totalTime = totalPerDay[datestring].totalWorkerTime[0] + totalPerDay[datestring].totalWorkerTime[1] / 60
            }
            if (totalPerDay && totalPerDay[datestring] && totalPerDay && totalPerDay[datestring].totalWorkOrders) {
                totalWorkOrders = totalPerDay[datestring].totalWorkOrders
            }

            return (
                <Segment
                    key={`calendar-key-${datestring}`}
                    style={{
                        margin: 0,
                        padding: 0,
                        flex: 1,
                        height: '100%',
                        backgroundColor: workday.format('YYYY-MM-DD') === value.key ? '#d7fff1' : 'white',
                    }}
                    color={value.color}
                    onClick={() => {
                        this.props.workdayResult(value)
                        if (isEmployee && showWorkHours) {
                            this.pressDay(value)
                        }

                        if (this.props.onDayClicked) {
                            this.props.onDayClicked({
                                value,
                                index: index + curweek * 7,
                            })
                        }
                    }}
                >
                    <View column fullw fullh>
                        <View row fullw style={{ justifyContent: 'flex-start', minHeight: 100 }}>
                            {!hideday && (
                                <Statistic style={{ margin: 0 }} color={value.color}>
                                    {inlineWeekday && <Statistic.Label>{value.date.format('dddd')}</Statistic.Label>}
                                    <Statistic.Value>{value.date.format('DD')}</Statistic.Value>
                                    <Statistic.Label>
                                        {value.date.format('MM')}/{value.date.format('YYYY')}
                                    </Statistic.Label>
                                </Statistic>
                            )}
                            {!inlineWeekday && <Statistic.Label>{value.date.format('dddd')}</Statistic.Label>}
                            {showWorkHours && worker && totalTime < worker.dailyHours && (
                                <Popup trigger={<Icon color="yellow" style={{ marginLeft: 4 }} name="warning sign" />}>
                                    <Popup.Header>Registrate: {floatTimeToString(totalTime)}</Popup.Header>
                                    <Popup.Content>Registra almeno {worker.dailyHours} ore per rimuovere questo avviso.</Popup.Content>
                                </Popup>
                            )}
                        </View>
                        <View column fullw fullh style={{ overflow: 'hidden' }}>
                            {isEmployee && (
                                <Works
                                    isEmployee
                                    sub
                                    fullh
                                    compact
                                    childProps={{ hideDate: true, hideWorker: true, hideDescription: true }}
                                    style={{ width: '100%' }}
                                    worker={worker ? worker.id : null}
                                    fixedWorks={worksByDay && worksByDay[datestring] ? worksByDay[datestring] : []}
                                />
                            )}
                            {!customContent && (!isEmployee || isEmployee && showWorkOrders) && (
                                <View fullw fullh around>
                                    {showWorkHours && <TimeSpan size="mini" hideMinutes display="block" totalTime={totalTime} />}
                                    {showWorkOrders && (
                                        <Statistic style={{ margin: 0 }}>
                                            <Statistic.Value>{totalWorkOrders ? totalWorkOrders : '--'}</Statistic.Value>
                                        </Statistic>
                                    )}
                                </View>
                            )}

                            {customContent ? customContent(value, index + curweek * 7) : null}
                        </View>
                    </View>
                </Segment>
            )
        })
    }

    renderWeek(days, number) {
        let digrow = days.slice(number * 7, number * 7 + 7)

        return (
            <View fullw fullh row>
                {this.renderDays(digrow, number)}
            </View>
        )
    }

    render() {
        const { monthStart, monthEnd } = this.state
        const { controls, hideday, customTitle, customWeeks } = this.props

        let left = monthStart.startOf('week')
        let right = monthEnd.endOf('week')
        let diff = right.diff(left, 'days')
        let weeks = customWeeks ? customWeeks : Math.ceil(diff / 7)
        let days = []
        for (let m = moment(left); m.isBefore(right); m.add(1, 'days')) {
            days.push({
                date: moment(m),
                color: m.day() === 0 || m.day() === 6 ? 'red' : 'grey',
                key: moment(m).format('YYYY-MM-DD'),
            })
        }

        let elements = []
        for (let i = 0; i < weeks; i++) {
            let w = this.renderWeek(days, i)
            elements.push(w)
        }

        return (
            <View fullw column around style={{ padding: 16 }}>
                <View fullw around column>
                    <Label style={{ textTransform: 'capitalize', fontSize: '24pt', textAlign: 'center', borderRadius: 0 }}>
                        {customTitle ? customTitle : moment(monthEnd).startOf('week').format('MMMM')}
                    </Label>
                </View>
                {controls && <MenuBar toolbar={this.state.toolbar} />}
                <View style={styles.container} column fullw fullh>
                    {elements}
                </View>
            </View>
        )
    }
}

const styles = {
    container: {
        margin: '0px auto',
    },
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        workdayRequest: (result) => WORKDAY_ACTIONS.request(result, dispatch),
        workdayResult: (result) => WORKDAY_ACTIONS.result(result, dispatch),
        workdayError: (result) => WORKDAY_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getWorkOrderListSpan: {
            data: { data: workordersByDay },
        },
        getWorkListSpan: {
            data: { data: worksByDay },
        },
        workday: {
            data: { date: workday },
        },
    } = state

    let totalPerDay = {}
    for (let date in worksByDay) {
        totalPerDay[date] = {
            totalWorkerTime: [0, 0],
            totalMachineTime: [0, 0],
        }
        let works = worksByDay[date]

        for (let work of works) {
            totalPerDay[date].totalWorkerTime[0] += work.workerHours
            totalPerDay[date].totalWorkerTime[1] += work.workerMinutes
            totalPerDay[date].totalMachineTime[0] += work.machineHours
            totalPerDay[date].totalMachineTime[1] += work.machineMinutes
        }

        totalPerDay[date].totalWorkerTime[0] += Math.floor(totalPerDay[date].totalWorkerTime[1] / 60)
        totalPerDay[date].totalWorkerTime[1] = totalPerDay[date].totalWorkerTime[1] % 60
        totalPerDay[date].totalMachineTime[0] += Math.floor(totalPerDay[date].totalMachineTime[1] / 60)
        totalPerDay[date].totalMachineTime[1] = totalPerDay[date].totalMachineTime[1] % 60
    }

    for (let date in workordersByDay) {
        if (!totalPerDay[date]) {
            totalPerDay[date] = {}
        }
        let workorders = workordersByDay[date]
        totalPerDay[date].totalWorkOrders = workorders.length
    }

    return {
        worksByDay,
        totalPerDay,
        workday,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Calendar)))
